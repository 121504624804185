import { ProcessDocumentsStepsResume } from 'clients/manager/documents/process-documents-steps.requests';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import { createContext, ReactNode, useContext } from 'react';

export type StepDocumentContextType = {
    isContractOrTermType: boolean;
};

export const StepDocumentContext = createContext<StepDocumentContextType>(
    {} as StepDocumentContextType
);

export const useStepDocumentContext = () => useContext(StepDocumentContext);

export const StepDocumentContextProvider = ({
    children,
    step,
}: {
    children: ReactNode;
    step: ProcessDocumentsStepsResume;
}) => {
    const isContractOrTermType =
        step.documentType !== undefined &&
        [
            DocumentTemplateType.contract,
            DocumentTemplateType.terminationNotice,
            DocumentTemplateType.addendum,
            DocumentTemplateType.apostilmentTerm,
        ].includes(step.documentType);

    return (
        <StepDocumentContext.Provider value={{ isContractOrTermType }}>
            {children}
        </StepDocumentContext.Provider>
    );
};
