import { TypeAccess } from 'common/interfaces/user.interface';
import { getAuthUserToken } from 'modules/auth/session.utils';

export const getUrlOAuth2 = (
    typeAccess?: TypeAccess | null,
    resourceId?: string | null,
    redirect?: string | null,
    baseUrlToPhp?: string | null,
    userId?: string
) => {
    const urlLocation = window.location.toString();
    const { protocol } = window.location;
    const { host } = new URL(urlLocation);
    const state = 'state'; // TODO:
    let urlCallback = 'oauth2/callback';
    urlCallback += redirect ? `/?redirectUri=${redirect}` : '';
    const baseUrl = baseUrlToPhp ?? `${protocol}//${host}`;
    const redirectUri = `${baseUrl}/${urlCallback}`;
    const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID;
    let tenancy = 'common';
    switch (typeAccess) {
        case TypeAccess.organization:
        case TypeAccess.provider:
            tenancy = resourceId ?? tenancy;
            break;
        default:
            break;
    }

    const scope = typeAccess ?? 'main';

    let url = `oauth2/authorize/${tenancy}/?client_id=${clientId}&response_type=code&state=${state}`;
    url += `&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scope}`;
    url += userId ? `&userId=${userId}` : '';

    return url;
};

export const redirectTo = (domain: string, to: string, custom?: boolean) => {
    const redirectTo = (url: string) => window.location.replace(url);

    if (custom) {
        return redirectTo(to);
    }

    if (to.startsWith('/')) {
        to = to.replace(/^\//, '');
    }

    return redirectTo(`${domain}/${to}`);
};

export const apiRedirectTo = (options: {
    backofficeRedirectUrl: string;
    redirectUrl: string;
    isPublic?: boolean;
    isDisableRedirect?: boolean;
    loginBaseUrl?: string;
}) => {
    const { backofficeRedirectUrl, redirectUrl, isPublic, isDisableRedirect, loginBaseUrl } =
        options;

    const jwt = getAuthUserToken();
    const typeAccess = jwt?.typeAccess || 'citizen';
    let companyId: number | undefined;

    switch (typeAccess) {
        case TypeAccess.organization:
            companyId = jwt?.organizationId;
            break;
        case TypeAccess.provider:
            companyId = jwt?.providerId;
            break;
        default:
            break;
    }
    const opPath = redirectUrl.startsWith('/') ? '' : '/';

    if (!isPublic && isDisableRedirect) {
        let url = `${backofficeRedirectUrl}${opPath}${redirectUrl}`;
        url = decodeURIComponent(url);
        const haveQueryParams = url.includes('?');
        const charConcatUrl = haveQueryParams ? '&' : '?';
        url += `${charConcatUrl}private=true&typeAccess=${typeAccess}&companyId=${companyId || 0}`;
        return url;
    }

    let url = `${backofficeRedirectUrl}${opPath}${redirectUrl}`;

    if (!isPublic) {
        const newUrl = getUrlOAuth2(
            typeAccess as TypeAccess,
            `${companyId}`,
            `${opPath}${redirectUrl}`,
            backofficeRedirectUrl
        );
        url = `${loginBaseUrl || backofficeRedirectUrl}/${newUrl}`;
    } else if (isDisableRedirect) {
        return url;
    }
    window.location.replace(url);
};
