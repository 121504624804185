import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Grid,
    TableRow,
    CircularProgress,
    Typography,
    TablePagination,
} from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
    ListProcessStepsFiltersParams,
    ProcessDocumentsStepsResume,
    processDocumentStepsRequests,
} from 'clients/manager/documents/process-documents-steps.requests';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { processUtils } from 'modules/process/process-utils';
import { ListDocumentsProps } from './props';
import { useProcessDocumentsStepsContext } from '../../../../context/process-document-steps';
import RowView from './row-view';

const rowsPerPageOptions = [10, 20, 30];

const ListDocuments: FC<ListDocumentsProps> = ({ onClickCreateDocument, process }) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<ListProcessStepsFiltersParams>({
        auctionId: process?.id,
        q: undefined,
    });
    const { processDocumentsSteps, setProcessDocumentsSteps } = useProcessDocumentsStepsContext();

    const { data, loading, totalCount, page, limit, setLimit, setPage } = usePaginatedQuery(
        processDocumentStepsRequests.listProcessStepsResume,
        filters
    );

    useEffect(() => {
        setProcessDocumentsSteps(data?.data || []);
    }, [data]);

    const onClickDocument = () => {};

    const onDeleteProcessDocumentStep = (processDocumentStep: ProcessDocumentsStepsResume) => {
        const newProcessDocumentsSteps = processDocumentsSteps.filter(
            (processDocumentSteps) => processDocumentSteps._id !== processDocumentStep._id
        );
        setProcessDocumentsSteps(newProcessDocumentsSteps);
    };

    const onUpdateProcessDocumentStep = (newProcessDocumentStep: ProcessDocumentsStepsResume) => {
        setProcessDocumentsSteps((prevState) => [
            ...prevState.map((processDocumentSteps) => {
                if (processDocumentSteps._id === newProcessDocumentStep._id) {
                    const { totalGenerated } = newProcessDocumentStep;

                    return {
                        ...newProcessDocumentStep,
                        totalGenerated: totalGenerated ?? 0,
                    };
                }
                return processDocumentSteps;
            }),
        ]);
    };

    const handleChangeTextFilter = debounce((text: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            q: text,
        }));
    }, 200);

    return (
        <>
            <Box mt={2}>
                <Box>
                    <Grid container>
                        <Grid item xs={9}>
                            <Box display='flex' alignItems='center'>
                                <TextField
                                    fullWidth
                                    label={t('process.components.search-documents')}
                                    name='process_text'
                                    onChange={(event) => handleChangeTextFilter(event.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box
                                display='flex'
                                justifyContent='flex-end'
                                alignItems='center'
                                height={1}
                            >
                                <Button
                                    color='primary'
                                    size='small'
                                    variant='contained'
                                    onClick={onClickCreateDocument}
                                >
                                    {t('term.new-document')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2}>
                    <TableContainer>
                        <Table aria-label={t('process.components.list-documents-process')}>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding='none' />
                                    <TableCell>{t('term.name')}</TableCell>
                                    <TableCell>{t('term.type')}</TableCell>
                                    <TableCell>{t('term.signers')}</TableCell>
                                    <TableCell>Status</TableCell>
                                    {processUtils.isNewLaw(process?.legalSupportId) ? (
                                        <TableCell>{t('term.sended-to-pncp')}</TableCell>
                                    ) : (
                                        <TableCell>{t('term.generated')}</TableCell>
                                    )}
                                    <TableCell padding='none' />
                                    <TableCell padding='none' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={7} align='center'>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!loading && !processDocumentsSteps.length && (
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Typography variant='body2' align='center'>
                                                {t('info.none-data')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!loading &&
                                    processDocumentsSteps.map((processDocumentStep) => {
                                        return (
                                            <RowView
                                                key={processDocumentStep._id}
                                                processDocumentsStep={processDocumentStep}
                                                onClickDocument={onClickDocument}
                                                onDeleteProcessDocumentStep={
                                                    onDeleteProcessDocumentStep
                                                }
                                                onUpdateProcessDocumentStep={
                                                    onUpdateProcessDocumentStep
                                                }
                                                process={process}
                                            />
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            count={totalCount}
                            page={page}
                            onPageChange={(_e, page) => setPage(page)}
                            rowsPerPage={limit || rowsPerPageOptions[0]}
                            rowsPerPageOptions={rowsPerPageOptions}
                            component='div'
                            onRowsPerPageChange={(event) =>
                                setLimit(Number(event.target.value) ?? rowsPerPageOptions[0])
                            }
                            labelRowsPerPage={t('text.lines-per-page')}
                        />
                    </TableContainer>
                </Box>
            </Box>
        </>
    );
};

export default ListDocuments;
