import { useTopbar } from 'common/components/base/pages/context/TopbarContext';
import { useHistory } from 'react-router';
import NotificationTopbarView from './notification-topbar-view';

const NotificationTopbar = () => {
    const history = useHistory();
    const { topbarData, closeTopbar } = useTopbar();

    const handleClickTopbar = () => {
        if (topbarData.informationId) {
            history.push(`/avisos-informacoes/${topbarData.informationId}`);
        }
        closeTopbar();
    };

    return <NotificationTopbarView {...{ handleClickTopbar, topbarData, closeTopbar }} />;
};

export default NotificationTopbar;
