import { ChipProps } from '@material-ui/core';
import { ProviderValueStatus } from 'common/enums/provider-values-status.enum';
import { useTranslation } from 'react-i18next';
import { useStyles } from './provider-value-status-chip-style';
import ProviderValueStatusChipView from './provider-value-status-chip-view';

interface ProviderValueStatusChipProps extends ChipProps {
    status: string;
}

const ProviderValueStatusChip: React.FC<ProviderValueStatusChipProps> = ({ status }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const labels = {
        [ProviderValueStatus.winner]: t('provider-value-status.winner', { ns: 'getters' }),
        [ProviderValueStatus.winner_unclassified]: t('provider-value-status.winner_unclassified', {
            ns: 'getters',
        }),
    };

    const mapper = {
        [ProviderValueStatus.winner]: styles.chipStatusWinner,
        [ProviderValueStatus.winner_unclassified]: styles.chipStatusUnclassified,
    };

    const defaultProps: ChipProps = {
        label: labels[status] ?? t('provider-value-status.not_callable', { ns: 'getters' }),
        className: mapper[status] ?? styles.chipStatusNotCalled,
    };

    return <ProviderValueStatusChipView {...defaultProps} />;
};

export default ProviderValueStatusChip;
