import { useContext } from 'react';
import { PlatformContext } from 'routes/platform.context';

export const getBackofficeRedirectUrl = (isOAuth2?: boolean): string => {
    const { platform } = useContext(PlatformContext);
    if (!platform) {
        return '';
    }
    const urlLocation = window.location.toString();
    const { host } = new URL(urlLocation);
    const subdomain = host.split('.')[0];

    const fromToUrlPhp = {
        'app2-dev': 'app-dev',
        'app2-qa': 'app-qa',
        'app2-compras': 'app-compras',
        'app2-cemig': 'app-cemig',
        'app2x-qa': 'appx-qa',
        app2x: 'appx',
        app2: 'app',
        compras2: 'compras',
    };

    if (fromToUrlPhp[subdomain] === undefined) {
        if (host.includes('localhost') || host.includes('4000')) {
            return `http://${isOAuth2 ? 'loginlicitar' : 'licitardigital'}:8080`;
        }
        throw new Error('Application not found!');
    }

    const subdomainAccount = process.env.REACT_APP_ACCOUNT_PREFIX || 'minhaconta';
    const developmentTag = isOAuth2 ? subdomainAccount : fromToUrlPhp[subdomain];
    const domainAccount = platform.apps?.account;

    return isOAuth2 && domainAccount
        ? domainAccount
        : `https://${developmentTag}.${platform.domain}`;
};
