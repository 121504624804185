import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Theme } from 'common/theme';
import { formatPxToRem } from 'common/utils';

const infoContainer: CSSProperties = {
    height: formatPxToRem(40),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        borderRadius: formatPxToRem(8),
        padding: formatPxToRem(20),
        marginBottom: formatPxToRem(15),
    },
    titleSection: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: formatPxToRem(5),
    },
    marginTopSm: {
        marginTop: formatPxToRem(5),
    },
    infoSection: { display: 'flex', alignItems: 'center', margin: `${formatPxToRem(15)} 0` },
    infoContainer: {
        ...infoContainer,
        borderRight: `1px solid ${theme.palette.grey?.[700]}`,
        paddingRight: formatPxToRem(10),
    },
    infoContainerBordeless: {
        ...infoContainer,
        padding: `0 ${formatPxToRem(10)}`,
    },
    infoLabel: {
        color: theme.palette.grey?.[600],
        fontSize: `${formatPxToRem(12)}`,
        fontWeight: 600,
    },
    strong: {
        fontWeight: 600,
    },
}));

export { useStyles };
