import {
    LatestInformation,
    InformationCategoryEnum,
} from 'clients/manager/interfaces/notice-information.interfaces';
import { noticesInformationRequests } from 'clients/manager/notice-information.requests';
import { isArray } from 'lodash';
import { getAuthToken } from 'modules/auth/session.utils';
import { createContext, useContext, useEffect, useState } from 'react';

type TopbarData = {
    informationId?: number;
    open: boolean;
    message?: string;
    resume?: string;
    label?: string;
};

interface TopbarContext {
    topbarData: TopbarData;
    showTopbar: (
        message: string,
        topbarConfig?: { label?: string; resume?: string; informationId: number }
    ) => void;
    closeTopbar: () => void;
}

const TopbarContext = createContext({} as TopbarContext);

export const useTopbar = () => useContext(TopbarContext);

const TopbarContextProvider = ({ children }) => {
    const [topbarData, setTopbarData] = useState<TopbarData>({ open: false });

    const getTopbarInformation = async () => {
        const { data } = await noticesInformationRequests.getSingleInformation('topbar');
        let information: LatestInformation;

        if (isArray(data)) {
            information = data[0];
        } else {
            information = data;
        }

        if (information)
            setTopbarData({
                open: true,
                label: InformationCategoryEnum[information.category],
                informationId: information.id,
                resume: information.resume,
                message: information.title,
            });
    };

    useEffect(() => {
        getTopbarInformation();
    }, []);

    useEffect(() => {
        let informationInterval: NodeJS.Timeout;

        const clearTimer = () => clearTimeout(informationInterval);

        const activeTimer = () => {
            clearTimer();
            informationInterval = setInterval(() => {
                if (!topbarData.open) getTopbarInformation();
            }, 60000 * 5); // 5 minutos
        };

        window.addEventListener('focus', activeTimer);
        window.addEventListener('blur', clearTimer);

        activeTimer();

        return () => {
            window.removeEventListener('focus', activeTimer);
            window.removeEventListener('blur', clearTimer);
            clearInterval(informationInterval);
        };
    }, [topbarData.open]);

    const showTopbar = (
        message: string,
        topbarConfig?: { label?: string; resume?: string; informationId: number }
    ) => {
        setTopbarData({
            open: true,
            message,
            label: topbarConfig?.label || InformationCategoryEnum.information,
            informationId: topbarConfig?.informationId,
            resume: topbarConfig?.resume,
        });
    };

    const closeTopbar = () => {
        if (topbarData.informationId && getAuthToken()) {
            noticesInformationRequests.readInformation(topbarData.informationId);
        }

        setTopbarData((prev) => ({ ...prev, open: false }));
    };

    return (
        <TopbarContext.Provider value={{ topbarData, showTopbar, closeTopbar }}>
            {children}
        </TopbarContext.Provider>
    );
};

export default TopbarContextProvider;
