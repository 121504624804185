import { Box } from '@material-ui/core';
import i18n from 'i18next';
import React, { FC, useMemo } from 'react';
import ProcessData from '../process-data';
import ProcessDocumentsData from '../process-documents-data';
import ProcessMessagesData from '../process-messages-data';
import { ProcessTab } from '../../interfaces/process.tabs.interface';
import { ContentTabProps } from './props';
import ProcessContractsData from '../process-contracts-data';
import ProcessSolicitationsData from '../process-solicitations-data';
import ProcessIframeData from './components/process-iframe-data/process-iframe-data';
import ProcessOrdersData from './components/process-orders-data';

type ITab = {
    [key in ProcessTab]: { component?: any; title: string; onClick?: () => void };
};

const ContentTab: FC<ContentTabProps> = ({ tab }) => {
    const tabs = useMemo(() => {
        const tabs: ITab = {
            [ProcessTab.general]: {
                component: <ProcessData />,
                title: i18n.t('process.components.general-data'),
            },
            [ProcessTab.documents]: {
                component: <ProcessDocumentsData />,
                title: i18n.t('term.documents'),
            },
            [ProcessTab.messages]: {
                component: <ProcessMessagesData />,
                title: i18n.t('term.warnings'),
            },
            [ProcessTab.solicitations]: {
                component: <ProcessSolicitationsData />,
                title: i18n.t('term.solicitations'),
            },
            [ProcessTab.proposals]: {
                component: <ProcessIframeData urlTab='propostas' />,
                title: i18n.t('term.proposals'),
            },
            [ProcessTab.authorization]: {
                component: <ProcessIframeData urlTab='habilitacao' />,
                title: i18n.t('term.qualification'),
            },
            [ProcessTab.quantitative]: {
                component: <ProcessIframeData urlTab='quantity' />,
                title: 'quantitativo',
            },
            [ProcessTab.orders]: {
                component: <ProcessOrdersData />,
                title: i18n.t('term.purchase'),
            },
            [ProcessTab.lotsOverview]: {
                component: <ProcessIframeData urlTab='lotes' />,
                title: i18n.t('term.lots-overview'),
            },
            [ProcessTab.contracts]: {
                component: <ProcessContractsData />,
                title: i18n.t('term.contracts'),
            },
            [ProcessTab.integrations]: {
                component: <ProcessIframeData urlTab='integracoes' />,
                title: i18n.t('term.integrations'),
            },
        };

        return tabs;
    }, []);

    const { component: Component } = tabs[tab];

    return (
        <div
            role='tabpanel'
            id={`scrollable-force-tabpanel-${tab}`}
            aria-labelledby={`scrollable-force-tab-${tab}`}
        >
            <Box p={2}>{React.cloneElement(Component, { tab })}</Box>
        </div>
    );
};

export default ContentTab;
