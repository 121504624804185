import React from 'react';
import { Typography } from '@material-ui/core';
import { formatCountdown } from 'common/utils';
import { useTranslation } from 'react-i18next';
import useStyles from './resend-code-countdown-style';

interface ResendCodeCountdownViewProps {
    countdown?: number;
    onResendCode?: () => void;
    setErrorSendCode?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ResendCodeCountdownView: React.FC<ResendCodeCountdownViewProps> = ({
    countdown = 0,
    onResendCode,
    setErrorSendCode,
}) => {
    const style = useStyles();
    const { t } = useTranslation();
    const handleOnResendCode = () => {
        onResendCode?.();
        if (setErrorSendCode !== undefined) {
            setErrorSendCode(undefined);
        }
    };

    return (
        <div className={style.countdownContainer}>
            {countdown > 0 && (
                <Typography className={style.countdownText}>
                    {t(
                        `code-validator.resend-code-countdown.${
                            setErrorSendCode === undefined ? 'code-expires-in' : 'await'
                        }`,
                        {
                            countdown: formatCountdown(countdown ?? 0),
                        }
                    )}
                </Typography>
            )}
            {countdown <= 0 && (
                <Typography className={style.resendText}>
                    {setErrorSendCode === undefined
                        ? t('code-validator.resend-code-countdown.code-expired')
                        : ''}
                    <Typography onClick={handleOnResendCode} className={style.resendLink}>
                        {t('code-validator.resend-code-countdown.resend-code')}
                    </Typography>
                </Typography>
            )}
        </div>
    );
};

export default ResendCodeCountdownView;
