import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { UserLanguages } from 'common/interfaces/user.interface';
import { getBackofficeRedirectUrl, getLanguage } from 'common/utils';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { useParams } from 'react-router';
import { BodyValidateById } from 'modules/public/interfaces/verification-email.interface';
import { verificationEmailRequests } from '../../services/verification-email.requests';
import useStyles from './styles';
import { BoxConfirmChangeEmailView } from './box-confirm-change-email-view';

const BoxConfirmChangeEmail = () => {
    const phpBaseUrl = getBackofficeRedirectUrl();
    const params = new URLSearchParams(window.location.search);
    const confirmHash = useMemo<string>(() => params.get('confirmHash') || '', [params]);

    const styles = useStyles();
    const [isReponse, setIsReponse] = useState(false);
    const [errMessage, setErrMessage] = useState<{ token: string; message: string } | undefined>();
    const [isConfirm, setIsConfirm] = useState(false);

    const handleError = (err: any) => {
        console.error(err);
        if (err?.response?.data?.message) {
            const currentLanguage = getLanguage();
            const isPt = currentLanguage === UserLanguages['pt-BR'];
            const { message } = err.response.data;

            setErrMessage({
                message: typeof message === 'object' ? message[isPt ? 'pt' : 'en'] : message,
                token: err.response.data.token,
            });
        } else {
            setErrMessage(err);
        }

        setIsReponse(true);
    };

    const handleSuccess = async (data: BodyValidateById) => {
        setIsConfirm(data.confirm);
        return verificationEmailRequests
            .validateById(data)
            .then(() => setIsReponse(true))
            .catch(handleError);
    };

    useQueryWithBody(verificationEmailRequests.decodeEmailChangeHash, confirmHash, {
        onSuccess: async ({ data }) => handleSuccess(data),
        onError: handleError,
    });

    const url = `${phpBaseUrl}/login`;

    return !isReponse ? (
        <div className={styles.main}>
            <div className={styles.loading}>
                <CircularProgress className={clsx(styles.loading)} />
            </div>
        </div>
    ) : (
        <BoxConfirmChangeEmailView error={errMessage} isConfirm={isConfirm} url={url} />
    );
};

export default BoxConfirmChangeEmail;
