import { CircularProgress, Collapse, Tooltip } from '@material-ui/core';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { auctionNoticeParticipate } from 'clients/manager/auction-notice-participate.request';
import { favoriteAuctionNotice } from 'clients/manager/favorite-auction-notice';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import {
    DetailedAutcionNotice,
    ProcessEnum,
} from 'clients/manager/interfaces/auction-notice.interface';
import {
    addNotificationApiError,
    getMd5Hash,
    formatDate,
    addNotificationError,
    apiRedirectTo,
    getBackofficeRedirectUrl,
    getJudgmentCriterion,
    getMethodDispute,
} from 'common/utils';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AiFillHeart } from 'react-icons/ai';
import { FaHandsHelping } from 'react-icons/fa';
import { IoMdPlay } from 'react-icons/io';
import { RiEyeFill, RiSwordFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AppState } from 'store';
import moment from 'moment';
import {
    ExceptionData,
    ProcessDisputeType,
    ProcessParticipate,
} from 'clients/manager/interfaces/auction-notice-participate.interface';
import { getDisputeUrl } from 'common/utils/getters/get-url-redirect-dispute.utils';
import { usePlatformContext } from 'routes/platform.context';
import { processUtils } from 'modules/process/process-utils';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import {
    labelTypeRegionalBenefit,
    labelWarningRegionalBenefit,
} from 'common/constants/type-regional-benefit.constant';
import { providerRequests } from 'clients/manager/provider.requests';
import { RangeRegionParticipation } from 'common/enums/range-regional-participation.enum';
import {
    ProcessButton,
    ProcessButtonActivityIndicator,
    ProcessButtonsContainer,
    ProcessDescriptionCard,
    ProcessDescriptionContainer,
} from '../../styled';

interface GeneralInfoContainerProps {
    id: string | number;
    data?: DetailedAutcionNotice;
    setCurrentDate: Dispatch<
        SetStateAction<{
            serverDate: Date;
            localDate: Date;
            diference: number;
        }>
    >;
    setExceptions: Dispatch<SetStateAction<ExceptionData[]>>;
    participation: ProcessParticipation;
    setParticipation: Dispatch<SetStateAction<ProcessParticipation>>;
    loading?: boolean;
}

export type ProcessParticipation = {
    participate: ProcessParticipate;
    loading: boolean;
};

const GeneralInfoContainer = ({
    id,
    data,
    loading,
    setExceptions,
    setCurrentDate,
    participation,
    setParticipation,
}: GeneralInfoContainerProps) => {
    const { authUser, authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const { platform } = usePlatformContext();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const history = useHistory();
    const { t } = useTranslation();

    const [dispute, setDispute] = useState<ProcessDisputeType>('none');
    const [disputeToken, setDisputeToken] = useState('');
    const [isFavorite, setIsFavorite] = useState(data?.isFavorite);
    const [favoriteLoading, setFavoriteLoading] = useState(false);
    const [expanded] = useState(true);
    const [provider, setProvider] = useState<Provider>();
    const [isDisabledParticipate, setIsDisabledParticipate] = useState(false);
    const [messageButtonParticipate, setMessageButtonParticipate] = useState(
        t('bidding.process.section.general.action.participate').toString()
    );

    const benefitApplied = !data?.regionalBenefit
        ? t('bidding.general.benefit.no-benefit')
        : t(`${labelTypeRegionalBenefit[data?.regionalBenefit || 0]}`);

    const labelBenefitApplied =
        data?.regionalBenefit && data.regionalBenefit === RangeRegionParticipation.localExclusivity
            ? 'local'
            : 'regional';

    useEffect(() => {
        const fetchProvider = async () => {
            try {
                const response = await providerRequests.getProvider();
                setProvider(response);
            } catch (error) {
                //
            }
        };

        fetchProvider();
    }, []);

    useEffect(() => {
        if (
            provider &&
            data &&
            typeof data.regionalBenefit === 'number' &&
            data.organizationUnit?.organization
        ) {
            const { organization } = data.organizationUnit;
            const { ibgeCode, ibgeCodesRegion } = organization;
            if (!ibgeCodesRegion.length || !ibgeCodesRegion.includes(ibgeCode)) {
                ibgeCodesRegion?.push(ibgeCode);
            }
            const providerIbgeCode = `${provider.ibgeCode}`;

            switch (Number(data.regionalBenefit)) {
                case RangeRegionParticipation.localExclusivity:
                    setIsDisabledParticipate(ibgeCode !== providerIbgeCode);
                    break;
                case RangeRegionParticipation.regionalExclusivity:
                    setIsDisabledParticipate(!ibgeCodesRegion.includes(providerIbgeCode));
                    break;
                default:
                    setIsDisabledParticipate(false);
                    break;
            }
        }
    }, [provider]);

    useEffect(() => {
        setMessageButtonParticipate(
            isDisabledParticipate
                ? t(`${labelWarningRegionalBenefit[data?.regionalBenefit || 0]}`)
                : t('bidding.process.section.general.action.participate')
        );
    }, [isDisabledParticipate]);

    const getProposalUrl = (id: number | string) => {
        const encryptedId = getMd5Hash(String(id));
        return apiRedirectTo({
            backofficeRedirectUrl,
            redirectUrl: `/proposta/?idE=${encryptedId}`,
            isPublic: false,
            isDisableRedirect: true,
        }) as string;
    };

    const changeFavorite = async () => {
        try {
            setFavoriteLoading(true);
            await favoriteAuctionNotice.doChangeFavoriteProcess({
                params: { auctionNoticeId: Number(id) },
                isFavorite: !isFavorite,
            });
            setIsFavorite((prev) => !prev);
        } catch (err) {
            addNotificationApiError(err);
        } finally {
            setFavoriteLoading(false);
        }
    };

    useEffect(() => {
        const getProcessActions = async () => {
            const { data } = await auctionNoticeParticipate.getProcessParticipationActions({
                params: {
                    auctionId: Number(id),
                },
            });

            const localDate = moment(new Date());
            const serverDate = moment(data.currentDatetime, 'DD/MM/YYYY HH:mm:ss');
            const diference = localDate.diff(serverDate, 's');

            setCurrentDate({
                localDate: localDate.toDate(),
                serverDate: serverDate.toDate(),
                diference,
            });

            const { dispute, participate, exceptions } = data;

            unstable_batchedUpdates(() => {
                setExceptions(exceptions);
                setDispute(dispute.type);
                setDisputeToken(dispute?.token);
                setParticipation((prev) => ({ ...prev, participate }));
            });
        };

        if (id && !data?.isCanceled && !data?.isFinished) getProcessActions();
    }, [id]);

    const handleParticipate = useCallback(() => {
        const { participate } = participation;
        if (participate === 'proposal') {
            window.location.replace(getProposalUrl(id));
        }
        if (participate === 'create') {
            auctionNoticeParticipate
                .createParticipate(id)
                .then(() => window.location.replace(getProposalUrl(id)))
                .catch((e) => {
                    addNotificationApiError(e);
                });
        }
        if (participate === 'checkout') {
            history.push(`/pesquisa/${id}/checkout`);
        }
    }, [participation]);

    const handleDispute = useCallback(async () => {
        if (!disputeToken && dispute === 'watch') {
            return addNotificationError({
                title: t('term.error'),
                message: t('info.error-redirect-dispute'),
            });
        }

        const { data } = await auctionNoticeRequests.getService(Number(id));
        window.open(getDisputeUrl(id, authUserTokenData, data, platform, disputeToken), '_blank');
    }, [dispute]);

    useEffect(() => {
        setIsFavorite(data?.isFavorite);
    }, [data?.isFavorite]);

    const getInitialDateLabel = (ProcessType: ProcessEnum) => {
        switch (ProcessType) {
            case ProcessEnum.D:
                return t('bidding.process.section.general.date-send-proposal-init');
            case ProcessEnum.C:
                return t('bidding.process.section.general.initial-date');
            default:
                return t('bidding.process.section.general.date-official-diary');
        }
    };

    const getFinalDateLabel = (ProcessType: ProcessEnum) => {
        switch (ProcessType) {
            case ProcessEnum.C:
                return t('bidding.process.section.general.final-date');
            default:
                return t('bidding.process.section.general.date-start-dispute');
        }
    };

    const isDispenseWithoutDipute = processUtils.isDispenseWithoutDispute(data);

    const processButtonNotRunningLabel = isDispenseWithoutDipute
        ? t('bidding.process.section.general.action.watch-session')
        : t('bidding.process.section.general.action.watch-dispute');

    const processButtonRunningLabel = isDispenseWithoutDipute
        ? t('bidding.process.section.general.action.session')
        : t('bidding.process.section.general.action.dispute');

    const processButtonLabel =
        dispute === 'run' ? processButtonRunningLabel : processButtonNotRunningLabel;

    return (
        <>
            {!data?.isFinished && isDisabledParticipate && (
                <Alert severity='warning' variant='outlined'>
                    <AlertTitle>{`${t('term.process')} ${t(
                        'term.with'
                    ).toLocaleLowerCase()} ${benefitApplied.toLocaleLowerCase()}`}</AlertTitle>
                    <strong>{t(`regional_exclusivity.message-${labelBenefitApplied}`)}</strong>
                </Alert>
            )}
            <ProcessDescriptionCard elevation={0} variant='outlined'>
                <header>
                    {data && data.organizationUnit.organization.platform.length > 0 && (
                        <img
                            src={data?.organizationUnit.organization.platform[0].urlOriginalIcon}
                            alt='Plataforma'
                            title='Plataforma'
                        />
                    )}
                    <h1>
                        {data?.rule?.description} -{' '}
                        {data?.organizationUnit.organization.organizationName}
                    </h1>
                </header>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    <br />
                    <ProcessDescriptionContainer>
                        <div>
                            <p>
                                <strong>ID:</strong>{' '}
                                {loading ? <Skeleton width={50} variant='text' /> : id}
                            </p>
                            <p>
                                <strong>{t('bidding.process')}:</strong>{' '}
                                {loading ? (
                                    <Skeleton width={80} variant='text' />
                                ) : (
                                    data?.processNumber
                                )}
                            </p>
                            <p>
                                <strong>
                                    {loading ? (
                                        <Skeleton width={100} variant='text' />
                                    ) : (
                                        data?.rule?.description
                                    )}
                                    :
                                </strong>{' '}
                                {loading ? (
                                    <Skeleton width={60} variant='text' />
                                ) : (
                                    data?.purcharseNumber
                                )}
                            </p>
                            {![ProcessEnum.D, ProcessEnum.C].includes(
                                ProcessEnum[data?.processType || 'C']
                            ) && (
                                <p>
                                    <strong>
                                        {t('bidding.process.section.general.dispute-method')}:
                                    </strong>{' '}
                                    {loading ? (
                                        <Skeleton width={60} variant='text' />
                                    ) : (
                                        getMethodDispute(data?.disputeMethod?.id)
                                    )}
                                </p>
                            )}
                            {![ProcessEnum.L, ProcessEnum.C].includes(
                                ProcessEnum[data?.processType || 'E']
                            ) && (
                                <>
                                    <p>
                                        <strong>
                                            {t('bidding.process.section.general.judge-criterion')}:
                                        </strong>{' '}
                                        {loading ? (
                                            <Skeleton width={80} variant='text' />
                                        ) : (
                                            getJudgmentCriterion(
                                                data?.judgeCriterion?.id,
                                                Boolean(data?.rateTypeBid)
                                            )
                                        )}
                                    </p>
                                    <p>
                                        <strong>
                                            {t('bidding.process.section.general.regional-benefit')}:
                                        </strong>{' '}
                                        {loading ? (
                                            <Skeleton width={40} variant='text' />
                                        ) : (
                                            benefitApplied
                                        )}
                                    </p>
                                </>
                            )}
                            <p>
                                <strong>{data?.rule.team.operational.description}:</strong>{' '}
                                {loading ? (
                                    <Skeleton width={100} variant='text' />
                                ) : (
                                    data?.team.operator.name
                                )}
                            </p>
                            <p>
                                <strong>
                                    {t('bidding.process.section.general.extract-notice')}:
                                </strong>{' '}
                                {loading ? (
                                    <Skeleton width={120} variant='text' />
                                ) : (
                                    data?.simpleDescription
                                )}
                            </p>
                            <p>
                                <strong>{t('bidding.process.section.general.segments')}:</strong>{' '}
                                {loading ? (
                                    <>
                                        <Skeleton width={80} variant='text' />,
                                        <Skeleton width={120} variant='text' />
                                    </>
                                ) : (
                                    data?.segments.map(({ categoryName }) => categoryName).join(',')
                                )}
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>
                                    {getInitialDateLabel(ProcessEnum[data?.processType || 'E'])}
                                </strong>{' '}
                                {loading ? (
                                    <Skeleton width={120} variant='text' />
                                ) : (
                                    data?.startDateTimeToSendProposal &&
                                    formatDate(data.startDateTimeToSendProposal, 'L [-] LT')
                                )}
                            </p>
                            <p>
                                <strong>
                                    {t('bidding.process.section.general.date-platform')}:
                                </strong>{' '}
                                {loading ? (
                                    <Skeleton width={120} variant='text' />
                                ) : (
                                    data?.publishedDate &&
                                    formatDate(data.publishedDate, 'L [-] LT')
                                )}
                            </p>
                            {ProcessEnum.C === ProcessEnum[data?.processType || 'E'] ? (
                                <p>
                                    <strong>
                                        {getFinalDateLabel(ProcessEnum[data?.processType || 'E'])}:
                                    </strong>{' '}
                                    {loading ? (
                                        <Skeleton width={120} variant='text' />
                                    ) : (
                                        data?.endDateTimeToSendProposal &&
                                        formatDate(data?.endDateTimeToSendProposal, 'L [-] LT')
                                    )}
                                </p>
                            ) : (
                                <p>
                                    <strong>
                                        {getFinalDateLabel(ProcessEnum[data?.processType || 'E'])}:
                                    </strong>{' '}
                                    {loading ? (
                                        <Skeleton width={120} variant='text' />
                                    ) : (
                                        data?.startDateTimeDispute &&
                                        formatDate(data.startDateTimeDispute, 'L [-] LT')
                                    )}
                                </p>
                            )}
                            {ProcessEnum.D === ProcessEnum[data?.processType || 'E'] && (
                                <p>
                                    <strong>{t('term.dispute-duration')}:</strong>{' '}
                                    {loading ? (
                                        <Skeleton width={60} variant='text' />
                                    ) : (
                                        moment
                                            .utc((data?.disputeDuration || 0) * 1000)
                                            .format('HH:mm')
                                    )}
                                </p>
                            )}
                            {data?.deadlineImpugnment &&
                                ![ProcessEnum.C, ProcessEnum.D].includes(
                                    ProcessEnum[data?.processType || 'C']
                                ) && (
                                    <p>
                                        <strong>
                                            {t(
                                                'bidding.process.section.general.date-impugnation-limit'
                                            )}
                                            :
                                        </strong>{' '}
                                        {loading ? (
                                            <Skeleton width={120} variant='text' />
                                        ) : (
                                            data?.deadlineImpugnment &&
                                            formatDate(data.deadlineImpugnment, 'L [-] LT')
                                        )}
                                    </p>
                                )}
                        </div>
                    </ProcessDescriptionContainer>
                </Collapse>
                <section>
                    <ProcessButtonsContainer>
                        <div>
                            {!!authUser && (
                                <ProcessButton
                                    onClick={changeFavorite}
                                    outlined={!isFavorite}
                                    color='var(--primary-color)'
                                >
                                    {favoriteLoading && (
                                        <CircularProgress color='inherit' size={16} />
                                    )}
                                    {!favoriteLoading && <AiFillHeart size='1rem' />}

                                    {isFavorite &&
                                        t('bidding.process.section.general.action.unfavorite')}
                                    {!isFavorite &&
                                        t('bidding.process.section.general.action.change-favorite')}
                                </ProcessButton>
                            )}
                        </div>
                        <div>
                            {!data?.isFinished &&
                                (participation.participate === 'proposal' ||
                                    participation.participate === 'create') && (
                                    <Tooltip title={messageButtonParticipate}>
                                        <span>
                                            <ProcessButton
                                                onClick={handleParticipate}
                                                color='var(--secondary-color)'
                                                disabled={isDisabledParticipate}
                                            >
                                                <FaHandsHelping size='1rem' />
                                                {t(
                                                    'bidding.process.section.general.action.proposal'
                                                )}
                                            </ProcessButton>
                                        </span>
                                    </Tooltip>
                                )}
                            {participation.participate === 'checkout' && !data?.isFinished && (
                                <Tooltip title={messageButtonParticipate}>
                                    <span>
                                        <ProcessButton
                                            loading={participation.loading}
                                            onClick={handleParticipate}
                                            color='var(--secondary-color)'
                                            disabled={isDisabledParticipate}
                                        >
                                            {participation.loading && (
                                                <ProcessButtonActivityIndicator
                                                    size={20}
                                                    color='inherit'
                                                />
                                            )}
                                            <IoMdPlay size='1rem' />
                                            {`${t(
                                                'bidding.process.section.general.action.participate'
                                            )}`}
                                        </ProcessButton>
                                    </span>
                                </Tooltip>
                            )}

                            {dispute !== 'none' && (
                                <ProcessButton
                                    onClick={handleDispute}
                                    color='var(--secondary-color)'
                                >
                                    {dispute === 'run' ? (
                                        <RiSwordFill size='1rem' />
                                    ) : (
                                        <RiEyeFill size='1rem' />
                                    )}
                                    {processButtonLabel}
                                </ProcessButton>
                            )}
                        </div>
                    </ProcessButtonsContainer>
                </section>
            </ProcessDescriptionCard>
        </>
    );
};

export default GeneralInfoContainer;
