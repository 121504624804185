import { useEffect, useMemo } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { useParams } from 'react-router';
import NotFound from 'routes/not-found';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { OrderStatusEnum } from 'common/enums';
import JudgmentView from './judgment-page-view';

const JudgmentPage = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { orderId } = useParams<{ orderId?: string }>();

    if (!orderId) {
        return <NotFound />;
    }

    const orderFilter = useMemo(
        () => ({
            orderId: Number(orderId),
        }),
        [orderId]
    );

    const { data: orderData, loading: loadingOrder } = usePaginatedQuery(
        marketplaceRequests.listOrdersByProvider,
        orderFilter
    );
    const order = orderData?.data?.[0];

    useEffect(() => {
        setTitle(t('term.judgment'));
    });

    const isJudged = order?.status !== OrderStatusEnum.OPENED;

    return <JudgmentView order={order} loading={loadingOrder} isJudged={isJudged} />;
};

export default JudgmentPage;
