import { Route } from 'react-router';
import UnsubscribeLgpd from './pages/unsubscribe-lgpd';
import BoxConfirmChangeEmail from './pages/confirm-change-email';

const PublicRoutes: JSX.Element[] = [
    <Route
        path='/change-email'
        key='/change-email'
        exact
        render={() => <BoxConfirmChangeEmail />}
    />,
    <Route
        path='/public/unsubscribe/:token'
        key='/public/unsubscribe'
        exact
        component={UnsubscribeLgpd}
    />,
];

export default PublicRoutes;
