import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { OkResponse } from 'clients/interfaces/ok.interface';
import {
    CreateNoticeInformationBody,
    NoticesInformationRequest,
    NoticesInformationResponse,
    UploadFileNoticeInformationResponse,
    UniqueNoticeInformationResponse,
    UpdateNoticeInformationBody,
    UnreadedInformationCount,
    LatestInformation,
} from './interfaces/notice-information.interfaces';

const noticesInformationRequests = {
    getAllNoticesInformation: async (data?: NoticesInformationRequest) =>
        doRequest<NoticesInformationResponse>(
            managerApiInstance.post(`/notices-information/listNoticeInformation`, {
                limit: data?.limit || 10,
                offset: data?.offset || 0,
                orderBy: data?.orderBy,
                titleResume: data?.titleResume,
                expirationAt: data?.expirationAt,
            })
        ),
    getUniqueNoticeInformation: async (id: number) =>
        doRequest<UniqueNoticeInformationResponse>(
            managerApiInstance.post(`/notices-information/getNoticeInformation/${id}`)
        ),
    createNoticeInformation: async (data: CreateNoticeInformationBody) =>
        doRequest<UniqueNoticeInformationResponse>(
            managerApiInstance.post(`/information/createNoticeInformation`, data)
        ),
    updateNoticeInformation: async (data: UpdateNoticeInformationBody, id: number) =>
        doRequest<UniqueNoticeInformationResponse>(
            managerApiInstance.post(`/information/updateNoticeInformation/${id}`, data)
        ),
    uploadFileNoticeInformation: async (file: File) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        return doRequest<PlainGenericResponse<UploadFileNoticeInformationResponse>>(
            managerApiUploadFileInstance.post(
                '/information/uploadFileNoticeInformation',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
        );
    },
    deleteNoticeInformation: async (id: number) => {
        return doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.delete(`/information/deleteNoticeInformation/${id}`)
        );
    },
    deleteFileNoticeInformation: async (filename: string) => {
        return doRequest<PlainGenericResponse<OkResponse>>(
            managerApiUploadFileInstance.post(
                `/information/deleteFileNoticeInformation/${filename}`
            )
        );
    },
    getSingleInformation: async (showWhere: 'page' | 'widget' | 'topbar' | 'tooltip' | 'page') =>
        doRequest<GenericResponse<LatestInformation> | PlainGenericResponse<LatestInformation>>(
            managerApiInstance.get('/information/single', { params: { showWhere } })
        ),
    getSingleUnreadedInformationCount: async (
        showWhere: 'page' | 'widget' | 'topbar' | 'tooltip' | 'page'
    ) =>
        doRequest<PlainGenericResponse<UnreadedInformationCount>>(
            managerApiInstance.get('/information/unreaded', { params: { showWhere } })
        ),
    readInformation: async (informationId: number) =>
        doRequest<PlainGenericResponse<string>>(
            managerApiInstance.post(`/information/read/${informationId}`)
        ),
};

export { noticesInformationRequests };
