import { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStylesActions from './footer-actions-style';

interface FooterConfirmChangeEmailAdminProps {
    onClose: () => void;
    onConfirm: (values?: any) => void;
}

export const FooterActions: FC<FooterConfirmChangeEmailAdminProps> = ({ onClose, onConfirm }) => {
    const { t } = useTranslation();
    const styles = useStylesActions();

    return (
        <footer className={styles.container}>
            <Button className={styles.cancelButton} onClick={onClose}>
                <Typography className={styles.buttonText}>
                    {t('code-validator.action-button.cancel')}
                </Typography>
            </Button>
            <div className={styles.divider} />
            <Button type='button' className={styles.confirmButton} onClick={onConfirm}>
                <Typography className={styles.buttonText}>{t('term.continue')}</Typography>
            </Button>
        </footer>
    );
};
