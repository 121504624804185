import { doRequest, managerApiInstance } from 'clients/api';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { AxiosRequestConfig } from 'axios';
import { ReponseDecode, BodyValidateById } from '../interfaces/verification-email.interface';

const BASE_PATH = '/verification-email';

const verificationEmailRequests = {
    decodeEmailChangeHash: async (hash?: string, config?: AxiosRequestConfig<any>) =>
        doRequest<PlainGenericResponse<ReponseDecode>>(
            managerApiInstance.post(`${BASE_PATH}/decode-email-change-hash`, { hash }, config)
        ),
    validateById: async (body?: BodyValidateById, config?: AxiosRequestConfig<any>) =>
        doRequest<PlainGenericResponse<ReponseDecode>>(
            managerApiInstance.post(
                `${BASE_PATH}/valid/${body?.id}`,
                {
                    confirm: body?.confirm,
                    companyId: body?.companyId,
                    typeCompany: body?.typeCompany,
                },
                config
            )
        ),
};

export { verificationEmailRequests };
