import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

const helperText = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: formatPxToRem(5),
    fontFamily: 'Public Sans Variable',
    fontSize: formatPxToRem(12),
    fontWeight: 400,
    lineHeight: formatPxToRem(16),
    marginTop: formatPxToRem(8),
});

const useStyles = makeStyles<Theme>(() => ({
    loading: {
        ...helperText(),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    main: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
    },
    containerError: {
        textAlign: 'center',
        padding: '20px',
        border: '1px solid #f5c6cb',
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '50vh',
    },
    containerSucess: {
        textAlign: 'center',
        padding: '20px',
        border: '1px solid green',
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '50vh',
    },
    titleError: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '2em',
        marginBottom: '10px',
        color: '#dc3545',
    },
    titleSucess: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '2em',
        marginBottom: '10px',
        color: 'green',
    },
    description: {
        fontSize: '1.2em',
        marginBottom: '20px',
    },
    errorCode: {
        fontSize: '1em',
        fontStyle: 'italic',
        marginBottom: '20px',
    },
    redirectMain: {
        height: '100vh;',
        backgroundColor: '#f2f2f2',
    },
    icon: {
        height: '3vh',
    },
}));

export default useStyles;
