import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse, NoDataGenericResponse } from 'clients/interfaces/paginated.interface';
import { AxiosRequestConfig } from 'axios';
import {
    HiringPlanResponse,
    HiringPlanRequest,
    ListPcaItemBody,
    HiringPlanItensResponse,
    HiringPlanItemUnitRequest,
    HiringPlansListRequest,
    PcaDashBoardData,
    PcaDataChartCategory,
    GetPcaDataChartCategoryBody,
} from './interfaces/hiring-plans.interfaces';

const hiringPlansRequests = {
    createHiringPlan: async (data: HiringPlanRequest) =>
        doRequest<HiringPlanResponse>(managerApiInstance.post(`/pca/createPca`, data)),
    createHiringPlanUnitItem: async (data: HiringPlanItemUnitRequest) =>
        doRequest<HiringPlanItensResponse>(managerApiInstance.post(`/pca/createPcaItem`, data)),
    editHiringPlanUnitItem: async (data: HiringPlanItemUnitRequest) =>
        doRequest<HiringPlanItensResponse>(managerApiInstance.post(`/pca/updatePcaItem`, data)),
    deleteHiringPlanUnitItem: async (
        data: Pick<HiringPlanItemUnitRequest, 'id' | 'justification'>
    ) => doRequest<{}>(managerApiInstance.post(`/pca/deletePcaItem`, data)),
    getAllHiringPlans: async (data?: HiringPlansListRequest) =>
        doRequest<Array<HiringPlanResponse>>(managerApiInstance.post(`/pca/listPca`, data)),
    getListItensHiringPlan: async (body?: ListPcaItemBody, config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<HiringPlanItensResponse>>(
            managerApiInstance.post(`/pca/listPcaItem`, body, config)
        ),
    synchronizeHiringPlanWithPncp: async (id: string, organizationUnitId: number) =>
        doRequest<NoDataGenericResponse>(
            managerApiInstance.post(
                `/pca/synchronizePca`,
                { id, organizationUnitId },
                // @TODO: Tornar essa requisição assíncrona e retirar o timeout
                { timeout: 30000 }
            )
        ),
    synchronizeHiringPlanMissingItemsPncp: async (pcaId: number, organizationUnitId: number) =>
        doRequest<NoDataGenericResponse>(
            managerApiInstance.post(`/pca/synchronizePcaItem`, { pcaId, organizationUnitId })
        ),
    getPcaDashboardAmountData: async (pcaId: number) =>
        doRequest<GenericResponse<PcaDashBoardData>>(
            managerApiInstance.get(`/pca/dashboardAmountData/${pcaId}`)
        ),
    getPcaDataChartCategory: async (
        body?: GetPcaDataChartCategoryBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<PcaDataChartCategory>>(
            managerApiInstance.get(`/pca/dataChartCategory/${body?.pcaId}`, config)
        ),
};

export { hiringPlansRequests };
