import { doRequest, managerApiInstance } from '../api';
import { PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    CheckAccessTokenIsValidResponse,
    DoLoginTypeAccess,
    GetTokenByCodeDto,
    GetTokenByCodeResponse,
} from './interfaces/auth.interface';

const authRequests = {
    doLoginTypeAccess: async (data: DoLoginTypeAccess) =>
        doRequest<PlainGenericResponse<{ token: string }>>(
            managerApiInstance.post(`/auth/doLoginTypeAccess`, data)
        ),
    getTokenByCode: async (data: GetTokenByCodeDto) =>
        doRequest<PlainGenericResponse<GetTokenByCodeResponse>>(
            managerApiInstance.post(`/auth/getTokenByCode`, data)
        ),
    checkAccessTokenIsValid: async () =>
        doRequest<PlainGenericResponse<CheckAccessTokenIsValidResponse>>(
            managerApiInstance.get(`/auth/checkAccessTokenIsValid`)
        ),
};

export { authRequests };
