import { useEffect, useRef, useState, FC, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    defaultVariables as getDefaultVariables,
    editalVariables,
} from 'modules/editor/services/variables.service';
import {
    internalRedirect,
    decryptEncodedParam,
    addNotificationError,
    addNotificationSuccess,
    getBackofficeRedirectUrl,
    apiRedirectTo,
} from 'common/utils';
import PageErrorFetchData from 'common/components/page-error-fetch-data';
import { Typography, Box, Breadcrumbs } from '@material-ui/core';
import { Button } from 'common/components';
import { templateVariablesRequests } from 'clients/manager/documents/template-variables.requests';
import { DocumentVariables } from 'clients/manager/interfaces/document-variables.interface';
import {
    ProcessVariable,
    CustomVariable,
    DefaultVariable,
} from 'modules/editor/interfaces/variables.interface';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { DocumentProcessType } from 'clients/manager/interfaces';
import { useSelector } from 'react-redux';
import { DocumentTemplate } from 'clients/manager/interfaces/document-templates.interface';
import { usePageContext } from 'common/components/base/pages/context';
import { useTranslation } from 'react-i18next';
import { ProcessDocumentsTemplates } from 'clients/manager/interfaces/process-documents-templates.interface';
import { processDocumentsTemplatesRequests } from 'clients/manager/documents/process-documents-templates.requests';
import { AppState } from 'store';
import LoadingButton from 'common/components/loading-button';
import { Header, EditArea, Page } from './styles';
import { DocumentEditorProps } from './props';
import DynamicEditor from './dynamic-editor';
import { PendentVariables, variablesToReplace } from './variables-to-replace';
import { replaceVariablesToValue } from './auto-replace-variables';
import { getDocumentVariables, getReplacedVariables } from './mount-auction-variables';
import VariablesToReplace from '../../components/variables-to-replace';
import ModalCreateVariable from '../../../templates/components/modal-create-variable';
import VariablesList from '../../../templates/components/variables-list';
import { getProccessDocumentRedirectUrl } from '../../redirect-url';

interface Params {
    [key: string]: string;
}

const EditProcessDocumentTemplate: FC<DocumentEditorProps> = () => {
    const query = new URLSearchParams(useLocation().search);
    const { t } = useTranslation();
    const history = useHistory();
    const { currentAccessType } = useSelector((state: AppState) => state.authUserState);

    const [template, setTemplate] = useState<DocumentTemplate | undefined>(undefined);
    const [getTemplateWithError, setTemplateWithError] = useState(false);
    const [processDocumentTemplate, setProcessDocumentTemplate] = useState<
        ProcessDocumentsTemplates | undefined
    >();
    const [processTemplateDocumentWithError, setProcessTemplateDocumentWithError] = useState(false);
    const [showVariables, setShowVariables] = useState(false);
    const [creatingVariable, setCreatingVariable] = useState(false);
    const [customVariables, setCustomVariables] = useState<DocumentVariables[]>([]);
    const [editorState, setEditorState] = useState<string>('');
    const [contentReplaced, setContentReplaced] = useState<string>('');
    const [editingDocument, setEditingDocument] = useState(false);
    const [editingVariable, setEditingVariable] = useState<DocumentVariables | undefined>();
    const [updatingDocument, setUpdatingDocument] = useState(false);
    const [pendentVariablesToReplace, setPendentVariablesToReplace] = useState<PendentVariables>(
        {}
    );

    const defaultVariables = getDefaultVariables();

    const getPendentVariables = (html: string) =>
        variablesToReplace(html, {
            defaults: defaultVariables,
            customs: customVariables ?? [],
            auction: processDocumentTemplate?.processTypes
                ? editalVariables(processDocumentTemplate.processTypes)
                : [],
        });

    const { processDocumentTemplateId = undefined } = useParams<Params>();
    const editorStateRef = useRef(editorState);
    const { setTitle } = usePageContext();

    const content = processDocumentTemplate?.content || template?.content || '';
    const initialPendentVariable = getPendentVariables(content);

    const getContentReplaced = async () => {
        if (!template || !processDocumentTemplate) {
            return '';
        }

        const { auctionId } = processDocumentTemplate;
        const variables = getDocumentVariables(content);

        if (!variables?.length || !auctionId) {
            return content;
        }

        const auctionVariables = await getReplacedVariables(auctionId, variables);
        const replacedContent = replaceVariablesToValue(
            content,
            defaultVariables,
            auctionVariables ?? undefined
        );

        return replacedContent;
    };

    const onProcessPendentVariables = (html: string) => {
        setPendentVariablesToReplace(getPendentVariables(html));
    };

    const replaceAllVariables = async () => {
        const replacedContent = await getContentReplaced();
        editorStateRef.current = replacedContent;
        setContentReplaced(replacedContent);
        onProcessPendentVariables(replacedContent);
    };

    useEffect(() => {
        replaceAllVariables();
    }, [template, processDocumentTemplate]);

    const getTemplate = async () => {
        if (!processDocumentTemplate?.fromTemplateId) {
            return;
        }

        try {
            const { data: docTemplate } = await templateRequests.getTemplate(
                processDocumentTemplate.fromTemplateId
            );
            setTemplate(docTemplate);
        } catch (error) {
            setTemplateWithError(true);
            addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.process.pages.edit-process.index.notification-error-onload-template'
                ),
            });
        }
    };

    const getGeneratedDocument = async () => {
        if (!processDocumentTemplateId) {
            return;
        }

        try {
            const response = await processDocumentsTemplatesRequests.getProcessDocumentTemplate(
                processDocumentTemplateId
            );
            setProcessDocumentTemplate(response.data);
        } catch (error) {
            setProcessTemplateDocumentWithError(true);
            addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.process.pages.edit-process.index.notification-error-onload-document'
                ),
            });
        }
    };

    const getCustomVariables = async () => {
        try {
            const variables = await templateVariablesRequests.getVariables();
            if (variables?.length) {
                setCustomVariables(variables);
            }
        } catch (error) {
            addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.process.pages.edit-process.index.notification-error-onload-var-custom'
                ),
            });
        }
    };

    useEffect(() => {
        getTemplate();
        getCustomVariables();
    }, [processDocumentTemplate]);

    useEffect(() => {
        setTitle(t('editor.process.pages.create-process.info-breadcrumb'));
        getGeneratedDocument();
    }, []);

    useEffect(() => {
        if (query.get('mode') === 'edit') {
            setEditingDocument(true);
        }
    }, []);

    const handleEditorChange = (html: string) => {
        editorStateRef.current = html;
        setEditorState(html);
        onProcessPendentVariables(html);
    };

    const getVariables = (processTypes: DocumentProcessType[]) =>
        ({
            defaults: defaultVariables,
            customs:
                customVariables.map((variable) => ({
                    name: variable.name,
                    description: variable.description,
                    id: variable._id,
                    values: variable.values,
                })) ?? [],
            auction: editalVariables(processTypes),
        } as {
            defaults: DefaultVariable[];
            customs: CustomVariable[];
            auction: ProcessVariable[];
        });

    const updateDocument = async (silent?: boolean) => {
        if (!processDocumentTemplate) {
            return;
        }

        setUpdatingDocument(true);

        try {
            const document: ProcessDocumentsTemplates = {
                ...processDocumentTemplate,
                content: editorStateRef.current,
            };
            await processDocumentsTemplatesRequests.updateProcessDocumentTemplate(
                processDocumentTemplate._id,
                document
            );
            if (!silent) {
                setUpdatingDocument(false);
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('term.updated-document'),
                });
            }
        } catch (error) {
            if (silent) {
                return;
            }
            setUpdatingDocument(false);
            addNotificationError({
                title: t('term.err'),
                message: t(
                    'editor.process.pages.edit-process.index.notification-error-created-document'
                ),
            });
        }
    };

    const handleShowVariables = () => {
        setShowVariables(true);
    };

    const getView = () => {
        if (getTemplateWithError) {
            return (
                <PageErrorFetchData>
                    <p>{t('editor.process.pages.edit-process.index.msg-error-onload-template')}</p>
                </PageErrorFetchData>
            );
        }

        if (template && processDocumentTemplate) {
            return (
                <EditArea>
                    <DynamicEditor
                        variables={getVariables(processDocumentTemplate.processTypes)}
                        onProcessPendentVariables={onProcessPendentVariables}
                        initialData={contentReplaced}
                        handleEditorChange={handleEditorChange}
                        editing={editingDocument}
                        processDocumentTemplateId={processDocumentTemplate._id}
                    />
                    {Object.values(initialPendentVariable).some(
                        (variableList) => variableList.length
                    ) ? (
                        <VariablesToReplace
                            initialPendentVariablesToReplace={initialPendentVariable}
                            pendentVariablesToReplace={pendentVariablesToReplace}
                        />
                    ) : null}
                </EditArea>
            );
        }
    };

    if (processTemplateDocumentWithError) {
        return (
            <PageErrorFetchData>
                <p>{t('editor.process.pages.edit-process.index.msg-error-onload-document')}</p>
            </PageErrorFetchData>
        );
    }

    const getAuctionData = useCallback(() => {
        const encodedAuction = query.get('auction');
        return decryptEncodedParam<{ id: number; auctionNumber: string; auctionType: string }>(
            encodedAuction ?? ''
        );
    }, [query]);

    const backofficeRedirectUrl = getBackofficeRedirectUrl();
    const auctionData = getAuctionData();

    const getFromAuctionBreadCrumb = () => {
        const redirect = () => {
            if (!auctionData) {
                return;
            }

            const from = query.get('from');

            if (from) {
                return history.push(from);
            }

            const url = apiRedirectTo({
                backofficeRedirectUrl,
                redirectUrl: getProccessDocumentRedirectUrl(
                    '/',
                    auctionData?.id,
                    auctionData?.auctionType
                ),
                isPublic: false,
                isDisableRedirect: true,
            }) as string;

            if (currentAccessType) {
                return internalRedirect(backofficeRedirectUrl, url, currentAccessType, true);
            }

            internalRedirect(backofficeRedirectUrl, url, undefined, true);
        };

        return (
            <Breadcrumbs color='primary'>
                <Typography onClick={redirect} style={{ cursor: 'pointer' }}>
                    <Typography>{auctionData?.auctionNumber ?? '..'}</Typography>
                </Typography>
                <Typography>{t('editor.process.pages.create-process.info-breadcrumb')}</Typography>
            </Breadcrumbs>
        );
    };

    return (
        <Page>
            <Header>
                {getFromAuctionBreadCrumb()}
                <Box flex alignItems='center'>
                    {template ? (
                        <>
                            <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                style={{ margin: '2px 0 0 10px', backgroundColor: '#fff' }}
                                title={t('editor.process.pages.edit-process.index.show-variables')}
                                onClick={handleShowVariables}
                            >
                                {t('editor.process.pages.edit-process.index.show-variables')}
                            </Button>
                            {!editingDocument && (
                                <Button
                                    size='small'
                                    title={t('term.edit-doc')}
                                    variant='outlined'
                                    color='primary'
                                    style={{ margin: '2px 0 0 10px', backgroundColor: '#fff' }}
                                    onClick={() => setEditingDocument(!editingDocument)}
                                >
                                    {t('term.edit')}
                                </Button>
                            )}
                            <LoadingButton
                                size='small'
                                variant='contained'
                                style={{ margin: '2px 0 0 10px' }}
                                color='primary'
                                onClick={() => updateDocument()}
                                {...(updatingDocument
                                    ? {
                                          loading: {
                                              text: `${t('term.updating')}..`,
                                          },
                                      }
                                    : {})}
                            >
                                {t('term.save')}
                            </LoadingButton>
                        </>
                    ) : null}
                </Box>
            </Header>
            {getView()}
            {creatingVariable && (
                <ModalCreateVariable
                    opened={creatingVariable}
                    onClose={() => setCreatingVariable(false)}
                    onCreate={(variable) => {
                        setCreatingVariable(false);
                        setShowVariables(true);
                        setCustomVariables((prevState) => [...prevState, variable]);
                    }}
                />
            )}
            {!!editingVariable && (
                <ModalCreateVariable
                    variable={editingVariable}
                    opened={!!editingVariable}
                    onClose={() => setEditingVariable(undefined)}
                    onCreate={(variable) => {
                        setEditingVariable(undefined);
                        setShowVariables(true);
                        setCustomVariables((prevState) => [
                            ...prevState.map((customVariable) => {
                                if (customVariable._id === variable._id) {
                                    return variable;
                                }
                                return customVariable;
                            }),
                        ]);
                    }}
                />
            )}
            {showVariables && template && processDocumentTemplate && (
                <VariablesList
                    visible={showVariables}
                    onClickCreateVariable={() => {
                        setShowVariables(false);
                        setCreatingVariable(true);
                    }}
                    onClickEditVariable={(variable) => {
                        setShowVariables(false);
                        setEditingVariable(variable);
                    }}
                    onClose={() => setShowVariables(false)}
                    variables={{
                        defaults: defaultVariables,
                        customs: customVariables ?? [],
                        auction: editalVariables(processDocumentTemplate.processTypes),
                    }}
                />
            )}
        </Page>
    );
};

export default EditProcessDocumentTemplate;
