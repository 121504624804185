import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Modal, ModalPosition } from 'common/components';
import { FormikProps } from 'formik';
import censorEmail from 'common/utils/censor-email';
import useStyles from './code-validator-style';
import VerificationField from './components/verification-field';
import CodeValidatorActions from './components/code-validator-actions';
import { CodeValidatorFormValues } from './code-validator';
import SendCodeCountdown from './components/resend-code-countdown';

interface CodeValidatorViewProps {
    open: boolean;
    form: FormikProps<CodeValidatorFormValues>;
    onClose?: () => void;
    onResendCode?: () => void;
    email?: string;
    errorSendCode?: string;
    setErrorSendCode?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CodeValidatorView: React.FC<CodeValidatorViewProps> = ({
    open,
    form,
    onClose,
    onResendCode,
    email,
    errorSendCode,
    setErrorSendCode,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Modal
            open={open}
            position={ModalPosition.center}
            borderRadius={8}
            onClose={(event: any) => {
                event.stopPropagation();
                onClose?.();
            }}
            footer={
                <CodeValidatorActions
                    onConfirm={() => form.handleSubmit()}
                    onClose={onClose}
                    disabled={!form.isValid || !form.dirty || form.isSubmitting}
                />
            }
        >
            <div className={styles.container}>
                <img src='/assets/icons/lock.svg' alt='Title icon' className={styles.icon} />
                <Typography className={styles.title}>{t('code-validator.modal-title')}</Typography>
                <Typography className={styles.subTitle}>
                    {t('code-validator.modal-subtitle', {
                        email: email ? ` (${censorEmail(email)})` : '',
                    })}
                </Typography>
                <VerificationField
                    value={form.values.verificationCode}
                    onChange={(value) => form.setFieldValue('verificationCode', value)}
                    onBlur={() => form.setFieldTouched('verificationCode', true)}
                    hasError={!!form.touched.verificationCode && !!form.errors.verificationCode}
                    isLoading={form.isSubmitting}
                    errorText={form.errors.verificationCode}
                />
                {errorSendCode ? (
                    <>
                        <Typography className={styles.error}>
                            {errorSendCode}
                            <img
                                className={styles.icon_error}
                                src='/assets/icons/error_filled.svg'
                                alt='Error icon'
                            />
                        </Typography>
                        <SendCodeCountdown
                            onResendCode={onResendCode}
                            timeout={60}
                            setErrorSendCode={setErrorSendCode}
                        />
                    </>
                ) : (
                    <SendCodeCountdown onResendCode={onResendCode} />
                )}
            </div>
        </Modal>
    );
};

export default CodeValidatorView;
