import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Input, Select, Switch, TextArea } from '@licitar/ui-kit';
import { YupValidationMessages } from 'common/constants/yup-validation-messages.constant';
import { templateRequests } from 'clients/manager/documents/templates.requests';
import { DocumentProcessType } from 'clients/manager/interfaces';
import {
    DocumentTemplate,
    DocumentTemplateType,
} from 'clients/manager/interfaces/document-templates.interface';
import { Box } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { useTranslation } from 'react-i18next';
import { addNotificationWarning } from 'common/utils';
import { validateOnlyBlank } from 'common/utils/validators/validate-only-blank.utils';
import { Content, ActionsArea } from './styles';
import { ModalCreateTemplateProps } from './props';
import SelectTemplateType from '../select-template-type';
import SelectSupplyCategories from '../select-supply-categories';

const ModalCreateTemplate: FC<ModalCreateTemplateProps> = ({ onCancel, fromTemplateId }) => {
    const initialValues = {
        type: DocumentTemplateType.contract,
        processTypes: [],
        name: undefined,
        description: '',
        public: undefined,
        supplyCategories: [],
    };
    const { t } = useTranslation();

    const [template, setTemplate] = useState<DocumentTemplate | undefined>();
    const history = useHistory();

    const authUserIsAdmin: boolean = useSelector(
        (state: AppState) => state.authUserState.authUserTokenData?.isSystemAdmin || false
    );

    const getTemplate = async () => {
        if (!fromTemplateId) {
            return;
        }
        try {
            const { data: templateDocument } = await templateRequests.getTemplate(fromTemplateId);
            setTemplate(templateDocument);
        } catch (error) {
            addNotificationWarning({
                title: t('term.error'),
                message: t(
                    'editor.templates.components.modal-create-template.notification-error-search-template'
                ),
            });
            onCancel();
        }
    };

    useEffect(() => {
        if (fromTemplateId) {
            getTemplate();
        }
    }, [fromTemplateId]);

    const createTemplate = async (values: any) => {
        try {
            const data: Partial<DocumentTemplate> = {
                ...values,
            };

            const response = await templateRequests.createTemplate(
                template
                    ? {
                          ...template,
                          ...data,
                      }
                    : data
            );

            const templateDocument = response?.data;

            if (templateDocument?._id) {
                return history.push(`/processos/editor/modelos/${templateDocument._id}/edit`);
            }
        } catch (error) {
            addNotificationWarning({
                title: t(
                    'editor.templates.components.modal-create-template.notification-error-create-doc-title'
                ),
                message: t(
                    'editor.templates.components.modal-create-template.notification-error-create-doc'
                ),
            });
        }
    };

    const form = useFormik<Partial<DocumentTemplate>>({
        initialValues: { ...template, name: undefined },
        enableReinitialize: true,
        validationSchema: Yup.object({
            type: Yup.number().required(YupValidationMessages.REQUIRED),
            processTypes: Yup.array()
                .of(Yup.number())
                .min(1)
                .required(YupValidationMessages.REQUIRED),
            name: Yup.string().required(YupValidationMessages.REQUIRED),
            description: Yup.string(),
            public: Yup.bool(),
            supplyCategories: Yup.array()
                .of(Yup.number())
                .min(1)
                .required(YupValidationMessages.REQUIRED),
        }),
        onSubmit: (values) => createTemplate(values),
    });

    const getProcessTypeOptions = () => [
        { label: t('term.accreditation'), value: DocumentProcessType.accreditation },
        { label: t('term.trading'), value: DocumentProcessType.auction },
        { label: t('term.waiver'), value: DocumentProcessType.exemption },
        { label: t('term.auction'), value: DocumentProcessType.publicSale },
        { label: t('term.competition'), value: DocumentProcessType.concurrence },
        { label: t('term.unenforceability'), value: DocumentProcessType.unenforceability },
        { label: t('term.competitive-dialogue'), value: DocumentProcessType.competitiveDialogue },
        { label: t('term.contest'), value: DocumentProcessType.contest },
        {
            label: t('term.expression-of-interest'),
            value: DocumentProcessType.expressionOfInterest,
        },
        { label: t('term.pre-qualification'), value: DocumentProcessType.preQualification },
    ];

    const isNotBlank = validateOnlyBlank(form.values);

    return (
        <Content>
            <Modal
                position={ModalPosition.center}
                open
                onClose={onCancel}
                header={
                    <span>
                        {fromTemplateId && template
                            ? `${t(
                                  'editor.templates.components.modal-create-template.msg-modal-header-1'
                              )} ${template.name}`
                            : t(
                                  'editor.templates.components.modal-create-template.msg-modal-header-2'
                              )}
                    </span>
                }
            >
                <Box width='450px' flex>
                    <Select
                        value={form.values?.processTypes?.map((processType) => {
                            const currentOption = getProcessTypeOptions().find(
                                (option) => option.value === processType
                            );

                            return {
                                label: currentOption?.label,
                                value: currentOption?.value,
                            };
                        })}
                        isMulti
                        name='type'
                        label={t('term.process-types')}
                        options={getProcessTypeOptions()}
                        onChange={(options) => {
                            form.setFieldValue(
                                'processTypes',
                                options.map((option) => option.value)
                            );
                        }}
                    />
                    <Box marginTop={1}>
                        <SelectSupplyCategories
                            selectedValues={form.values.supplyCategories}
                            onChange={(supplyCategories) =>
                                form.setFieldValue('supplyCategories', supplyCategories)
                            }
                        />
                    </Box>
                    <div style={{ margin: '10px 0 0 0' }}>
                        <SelectTemplateType
                            selectedValue={form.values.type}
                            onChange={(value) => form.setFieldValue('type', value)}
                        />
                    </div>
                    <Input
                        style={{ margin: '10px 0 0 0' }}
                        value={form.values.name}
                        name='name'
                        autoFocus
                        label={t('term.model-name')}
                        onChange={(ev) => form.setFieldValue('name', ev.target.value)}
                    />
                    <TextArea
                        style={{ margin: '10px 0 0 0' }}
                        label={t('term.description')}
                        name='description'
                        value={form.values.description}
                        onChange={(ev) => {
                            form.setFieldValue('description', ev.target.value);
                        }}
                    />
                    {authUserIsAdmin && !fromTemplateId && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                margin: '10px 0',
                            }}
                        >
                            <Switch
                                name='public'
                                label={t('term.public')}
                                checked={!!form.values.public}
                                onChange={() => {
                                    form.setFieldValue('public', !form.values.public);
                                }}
                            />
                        </div>
                    )}
                    <ActionsArea>
                        <Button
                            title={t('term.cancel')}
                            size='small'
                            variant='outlined'
                            color='default'
                            onClick={() => onCancel()}
                        >
                            {t('term.cancel')}
                        </Button>
                        <Button
                            disabled={
                                !isNotBlank ||
                                !form.dirty ||
                                !!Object.keys(form.errors).length ||
                                (!!fromTemplateId && !template)
                            }
                            title={t(
                                'editor.templates.components.modal-create-template.msg-modal-header-2'
                            )}
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={() => form.submitForm()}
                        >
                            {t('term.create')}
                        </Button>
                    </ActionsArea>
                </Box>
            </Modal>
        </Content>
    );
};

export default ModalCreateTemplate;
