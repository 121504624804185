import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from 'common/theme';
import { formatPxToRem } from 'common/utils';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: 0,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: formatPxToRem(16),
        marginBottom: formatPxToRem(24),
    },
    title: {
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(16),
        color: theme.palette.neutral['600'],
    },
    pagination: { display: 'flex', justifyContent: 'flex-end' },
    notFoundLabel: {
        textAlign: 'center',
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(14),
        color: theme.palette.neutral['500'],
    },
}));
