import React from 'react';

import { OrderItem, OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useHistory } from 'react-router';
import { OrderItemStatusEnum, OrderStatusEnum } from 'common/enums';
import { Skeleton } from '@material-ui/lab';
import ProcessOrderCardView from './process-order-card-view';
import { useStyles } from './process-order-card-style';

interface ProcessOrderCardProps {
    order: OrderResponse;
}

export const ProcessOrderCardSkeleton = () => {
    const styles = useStyles();

    return <Skeleton variant='rect' height={150} width='100%' className={styles.skeleton} />;
};

const ProcessOrderCard: React.FC<ProcessOrderCardProps> = ({ order }) => {
    const history = useHistory();

    const redirectToOrderPage = () => {
        if (order.status === OrderStatusEnum.FINISHED) {
            return history.push(`/mercado/pedidos/empenho/${order.id}`);
        }
        return history.push(`/mercado/pedidos/julgamento/${order.id}`);
    };

    const calculateAveragePrice = (orderItem: OrderItem) => {
        return orderItem.item.prices.mid * (orderItem.itemAmount ?? 1);
    };
    const calculateEstimatedValue = (orderItem: OrderItem) => {
        return orderItem.item.prices.estimated * (orderItem.itemAmount ?? 1);
    };

    const items = order?.items.filter((item) => item.status !== OrderItemStatusEnum.FRUSTRATED);

    const totalEstimatedValue =
        items?.reduce((acc, orderItem) => acc + calculateEstimatedValue(orderItem), 0) ?? 0;
    const totalValue =
        items?.reduce((acc, orderItem) => acc + calculateAveragePrice(orderItem), 0) ?? 0;

    return (
        <ProcessOrderCardView
            order={order}
            totalEstimatedValue={totalEstimatedValue}
            totalValue={totalValue}
            onClick={redirectToOrderPage}
        />
    );
};

export default ProcessOrderCard;
