import SearchContainer from 'modules/bidding/components/search-container';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePageContext } from 'common/components/base/pages/context';
import { BiSearch } from 'react-icons/bi';
import { Button, CircularProgress, IconButton, TextField } from '@material-ui/core';
import ProcessSearchItem from 'modules/bidding/components/search-item';
import { FaFilter } from 'react-icons/fa';
import SearchFiltersAside from 'modules/bidding/components/search-filters-aside';
import { AiFillFire, AiFillHeart, AiOutlineDollar } from 'react-icons/ai';
import useBiddingFilters from 'modules/bidding/hooks/useBiddingFilters';
import { useTranslation } from 'react-i18next';
import {
    getBackofficeRedirectUrl,
    getMd5Hash,
    featureFlagEnabled,
    addNotificationError,
    addNotificationWarning,
    apiRedirectTo,
} from 'common/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { auctionNoticeRequests, ShortFilterEnum } from 'clients/manager/auction-notice.requests';
import { AuctionNotice, TypeCancel } from 'clients/manager/interfaces/auction-notice.interface';

import { getAuthUserToken } from 'modules/auth/session.utils';
import { processUtils } from 'modules/process/process-utils';
import { LegalSupport } from 'modules/process/components/process-data/enum/legal-support.enum';
import {
    BlankArea,
    FastFiltersContainer,
    FiltersBackgroundBlock,
    SearchHeader,
    SearchPageWrapper,
    SuggestedButton,
} from './styled';

export const PROCESS_ENUMS = {
    E: 'trading',
    C: 'accreditation',
    R: 'concurrence',
    L: 'auction',
    D: 'dispense',
    I: 'unenforceability',
    T: 'competitiveDialogue',
    U: 'contest',
    P: 'inPersonConcurrence',
    A: 'inPersonTrading',
    M: 'expressionOfInterest',
    Q: 'preQualification',
    N: 'inPersonAuction',
    B: 'biddingInapplicability',
};

export const PROCESS_STATUS_ENUMS = {
    8: 'published',
    9: 'dispute',
    10: 'decision',
    11: 'contract',
};

export const PROCESS_STATUS_CANCELED_ENUMS = {
    fail: 'fail',
    excluded: 'excluded',
    canceled: 'canceled',
    revoked: 'revoked',
    annulled: 'annulled',
    suspended: 'suspended',
};

const BiddingSearch = () => {
    const authUserToken = getAuthUserToken();

    const { authUser, authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const {
        onlySuggested,
        onlyFavorited,
        onlyProposal,
        setOnlyFavorited,
        setOnlySuggested,
        setOnlyProposal,
        searchValue,
        setSearchValue,
        region,
        state,
        supplyLine,
        processStage,
        processType,
        startDate,
        endDate,
        showFilters,
        setShowFilters,
        startDatePublication,
        endDatePublication,
    } = useBiddingFilters();

    const {
        t,
        i18n: { language },
    } = useTranslation();
    const { setTitle } = usePageContext();
    const [loading, setLoading] = useState(false);
    const [nextPageLoading, setNextPageLoading] = useState(false);
    const [endReached, setEndreached] = useState(false);
    const [processData, setProcessData] = useState<AuctionNotice[]>([]);
    const [processCount, setProcessCount] = useState({ favorited: 0, suggested: 0, proposals: 0 });
    const [errorOnGetProcess, setErrorOnGetProcess] = useState(false);

    const searchRef = useRef<HTMLDivElement>(null);

    const onlySuggestedHandle = () => setOnlySuggested((prev) => !prev);
    const onlyFavoritedHandle = () => setOnlyFavorited((prev) => !prev);
    const onlyProposalHandle = () => setOnlyProposal((prev) => !prev);

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const getProposalUrl = (id: number) => {
        const hash = getMd5Hash(String(id));

        if (authUserToken?.userId)
            return apiRedirectTo({
                backofficeRedirectUrl,
                redirectUrl: `proposta/?idE=${hash}`,
                isPublic: false,
                isDisableRedirect: true,
            }) as string;

        return `${backofficeRedirectUrl}/proposta/?idE=${hash}`;
    };

    const getProcessUrl = (id: number) => {
        if (featureFlagEnabled('novaTelaDetalhesPesquisaProcesso')) return `pesquisa/${id}`;

        const hash = getMd5Hash(String(id));

        if (authUserToken?.userId)
            return apiRedirectTo({
                backofficeRedirectUrl,
                redirectUrl: `pesquisa/?idE=${hash}`,
                isPublic: false,
                isDisableRedirect: true,
            });

        return `${backofficeRedirectUrl}/pesquisa/?idE=${hash}`;
    };

    const biddingStageId: number | undefined = useMemo(() => {
        const stageIdx = Object.values(PROCESS_STATUS_ENUMS).indexOf(processStage);
        const value = Object.keys(PROCESS_STATUS_ENUMS)[stageIdx];
        return value ? Number(value) : undefined;
    }, [processStage]);

    const auctionType: string | undefined = useMemo(() => {
        const typeIdx = Object.values(PROCESS_ENUMS).indexOf(processType);
        return Object.keys(PROCESS_ENUMS)[typeIdx];
    }, [processType]);
    const legalSupportId: number | undefined = useMemo(() => {
        return processType === 'marketplace' ? LegalSupport._14133_2021_Art79_III : undefined;
    }, [processType]);

    const getMostImportantShortFilter = () => {
        if (onlySuggested) return ShortFilterEnum.suggestion;
        if (onlyProposal) return ShortFilterEnum.proposal;
        if (onlyFavorited) return ShortFilterEnum.favorite;
    };

    const isMarketplace = useMemo(() => (processType === 'marketplace' ? 1 : 0), [processType]);

    const getProcessStatus = (
        isCanceled: number,
        typeCancel: TypeCancel,
        status: number,
        isDispenseWithoutDispute?: boolean
    ) => {
        const processStatus = PROCESS_STATUS_ENUMS[status] ?? '';
        const processCancelStatus = PROCESS_STATUS_CANCELED_ENUMS[typeCancel] ?? 'canceled';

        if (isCanceled) {
            return processCancelStatus;
        }

        if (isDispenseWithoutDispute && processStatus === 'dispute') {
            return 'session';
        }

        return processStatus;
    };

    const searchProcess = useCallback(
        async (search?: string, nextPage?: boolean) => {
            if (loading || nextPageLoading || (nextPage && endReached)) return;
            if (nextPage) setNextPageLoading(true);
            else setLoading(true);

            try {
                const shortFilter = getMostImportantShortFilter();

                const { data, meta } = await auctionNoticeRequests.doSearchAuctionNotice({
                    filter: {
                        search: typeof search === 'string' ? search : searchValue,
                        state: state?.uf,
                        auctionType,
                        biddingStageId,
                        legalSupportId,
                        ibgeCodes: region?.codes,
                        supliesProviders: supplyLine.map(({ id }) => Number(id)).filter((i) => !!i),
                        shortFilter,
                        startDate: startDate ? startDate.getTime() : 0,
                        endDate: endDate?.getTime(),
                        startDatePublication: startDatePublication
                            ? startDatePublication.getTime()
                            : 0,
                        endDatePublication: endDatePublication?.getTime(),
                        isMarketplace,
                    },
                    offset: nextPage ? processData.length : 0,
                });

                setProcessData((prev) => (nextPage ? [...prev, ...data] : data));
                setEndreached([...processData, ...data].length >= (meta?.count || 0));
            } catch (e) {
                setErrorOnGetProcess(true);
                addNotificationError({
                    title: t('bidding.notification-error-occurred'),
                    message: t('bidding.page.search.notification-error-get-processes'),
                });
            } finally {
                setNextPageLoading(false);
                setLoading(false);
            }
        },
        [
            setProcessData,
            auctionType,
            legalSupportId,
            biddingStageId,
            onlySuggested,
            onlyFavorited,
            onlyProposal,
            nextPageLoading,
            endReached,
            processData,
            searchValue,
            supplyLine,
            startDate,
            loading,
            endDate,
            region,
            state,
            startDatePublication,
            endDatePublication,
            isMarketplace,
        ]
    );

    useEffect(() => {
        setTitle(t('bidding.page.search.title'));
    }, [language]);

    useEffect(() => {
        const pageEl = document.querySelector('#page-child') as HTMLElement;
        let localLoading = false;

        const onScroll = ({ target }: { target: any }) => {
            if (loading || nextPageLoading || localLoading) return;

            const element = target as HTMLDivElement;
            const triggerScrollCoor = element.scrollHeight - element.clientHeight - 100;

            if (element.scrollTop >= triggerScrollCoor) {
                localLoading = true;
                searchProcess(searchValue, true).finally(() => {
                    localLoading = false;
                });
            }
        };

        pageEl.addEventListener('scroll', onScroll);
        return () => pageEl.removeEventListener('scroll', onScroll);
    }, [loading, searchValue, searchProcess, nextPageLoading]);

    useEffect(() => {
        if (authUser)
            auctionNoticeRequests
                .getSearchResume()
                .then(
                    ({
                        suggestionCount: suggested = 0,
                        favoriteCount: favorited = 0,
                        proposalCount: proposals = 0,
                    }) => {
                        setProcessCount({
                            favorited,
                            suggested,
                            proposals,
                        });
                    }
                )
                .catch(() => {
                    addNotificationWarning({
                        title: '',
                        message: t('bidding.page.search.fast-filters.notification-error-get-count'),
                    });
                });
    }, [authUser]);

    useEffect(() => {
        const timer = setTimeout(() => searchProcess(), 100);
        return () => clearTimeout(timer);
    }, [
        biddingStageId,
        auctionType,
        legalSupportId,
        onlySuggested,
        onlyFavorited,
        onlyProposal,
        supplyLine,
        startDate,
        endDate,
        state,
        region,
        startDatePublication,
        endDatePublication,
        isMarketplace,
    ]);

    return (
        <SearchPageWrapper active={showFilters}>
            <SearchHeader>
                <SearchContainer>
                    <div>
                        <IconButton onClick={() => setShowFilters((prev) => !prev)}>
                            <FaFilter color='var(--primary-color)' />
                        </IconButton>
                    </div>

                    <div style={{ flex: 3, display: 'flex' }}>
                        <form
                            style={{ flex: 1 }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                const { value }: any = searchRef.current?.firstChild;
                                setSearchValue(value);
                                searchProcess(value || '');
                            }}
                        >
                            <TextField
                                key={searchValue}
                                fullWidth
                                id='outlined-basic'
                                defaultValue={searchValue}
                                label={t('shared.pages.menu-header.option.search-biddings')}
                                variant='outlined'
                                placeholder={t('bidding.page.search.placeholder')}
                                InputProps={{
                                    ref: searchRef,
                                    style: { paddingRight: 0 },
                                    endAdornment: (
                                        <Button
                                            onClick={() => {
                                                const { value }: any =
                                                    searchRef.current?.firstChild;
                                                setSearchValue(value);
                                                searchProcess(value || '');
                                            }}
                                            style={{ height: '56px' }}
                                        >
                                            <BiSearch size={20} />
                                        </Button>
                                    ),
                                }}
                            />
                        </form>
                    </div>
                </SearchContainer>
                <FastFiltersContainer active={!authUser ? false : !showFilters}>
                    <p>{t('bidding.page.search.fast-filters')}:</p>
                    <div>
                        {authUserTokenData?.typeAccess === 'provider' && (
                            <>
                                <SuggestedButton
                                    onClick={onlySuggestedHandle}
                                    color='darkorange'
                                    active={onlySuggested}
                                    quantity={processCount.suggested}
                                >
                                    <AiFillFire size={14} />
                                    {t('bidding.filter.only-suggested')}
                                </SuggestedButton>
                                <SuggestedButton
                                    onClick={onlyProposalHandle}
                                    active={onlyProposal}
                                    quantity={processCount.proposals}
                                >
                                    <AiOutlineDollar size={14} />
                                    {t('bidding.filter.only-proposal')}
                                </SuggestedButton>
                            </>
                        )}
                        <SuggestedButton
                            onClick={onlyFavoritedHandle}
                            active={onlyFavorited}
                            quantity={processCount.favorited}
                        >
                            <AiFillHeart size={14} />
                            {t('bidding.filter.only-favorited')}
                        </SuggestedButton>
                    </div>
                </FastFiltersContainer>

                <FiltersBackgroundBlock active={showFilters} />
            </SearchHeader>

            <section style={{ flex: 1 }}>
                <SearchFiltersAside show={showFilters} />
                <section>
                    {loading && (
                        <BlankArea>
                            <CircularProgress color='secondary' size={64} />
                        </BlankArea>
                    )}
                    {!loading && errorOnGetProcess && (
                        <BlankArea border>
                            <h1>{t('bidding.page.search.notification-error-get-processes')}</h1>
                            <h2>{t('bidding.error.try-again-later')}</h2>
                        </BlankArea>
                    )}
                    {!loading && !errorOnGetProcess && processData.length === 0 && (
                        <BlankArea border>
                            <h1>{t('bidding.page.search.empty-process')}</h1>
                            <h2>{t('bidding.page.search.empty-process-sub')}</h2>
                        </BlankArea>
                    )}
                    {!loading && !errorOnGetProcess && processData.length > 0 && (
                        <>
                            {processData.map((process) => (
                                <ProcessSearchItem
                                    key={process.id}
                                    process={process}
                                    title={`${process.organizationName} - ${process.organizationUnitName}`}
                                    status={getProcessStatus(
                                        process.auctionCanceled,
                                        process.typeCancel as TypeCancel,
                                        process.biddingStageId,
                                        processUtils.isDispenseWithoutDispute(process)
                                    )}
                                    type={PROCESS_ENUMS[process.auctionType]}
                                    icon={process.urlOriginalIcon}
                                    canPropose={!!Number(process.hasProposal)}
                                    favorited={!!Number(process.isFavorite)}
                                    suggested={!!Number(process.isSuggestion)}
                                    proposalUrl={getProposalUrl(process.id)}
                                    processUrl={getProcessUrl(process.id)}
                                    id={process.id}
                                    description={process.simpleDescription}
                                    auction={process.auctionNumber}
                                    accreditation={process.accreditationNumber}
                                    startDate={process.auctionStartDate}
                                    endDate={process.auctionEndDate}
                                    startDisputeDate={process.startDateTimeDispute}
                                />
                            ))}
                            <BlankArea minHeight>
                                {endReached ? (
                                    <h3>{t('bidding.page.search.end-reached')}</h3>
                                ) : (
                                    <CircularProgress color='secondary' size={24} />
                                )}
                            </BlankArea>
                        </>
                    )}
                </section>
            </section>
        </SearchPageWrapper>
    );
};

export default memo(BiddingSearch);
