import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import i18n from 'common/i18n';
import { getColumns } from './columns';
import { CustomLabel } from './styles';

interface LotItemsTableHeadProps {
    showSelectAllRows: boolean;
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

const LotItemsTableHead: FC<LotItemsTableHeadProps> = ({
    onSelectAllClick,
    numSelected,
    rowCount,
    showSelectAllRows,
}) => {
    const columns = getColumns();
    const { processForm } = useProcessFormContext();
    const { t } = useTranslation();
    const updatedColumns = columns.map((c) => {
        if (
            processUtils.isMarketplace(processForm?.values) &&
            processForm?.values.isMarketplace &&
            c.id === 'referenceValue'
        ) {
            return {
                ...c,
                label: i18n.t('term.unit-value-3'),
            };
        }
        return c;
    });

    return (
        <TableHead>
            <TableRow>
                {showSelectAllRows && (
                    <TableCell padding='checkbox'>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': t('process.components.select-all-itens-lot'),
                            }}
                            disabled={processUtils.notKit(processForm?.values)}
                        />
                    </TableCell>
                )}
                {updatedColumns.map((updatedColumn) => (
                    <TableCell
                        key={updatedColumn.id}
                        align={updatedColumn.align}
                        style={{ minWidth: updatedColumn.minWidth }}
                    >
                        <CustomLabel>
                            {updatedColumn.label ? updatedColumn.label : null}
                        </CustomLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default LotItemsTableHead;
