import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { userRequests } from 'clients/manager/user.requests';
import { providerRequests } from 'clients/manager/provider.requests';
import CodeValidatorView from './code-validator-view';

export interface CodeValidatorFormValues {
    verificationCode: string;
}

export interface CodeValidatorProps {
    open: boolean;
    email?: string;
    onSubmit?: () => void;
    submitChange?: (values: CodeValidatorFormValues) => void;
    onClose?: () => void;
    setIsOpenCodeValidator: Dispatch<SetStateAction<boolean>>;
    typeChangeEmail: 'Notifications' | 'Billing';
    isEmailsEquals: boolean;
}

const CodeValidatorEditProvider: React.FC<CodeValidatorProps> = ({
    open,
    email,
    onSubmit,
    submitChange,
    onClose,
    setIsOpenCodeValidator,
    typeChangeEmail,
    isEmailsEquals,
}) => {
    const { t } = useTranslation();
    const [error, setError] = React.useState<string | undefined>(undefined);

    const form = useFormik({
        initialValues: {
            verificationCode: '',
        },
        validationSchema: yup.object().shape({
            verificationCode: yup.string().required(t('term.required-field')).min(7).max(7),
        }),
        validateOnBlur: false,
        onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
                setSubmitting(true);

                if (!email) {
                    setErrors({ verificationCode: t('code-validator.invalid-code') });
                    setSubmitting(false);
                    return;
                }

                if (email) {
                    const emailVerification = isEmailsEquals ? 'Equals' : typeChangeEmail;

                    await providerRequests.validateVerificationCode({
                        verificationCode: values.verificationCode,
                        email,
                        emailVerification,
                    });
                    setIsOpenCodeValidator(false);
                    submitChange?.(values);
                }

                resetForm();
                onSubmit?.();
                return;
            } catch (err) {
                setErrors({ verificationCode: t('code-validator.invalid-code') });
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleSendEmailVerificationCode = async () => {
        if (!email) return;

        await userRequests.sendEmailVerificationCode({ email }).catch((e) => {
            console.error(e);
            const message = t('code-validator.error-send-email');
            setError(message);
        });
    };

    useEffect(() => {
        if (!form.values.verificationCode && open) {
            handleSendEmailVerificationCode();
        }

        return () => form.resetForm();
    }, [email, open]);

    return (
        <CodeValidatorView
            form={form}
            open={open}
            onClose={onClose}
            onResendCode={handleSendEmailVerificationCode}
            email={email}
            errorSendCode={error}
            setErrorSendCode={setError}
        />
    );
};

export default CodeValidatorEditProvider;
