import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

export const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column' as any,
        justifyContent: 'space-between',
        height: formatPxToRem(350),
        border: `solid ${formatPxToRem(3)} #1d1ce5`,
        borderRadius: '0.3rem',
    },
    titleContainer: {
        height: '10%',
        marginBottom: formatPxToRem(15),
    },
    title: {
        paddingLeft: formatPxToRem(10),
        marginRight: '1rem',
    },
    tableContainer: {
        height: '90%',
        overflowY: 'auto',
    },
}));
