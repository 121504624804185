import React from 'react';
import { TablePagination } from '@material-ui/core';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { EmptyList } from 'modules/marketplace/components';
import { useStyles } from './order-page-style';
import ProviderOrderCard from './components/provider-order-card';

const ROWS_PER_PAGE_OPTIONS = [5, 10];

interface OrderPageViewProps {
    orders: OrderResponse[];
    loading?: boolean;
    paginationParams: Omit<
        UsePaginatedQueryResponse<OrderResponse>,
        'data' | 'loading' | 'setData'
    >;
}

const OrderPageView: React.FC<OrderPageViewProps> = ({
    orders,
    paginationParams,
    loading = false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const { totalCount, page, limit, setLimit, setPage } = paginationParams;

    return (
        <PageWrapper>
            <section className={styles.container}>
                {isEmpty(orders) && !loading && (
                    <EmptyList
                        title={t('marketplace.provider-orders.empty-title')}
                        subtitle={t('marketplace.provider-orders.empty-subtitle')}
                    />
                )}
                {orders.map((order) => (
                    <ProviderOrderCard key={order.id} order={order} />
                ))}
                {loading && (
                    <>
                        <Skeleton className={styles.skeleton} />
                        <Skeleton className={styles.skeleton} />
                    </>
                )}
            </section>
            <TablePagination
                page={page}
                count={totalCount}
                onPageChange={(_e, page) => setPage(page)}
                rowsPerPage={limit || ROWS_PER_PAGE_OPTIONS[0]}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onRowsPerPageChange={(event) =>
                    setLimit(Number(event.target.value) ?? ROWS_PER_PAGE_OPTIONS[0])
                }
                component='div'
                labelRowsPerPage={t('text.lines-per-page')}
            />
        </PageWrapper>
    );
};

export default OrderPageView;
