import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    root: {
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            backgroundColor: '#1D1D20',
            border: '1px solid #C4C4CE',
            borderLeft: 'none',
            borderRight: 'none',
            boxShadow: 'inset 4px 0 0 #3561DB',
            flexWrap: 'nowrap',
            width: '100%',
            height: '3.5rem',
            overflow: 'hidden',
            gap: '1rem',
        },
    },
    topbarInfoContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        width: '100%',
        alignItems: 'center',
        background: 'none',
        border: 'none',
        color: '#F5F5F8',
    },
    closeTopbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '3.5rem',
        width: '3.5rem',
        color: '#C4C4CE',
    },
    closeTopbarBtn: {
        borderRadius: '12px',
        '&:hover': {
            backgroundColor: '#1B1B1D',
        },
        '&:active': {
            backgroundColor: '#4E4E53',
        },
        '&:focus-visible': {
            background: '#1B1B1D',
            outline: '2px solid white',
        },
    },
    label: {
        padding: '0 1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        '& h6': {
            color: '#F5F5F8',
            fontWeight: 500,
        },
    },
    content: {
        color: '#C4C4CE',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '3rem',
    },
    action: {
        color: '#698BE8',
        textDecoration: 'underline',
        fontWeight: 500,
        background: 'none',
        whiteSpace: 'nowrap',
        border: 'none',
        cursor: 'pointer',
        textUnderlineOffset: '4px',
    },
}));
