import { TypeAccess } from 'common/interfaces/user.interface';
import { apiRedirectTo, getBackofficeRedirectUrl, getUrlOAuth2 } from 'common/utils';
import AccessLikeItemView from './access-like-view';

interface AccessLikeItemProps {
    providerId: string;
}

const AccessLikeItem: React.FC<AccessLikeItemProps> = ({ providerId }) => {
    const backofficeRedirectUrl = getBackofficeRedirectUrl(true);
    const handleAccessLike = async () => {
        const redirectUrl = getUrlOAuth2(TypeAccess.provider, providerId, '/painel-fornecedor/');
        apiRedirectTo({ backofficeRedirectUrl, redirectUrl, isPublic: true });
    };

    return <AccessLikeItemView onClick={handleAccessLike} />;
};

export default AccessLikeItem;
