import { userRequests } from 'clients/manager/user.requests';
import { setAuthToken, setAuthUserToken } from 'modules/auth/session.utils';
import { createContext, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserConnectedProviders from 'modules/auth/hooks/useUserConnectedProviders';
import {
    apiRedirectTo,
    featureFlagEnabled,
    getBackofficeRedirectUrl,
    getUrlOAuth2,
} from 'common/utils';
import { INewUser, ISignUpContextProviderValue } from '../props';

export const SignUpContext = createContext<ISignUpContextProviderValue>({ currentStep: 0 });
SignUpContext.displayName = 'SignUpContext';

export const SignUpContextProvider = memo(({ children }) => {
    const [newUser, setNewUser] = useState<INewUser>({});
    const [currentStep, setCurrentStep] = useState(0);
    const [userType, setUserType] = useState('Cidadão');
    const [token, setToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { createUser } = useUserConnectedProviders();

    const [userId, setUserId] = useState<number | undefined>();
    const backofficeRedirectUrl = getBackofficeRedirectUrl(true);

    const { t } = useTranslation();

    const isEnableCognitoAccess = featureFlagEnabled('enableCognitoAccess');

    const postUser = async () => {
        try {
            if (isEnableCognitoAccess) {
                const result = await createUser(newUser);
                setToken(result.token);
                return;
            }

            const result = await userRequests.createUser(newUser);
            setUserId(result.user.id);
            setToken(result.token);
        } catch (e: any) {
            setErrorMessage(t('sign-up.pages.context.error-try-again'));
            return e;
        }
    };

    const handleClickPrev = () => {
        setCurrentStep(currentStep - 1);
        setErrorMessage('');
    };

    useEffect(() => {
        // eslint-disable-next-line no-prototype-builtins
        if (newUser.hasOwnProperty('password')) {
            postUser();
        }
    }, [newUser]);

    useEffect(() => {
        if (token) {
            setAuthToken(token);
            setAuthUserToken(token);

            const redirectUrl = getUrlOAuth2(null, null, '/cadastro', null, `${userId || ''}`);
            apiRedirectTo({ backofficeRedirectUrl, redirectUrl, isPublic: true });
        }
    }, [token]);

    return (
        <SignUpContext.Provider
            value={{
                newUser,
                setNewUser,
                currentStep,
                setCurrentStep,
                userType,
                setUserType,
                postUser,
                token,
                errorMessage,
                handleClickPrev,
            }}
        >
            {children}
        </SignUpContext.Provider>
    );
});
