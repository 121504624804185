import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    MarketplaceCartItem,
    OrderItem,
    OrderResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'common/components/base/breadcrumb';
import MarketplaceItemCard from 'modules/marketplace/components/marketplace-item-card';
import MarketplaceSummaryCard from 'modules/marketplace/components/summary-card/marketplace-summary-card';
import { OrderItemStatusEnum, OrderStatusEnum } from 'common/enums';

interface JudgmentPageViewProps {
    order?: OrderResponse;
    itemsToSummary?: OrderItem[];
    loading?: boolean;
    onConfirm?: () => any;
    isConfirmButtonDisabled?: boolean;
    onDeclassifyProvider?: (value: { lotId: number; providerId: number }) => any;
    isDisqualifyingProvider?: boolean;
    isJudged?: boolean;
    isFinished?: boolean;
}

const JudgmentPageView: React.FC<JudgmentPageViewProps> = ({
    order,
    itemsToSummary,
    loading,
    onConfirm,
    isConfirmButtonDisabled = false,
    onDeclassifyProvider,
    isDisqualifyingProvider = false,
    isJudged = false,
    isFinished = false,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='mercado/pedidos/julgamento'
                paths={{
                    mercado: {
                        value: t('term.marketplace'),
                        onClick: () => history.replace('/mercado'),
                    },
                    pedidos: {
                        value: t('term.orders'),
                        onClick: () => history.replace('/mercado/pedidos'),
                    },
                    julgamento: { value: t('term.judgment'), disabled: true },
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    {loading && <MarketplaceItemCard loading />}
                    {order?.items.map((orderItem) => (
                        <MarketplaceItemCard
                            key={orderItem.id}
                            quantity={orderItem.itemAmount}
                            type='judgment'
                            item={orderItem.item as any}
                            orderItemStatus={orderItem.status}
                            onDeclassify={onDeclassifyProvider}
                            isDisqualifying={isDisqualifyingProvider}
                            isJudged={isJudged}
                            isFinished={isFinished}
                        />
                    ))}
                </Grid>
                <Grid item xs={3}>
                    <MarketplaceSummaryCard
                        title={t('term.marketplace-order-summary')}
                        items={itemsToSummary as any}
                        initialDeadlineToSendProposal={order?.deadlineToSendProposal}
                        hideBackToCartLink
                        onConfirm={onConfirm}
                        confirmLabel={t('term.marketplace-button-finish-shopping')}
                        isConfirming={isConfirmButtonDisabled}
                        isJudged={isJudged}
                        disabled={isFinished}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default JudgmentPageView;
