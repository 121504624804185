import { ReactNotifications } from 'react-notifications-component';
import { CssBaseline } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify, Hub } from 'aws-amplify';
import { amplifyConfig } from 'common/amplify';
import { connectProviders } from 'common/amplify/connectProviders';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import 'moment-timezone';
import { Suspense } from 'react';
import GlobalStyles from 'common/theme/styled/globalStyles';
import { MUIThemeProvider } from 'common/theme';
import { getLanguage } from 'common/utils';
import { UserLanguages } from 'common/interfaces/user.interface';
import Theme, { ThemeTypes } from './common/theme/styled';
import 'moment/locale/pt-br';
import 'react-notifications-component/dist/theme.css';
import Routes from './routes';
import 'common/i18n';

moment.tz.setDefault('America/Sao_Paulo');
momentDurationFormatSetup(moment);
Amplify.configure(amplifyConfig);
Hub.listen('auth', ({ payload: { event, data } }) => connectProviders(event, data));

const App = () => (
    <Suspense fallback={null}>
        <MUIThemeProvider>
            <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale={getLanguage() === UserLanguages['pt-BR'] ? 'pt-br' : undefined}
            >
                <ReactNotifications />
                <Authenticator.Provider>
                    <Theme theme={ThemeTypes.light}>
                        <CssBaseline />
                        <Routes />
                        <GlobalStyles />
                    </Theme>
                </Authenticator.Provider>
            </MuiPickersUtilsProvider>
        </MUIThemeProvider>
    </Suspense>
);

export default App;
