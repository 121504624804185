import { Store } from 'react-notifications-component';
import { getTranslation, getLanguage } from 'common/utils';
import {
    BaseNotificationProps,
    NotificationProps,
    NotificationType,
    NotificationWithMessageProps,
} from 'common/interfaces/notification.interface';
import { AxiosError } from 'axios';
import { apiErrorsMessages } from 'common/constants/api-errors-messages.constant';
import { UserLanguages } from 'common/interfaces/user.interface';
import { t } from 'i18next';

const getCustomTranslation = (message: string) => getTranslation(message, 'notification');

const baseNotification = ({ title, message, type, duration }: BaseNotificationProps) =>
    Store.addNotification({
        title,
        message,
        type,
        insert: 'bottom',
        container: 'bottom-right',
        dismiss: {
            duration: duration ?? 4000,
            pauseOnHover: true,
            click: true,
            showIcon: true,
        },
    });

const addNotificationInfo = ({ title, message, duration }: NotificationWithMessageProps) =>
    baseNotification({
        title: title || getCustomTranslation('info.title'),
        message: message,
        duration: duration,
        type: NotificationType.info,
    });

const addNotificationSuccess = ({ title, message, duration }: NotificationProps = {}) =>
    baseNotification({
        title: title || getCustomTranslation('success.title'),
        message: message || getCustomTranslation('success.description'),
        duration: duration,
        type: NotificationType.success,
    });

const addNotificationWarning = ({ title, message, duration }: NotificationWithMessageProps) =>
    baseNotification({
        title: title || getCustomTranslation('warning.title'),
        message: message,
        duration: duration,
        type: NotificationType.warning,
    });

const addNotificationError = ({ title, message, duration }: NotificationProps = {}) =>
    baseNotification({
        title: title || getCustomTranslation('error.title'),
        message: message || getCustomTranslation('error.description'),
        duration: duration,
        type: NotificationType.danger,
    });

const addNotificationApiError = (
    error:
        | string
        | AxiosError<{
              message: { pt: string; en: string };
              token: string;
              warnings?: { [key: string]: boolean };
          }>
        | AxiosError<{ message: string; token: string; warnings?: { [key: string]: boolean } }>,
    config?: Pick<Partial<BaseNotificationProps>, 'type' | 'title'>
) => {
    let message;
    let duration;

    if (typeof error === 'string') {
        message = error;
    } else {
        const { message: errorMessage, token: errorToken, warnings } = error?.response?.data || {};
        const currentLanguage = getLanguage();
        const isPt = currentLanguage === UserLanguages['pt-BR'];

        if (typeof errorMessage !== 'string') {
            message = isPt ? errorMessage?.pt : errorMessage?.en;
        }

        if (!message && errorToken && apiErrorsMessages[errorToken]) {
            message = getCustomTranslation(apiErrorsMessages[errorToken] ?? 'error.description');
        }

        if (!message && error?.response?.status === 403) {
            message = getCustomTranslation('error.unauthorized-message');
        }

        if (warnings && Object.keys(warnings)) {
            message = message ? `${message} ` : '';
            duration = 4000;
            Object.keys(warnings).forEach((key) => {
                if (warnings[key]) {
                    message += `${t(`warning.${key}`)} `;
                    duration += 2000;
                }
            });
        }
    }

    baseNotification({
        title: config?.title ?? getCustomTranslation('error.title'),
        message: message || getCustomTranslation('error.description'),
        type: config?.type ?? NotificationType.danger,
        duration,
    });
};

const addNotification = baseNotification;

export {
    addNotification,
    addNotificationInfo,
    addNotificationSuccess,
    addNotificationWarning,
    addNotificationError,
    addNotificationApiError,
};
