import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { HiringPlanItemUnitRequest } from 'clients/manager/interfaces/hiring-plans.interfaces';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import DialogDeleteItemPcaView from './dialog-delete-item-pca-view';

interface DialogDeleteItemPcaProps {}

const DialogDeleteItemPca: React.FC<DialogDeleteItemPcaProps> = () => {
    const { t } = useTranslation();
    const {
        setItemRemoveId,
        onCloseDialogDeleteItemPca,
        setHiringPlanItens,
        itemRemoveId,
        hiringPlanItens,
    } = useHiringPlansContext();
    const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false);

    const form = useFormik<Pick<HiringPlanItemUnitRequest, 'id' | 'justification'>>({
        validateOnBlur: true,
        validateOnMount: false,
        validateOnChange: true,
        initialValues: {
            justification: '',
        },
        validationSchema: yup.object({
            justification: yup.string().min(12).max(255).required(t('term.required-field')),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoadingSubmitForm(true);
            try {
                await hiringPlansRequests.deleteHiringPlanUnitItem({
                    id: itemRemoveId,
                    ...values,
                });
                setHiringPlanItens((prevState) => [
                    ...prevState.filter((item) => item.id !== itemRemoveId),
                ]);
                resetForm();
                onCloseDialogDeleteItemPca();
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('hiring-plans.info.delete-pca-unit-item-success'),
                });
            } catch (err) {
                addNotificationError({
                    title: t('term.error'),
                    message: t('hiring-plans.info.delete-pca-unit-item-error'),
                });
            } finally {
                setIsLoadingSubmitForm(false);
            }
        },
    });

    const searchDescriptionItemBeingDeleted = (idItem?: number) =>
        hiringPlanItens.find((item) => item.id === idItem);

    useEffect(() => () => setItemRemoveId(undefined), []);

    return (
        <DialogDeleteItemPcaView
            form={form}
            isLoadingSubmitForm={isLoadingSubmitForm}
            searchDescriptionItemBeingDeleted={searchDescriptionItemBeingDeleted}
        />
    );
};

export default DialogDeleteItemPca;
