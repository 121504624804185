import { apiRedirectTo, getBackofficeRedirectUrl, getMd5Hash } from 'common/utils';
import ProcessProposalsView from './process-proposals-view';

const ProcessProposals = ({ invites }) => {
    const backofficeRedirectUrl = getBackofficeRedirectUrl();
    const loginBaseUrl = getBackofficeRedirectUrl(true);

    const handleClick = (processId: number) => {
        const hash = getMd5Hash(String(processId));
        apiRedirectTo({
            backofficeRedirectUrl,
            redirectUrl: `proposta/?idE=${hash}`,
            loginBaseUrl,
        });
    };

    return <ProcessProposalsView data={invites} onClick={handleClick} />;
};

export default ProcessProposals;
