import { Collapse, IconButton, Typography } from '@material-ui/core';
import { MdClose, MdInfo } from 'react-icons/md';
import useStyles from './notification-topbar-style';

const NotificationTopbarView = ({ handleClickTopbar, topbarData, closeTopbar }) => {
    const classes = useStyles();

    return (
        <Collapse className={classes.root} in={topbarData.open}>
            <div className={classes.topbarInfoContainer}>
                <div className={classes.label}>
                    <MdInfo size='1.25rem' fill='#3561DB' />
                    <Typography variant='subtitle1'>{topbarData.label}</Typography>
                </div>
                <Typography
                    className={classes.content}
                    color='inherit'
                    align='left'
                    title={topbarData.resume}
                >
                    {topbarData.message}
                </Typography>
            </div>
            <button className={classes.action} onClick={handleClickTopbar} type='button'>
                Ver aviso
            </button>
            <div className={classes.closeTopbar}>
                <IconButton
                    onClick={closeTopbar}
                    className={classes.closeTopbarBtn}
                    color='inherit'
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                >
                    <MdClose size='1.25rem' />
                </IconButton>
            </div>
        </Collapse>
    );
};

export default NotificationTopbarView;
