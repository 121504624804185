import { FC, memo } from 'react';
import { Box, Button, IconButton, Typography, Box as MuiBox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import _ from 'lodash';
import UploadFile from 'common/components/upload-file';
import { Modal, ModalPosition } from 'common/components';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import LoadingButton from 'common/components/loading-button';
import { Alert } from '@material-ui/lab';
import { ModalImportPcaItemsProps } from './props';

const ModalImportPcaItems: FC<ModalImportPcaItemsProps> = ({
    dropzoneProps,
    selectedFile,
    setSelectedFile,
    onClose,
    open,
    handleClickImport,
    loading,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                position={ModalPosition.center}
                open={open}
                onClose={() => onClose()}
                header={<span>{t('hiring-plans.labels.title-modal-import-item-pca')}</span>}
            >
                <MuiBox p={2}>
                    <Alert severity='warning'>
                        <Typography variant='body2'>
                            {t('hiring-plans.labels.alert-modal-import-item-pca')}
                        </Typography>
                    </Alert>
                    <br />

                    <span>
                        <a
                            title={t('process.components.model-plane')}
                            href='/assets/samples/modelo_de_importacao_pca.xlsx'
                            download
                            style={{ textDecoration: 'none' }}
                        >
                            {t('term.model')}
                        </a>{' '}
                        {t('process.components.msg-info-download-model')}
                    </span>
                    <Box display='flex' justifyContent='center' mt={2} mb={2}>
                        <UploadFile {...dropzoneProps} />
                    </Box>
                    <Box display='flex' justifyContent='flex-start'>
                        {selectedFile && (
                            <Box display='flex' alignItems='center' justifyContent='space-between'>
                                <Typography variant='body2'>{selectedFile.name}</Typography>
                                <IconButton
                                    title={t('term.delete-file')}
                                    style={{ marginLeft: '8px' }}
                                    size='small'
                                    aria-label='delete'
                                    onClick={() => {
                                        setSelectedFile(undefined);
                                    }}
                                >
                                    <MdDelete />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                    <MuiBox display='flex' justifyContent='flex-end' mt={selectedFile ? 2 : 0}>
                        <Button
                            variant='outlined'
                            color='primary'
                            title={t('term.cancel')}
                            onClick={onClose}
                            size='small'
                            style={{ marginRight: '16px' }}
                        >
                            {t('term.cancel')}
                        </Button>
                        <ConfirmDialog
                            title={t('term.import-alert')}
                            message={t('term.import-confirm-dialog-pca')}
                            onConfirm={handleClickImport}
                        >
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                title={t('term.import-data')}
                                size='small'
                                disabled={!selectedFile}
                                {...(loading
                                    ? {
                                          loading: {
                                              text: t('term.importing'),
                                          },
                                      }
                                    : {})}
                            >
                                {t('term.import')}
                            </LoadingButton>
                        </ConfirmDialog>
                    </MuiBox>
                </MuiBox>
            </Modal>
        </>
    );
};

export default memo(ModalImportPcaItems);
