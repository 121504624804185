import React from 'react';
import {
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import { MarketplaceProviders } from 'modules/marketplace/interfaces/marketplace.interface';
import { formatCurrency } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { TableHead } from '@aws-amplify/ui-react';
import { isEmpty } from 'lodash';
import DiscountLabel from 'modules/marketplace/components/discount-label';
import { ImBlocked } from 'react-icons/im';
import RatingIndicator from 'modules/marketplace/components/rating-indicator';
import { useStyles, useTableRowStyles } from './providers-table-style';
import ProviderStatusBadge from './components/provider-status-badge';

interface ProvidersTableViewProps {
    estimatedValue: number;
    providers?: MarketplaceProviders[];
    loading?: boolean;
    onDeclassify?: (providerId: number) => void;
    isDisqualifying?: boolean;
    verifyProviderIsDisabled?: (provider: MarketplaceProviders) => boolean;
    verifyProviderActionsIsDisabled?: (provider: MarketplaceProviders) => boolean;
}

const ProvidersTableView: React.FC<ProvidersTableViewProps> = ({
    estimatedValue,
    providers = [],
    onDeclassify,
    loading = false,
    isDisqualifying = false,
    verifyProviderIsDisabled = () => false,
    verifyProviderActionsIsDisabled = () => false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <section>
            <Table className={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align='left' className={styles.headTableCell}>
                            {t('term.position')}
                        </TableCell>
                        <TableCell align='left' colSpan={2} className={styles.headTableCell}>
                            {t('term.company')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('term.assessments')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('term.value')}
                        </TableCell>
                        <TableCell align='right' className={styles.headTableCell}>
                            {t('term.discount')}
                        </TableCell>
                        {!!onDeclassify && (
                            <TableCell align='right' className={styles.headTableCell}>
                                {t('term.actions')}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {providers?.map((provider) => {
                        const isRowDisabled = verifyProviderIsDisabled(provider);
                        const isDeclassifyDisabled = verifyProviderActionsIsDisabled(provider);
                        const tableRowStyles = useTableRowStyles({ disabled: isRowDisabled });

                        return (
                            <TableRow className={tableRowStyles.container} hover={!isRowDisabled}>
                                <TableCell>{provider.position}º</TableCell>
                                <TableCell colSpan={2}>
                                    <div className={styles.providerStatusTableCell}>
                                        <ProviderStatusBadge status={provider.status} />
                                        <Typography variant='body2'>
                                            {provider.companyName}
                                        </Typography>
                                    </div>
                                </TableCell>
                                <TableCell align='right'>
                                    <RatingIndicator actual={provider.rating ?? 0} max={5} />
                                </TableCell>
                                <TableCell align='right'>
                                    {formatCurrency(provider.value)}
                                </TableCell>
                                <TableCell align='right'>
                                    <DiscountLabel
                                        value={provider.value}
                                        initialValue={estimatedValue}
                                    />
                                </TableCell>
                                {!!onDeclassify && (
                                    <TableCell align='right'>
                                        <Button
                                            className={styles.buttonContainer}
                                            title={t('term.declassify')}
                                            onClick={
                                                !isDisqualifying
                                                    ? () => onDeclassify?.(provider.id)
                                                    : undefined
                                            }
                                            disabled={isRowDisabled || isDeclassifyDisabled}
                                        >
                                            <ImBlocked size={20} />
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={4} align='center'>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {isEmpty(providers) && (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Typography gutterBottom align='center'>
                                    {t('info.none-data')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </section>
    );
};

export default ProvidersTableView;
