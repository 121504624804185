function formatCurrency(
    value?: string | number,
    digits?: number,
    removeCurrencyIdentifier?: boolean
) {
    if (!value) value = 0;

    const formatted = parseFloat(String(value)).toLocaleString('pt-BR', {
        minimumFractionDigits: digits || 2,
        style: 'currency',
        currency: 'BRL',
    });

    if (!removeCurrencyIdentifier) {
        return formatted;
    }

    return String(formatted).replaceAll('R$', '').trim();
}

export { formatCurrency };
