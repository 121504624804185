import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { FinancialProviderData } from './interfaces/financial-provider-data.interface';
import {
    AccountType,
    MethodType,
    PixType,
    UserFinancialData,
} from './interfaces/user-financial-data.interface';

const path = 'user-financial-data';

export interface CreateFinancialData {
    methodType: MethodType;
    accountType?: AccountType | null;
    accountAgency?: string | null;
    bankCode?: string | null;
    accountNumber?: string | null;
    pixValue?: string | null;
    pixType?: PixType | null;
    docNumberReceiver?: string | null;
    providerId?: number | null;
}

export interface UpdateFinancialData extends CreateFinancialData {
    resourceId: number;
}

export interface ProviderFinancialDataProps {
    financialData?: FinancialProviderData;
}

export interface DeleteFinancialData {
    resourceId: number;
    providerId?: number;
}

const userFinancialDataRequests = {
    createFinancialData: async (data: CreateFinancialData) =>
        doRequest<PlainGenericResponse<UserFinancialData>>(
            managerApiInstance.post(`/${path}/createFinancialData`, data)
        ),
    updateFinancialData: async (data: UpdateFinancialData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/updateFinancialData`, data)
        ),
    deleteFinancialData: async (data: DeleteFinancialData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/deleteFinancialData`, data)
        ),
    deleteFinancialDataByAdmin: async (data: DeleteFinancialData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/${path}/deleteFinancialDataByAdmin`, data)
        ),
    listFinancialData: async (body: number | undefined) =>
        doRequest<GenericResponse<UserFinancialData>>(
            managerApiInstance.post(`/${path}/listFinancialData`, { providerId: body })
        ),
};

export { userFinancialDataRequests };
