import { doRequest, managerApiInstance } from 'clients/api';
import {
    GenericResponse,
    NoDataGenericResponse,
    PlainGenericResponse,
} from 'clients/interfaces/paginated.interface';
import {
    UserLanguages,
    ValidateVerificationCodeBody,
    ValidateVerificationCodeReponse,
} from 'common/interfaces/user.interface';
import { omit } from 'lodash';
import { ApiCnpjWsResponse } from 'modules/sign-up/pages/sign-up-stepper-provider/props';
import { OkResponse } from '../interfaces/ok.interface';
import {
    Provider,
    ProviderResponse,
    ProviderCheckExist,
    ProviderCheckExistResponse,
    ProviderProposalDocument,
    ProviderLegalRepresentative,
    ListProviderBodyData,
    GetProviderByIdData,
    ProviderBodyData,
    DoRequestProviderUpdate,
    ProviderContractDocument,
    ToggleOpportunitiesBody,
} from './interfaces/provider.interface';

interface DoAddUserAsLegalRepresentative {
    name: string;
    email: string;
    phone: string;
    job: string;
    cpf: string;
    providerId: number;
    identityDoc: string;
    typeOfRepresentative: string;
    language: UserLanguages;
}
interface DoAddUserAsTecnicalResponsible {
    name: string;
    email: string;
    phone: string;
    cpf: string;
}

type ValidateVerificationCodeProviderBody = ValidateVerificationCodeBody & {
    emailVerification: 'Equals' | 'Notifications' | 'Billing';
};

const providerRequests = {
    listProviders: async (body: ListProviderBodyData) =>
        doRequest<GenericResponse<Provider>>(
            managerApiInstance.post(`/providers/listProviders`, body)
        ),
    createProvider: async (data: Provider) =>
        doRequest<ProviderResponse>(
            managerApiInstance.post(`/providers/createProvider`, {
                ...data,
                ibgeCode: data.ibgeCode ? parseInt(data.ibgeCode, 10) : null,
            })
        ),
    getProvider: async (body: GetProviderByIdData = { id: 0 }) =>
        doRequest<Provider>(managerApiInstance.post(`/providers/getProvider`, body)),
    getCnpjWsApiProviderInfo: async (cnpj: string) =>
        doRequest<ApiCnpjWsResponse>(managerApiInstance.get(`/cnpjscrapper?cnpj=${cnpj}`)),
    doCheckProviderExist: async (cpf: ProviderCheckExist) =>
        doRequest<ProviderCheckExistResponse>(
            managerApiInstance.post(`/providers/doCheckProviderExists`, cpf)
        ),
    doRequestProviderUpdate: async (data: DoRequestProviderUpdate) => {
        data = omit(data, [
            'docNumberMei',
            'socialSecurityCode',
            'gender',
            'birthdate',
            'birthplace',
            'profession',
            'intructionLevel',
            'isNotificationsEnabled',
        ]);
        return doRequest<ProviderCheckExistResponse>(
            managerApiInstance.post(`/providers/doRequestProviderUpdate`, data)
        );
    },
    doProviderUpdateByAdmin: async (data: ProviderBodyData) =>
        doRequest<ProviderCheckExistResponse>(
            managerApiInstance.post(`/providers/doProviderUpdateByAdmin`, data)
        ),
    toggleOpportunities: async (data: ToggleOpportunitiesBody) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.patch(`/providers/toggleOpportunities`, data)
        ),
    getProviderById: async (id: number) =>
        doRequest<Provider>(
            managerApiInstance.post('/providers/getProviderById', {
                providerId: id,
            })
        ),
    listProviderProposalDocuments: async (id: number, providerId: number) =>
        doRequest<{ data: ProviderProposalDocument[] }>(
            managerApiInstance.post('/auction-notice-documents/listProviderProposalDocuments', {
                params: {
                    auctionId: id,
                    providerId,
                },
            })
        ),
    getProviderByDocNumber: async (docNumber: string) =>
        doRequest<PlainGenericResponse<Provider>>(
            managerApiInstance.post('/providers/getProviderByDocNumber', {
                docNumber,
            })
        ),
    getContractByAuctionAndProvider: async (auctionId: number, providerId: number) =>
        doRequest<{ data: ProviderContractDocument }>(
            managerApiInstance.post('/contract/getContractByAuctionAndProvider', {
                params: {
                    providerId,
                    auctionId,
                },
            })
        ),
    deleteLegalRepresentative: async (providerId: number, representativeUserId: number) =>
        doRequest<OkResponse>(
            managerApiInstance.post(`/providers/deleteLegalRepresentative`, {
                providerId,
                representativeUserId,
            })
        ),
    doAddUserAsLegalRepresentative: async (data: DoAddUserAsLegalRepresentative) =>
        doRequest<ProviderResponse>(
            managerApiInstance.post(`/providers/doAddUserAsLegalRepresentative`, data)
        ),
    doAddUserAsTecnicalResponsible: async (data: DoAddUserAsTecnicalResponsible) =>
        doRequest<ProviderResponse>(
            managerApiInstance.post(`/providers/doAddUserAsTecnicalResponsible`, data)
        ),
    listLegalRepresentatives: async (providerId: number) =>
        doRequest<ProviderLegalRepresentative[]>(
            managerApiInstance.post(`/providers/listLegalRepresentatives`, {
                providerId,
            })
        ),
    doAcceptTerms: async () =>
        doRequest<NoDataGenericResponse>(managerApiInstance.post(`/providers/doAcceptTerms`)),

    validateVerificationCode: async (body: ValidateVerificationCodeProviderBody) =>
        doRequest<PlainGenericResponse<ValidateVerificationCodeReponse>>(
            managerApiInstance.post(`/providers/providerVerificationCode`, body)
        ),
};

export { providerRequests };
