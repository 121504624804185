import React, { useEffect, useState } from 'react';

import ResendCodeCountdownView from './resend-code-countdown-view';

interface ResendCodeCountdownProps {
    onResendCode?: () => void;
    timeout?: number;
    setErrorSendCode?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ResendCodeCountdown: React.FC<ResendCodeCountdownProps> = ({
    onResendCode,
    timeout,
    setErrorSendCode,
}) => {
    const RESEND_TIME_IN_SECONDS = timeout || 180; // 3 minutes
    const [countdown, setCountdown] = useState(RESEND_TIME_IN_SECONDS);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (countdown > 0) {
                setCountdown((state) => state - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [countdown]);

    const handleOnResendCode = () => {
        setCountdown(RESEND_TIME_IN_SECONDS);
        onResendCode?.();
    };

    return (
        <ResendCodeCountdownView
            countdown={countdown}
            onResendCode={handleOnResendCode}
            setErrorSendCode={setErrorSendCode}
        />
    );
};

export default ResendCodeCountdown;
