import { Grid, Breadcrumbs, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { providerRegistrationUpdateRequests } from 'clients/manager/provider-registration-update-request.requests';
import GenericError from 'common/components/generic-error';
import ModalSendRequest from 'common/components/provider-update-request-warning/modal-send-view';
import i18n from 'i18next';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    HiOutlineCurrencyDollar,
    HiOutlineLibrary,
    HiOutlineLocationMarker,
    HiOutlineOfficeBuilding,
    HiOutlinePaperClip,
    HiOutlineUserGroup,
    HiOutlineViewGridAdd,
} from 'react-icons/hi';
import { useHistory } from 'react-router';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import { usePlatformContext } from 'routes/platform.context';
import { CONSTANTS } from 'common/constants';
import ProviderFinancialDataSignup from 'modules/sign-up/components/provider-financial-data-signup';
import ProviderBankData from 'common/components/provider-bank-data';
import { SignUpProviderProps } from './props';
import Address from './Steps/address';
import Documents from './Steps/documents';
import Identification from './Steps/identification';
import LegalRepresentative from './Steps/legal-representative';
import Segments from './Steps/segments';
import { Container, Content, useStyles } from './styles';

function getSteps() {
    return [
        i18n.t('term.identification'),
        i18n.t('term.address2'),
        i18n.t('info.financial-data'),
        i18n.t('info.bank-data'),
        i18n.t('term.legal-representative'),
        i18n.t('term.segments'),
        i18n.t('title.model-documents'),
    ];
}

function getStepContent(stepIndex: number) {
    switch (stepIndex) {
        case 0:
            return i18n.t('term.identification');
        case 1:
            return i18n.t('term.address2');
        case 2:
            return i18n.t('info.bank-data');
        case 3:
            return i18n.t('info.financial-data');
        case 4:
            return i18n.t('term.legal-representative');
        case 5:
            return i18n.t('term.segments');
        case 6:
            return i18n.t('title.model-documents');
        default:
            return 'Unknown stepIndex';
    }
}

const AdvanceButton = ({ disabled, handleNext }) => (
    <div className='stepper-nav'>
        <Button
            disabled={disabled}
            size='small'
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleNext}
        >
            {i18n.t('term.advance')}
        </Button>
    </div>
);

const SignUpStepperProvider: FC<SignUpProviderProps> = () => {
    const { t } = useTranslation();
    const { platform } = usePlatformContext();
    const { activeStep, handleNext, listLegalRepresentative } = useContext(SignUpProviderContext);
    const steps = getSteps();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDocumentation, setLoadingDocumentation] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const history = useHistory();
    const classes = useStyles();
    const [visibleModalSend, setVisibleModalSend] = useState(false);

    const sendDocumentation = async (processId: number | undefined) => {
        try {
            setLoadingDocumentation(true);
            await providerRegistrationUpdateRequests.sendRegistrationUpdateRequestToReview(
                processId
            );
            history.push('painel-fornecedor');
        } catch (error) {
            setHasError(true);
        } finally {
            setLoadingDocumentation(false);
        }
    };

    useEffect(() => {
        if (platform?.generalSettings?.general?.hideSignupProvider) {
            return history.push('/sem-permissao');
        }
    }, []);

    return (
        <PageWrapper>
            <Container>
                <Content>
                    <Breadcrumbs>
                        <Typography color='primary'>{t('term.register')}</Typography>
                        <Typography color='primary'>{t('term.supplier')}</Typography>
                    </Breadcrumbs>
                    <div>
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            className={classes.stepper}
                        >
                            <Step className={classes.step}>
                                <StepLabel StepIconComponent={HiOutlineOfficeBuilding}>
                                    {i18n.t('term.identification')}
                                </StepLabel>
                            </Step>
                            <Step className={classes.step}>
                                <StepLabel StepIconComponent={HiOutlineLocationMarker}>
                                    {i18n.t('term.address2')}
                                </StepLabel>
                            </Step>
                            <Step className={classes.step}>
                                <StepLabel StepIconComponent={HiOutlineLibrary}>
                                    {i18n.t('info.bank-data')}
                                </StepLabel>
                            </Step>
                            <Step className={classes.step}>
                                <StepLabel StepIconComponent={HiOutlineUserGroup}>
                                    {i18n.t('term.legal-representative')}
                                </StepLabel>
                            </Step>
                            <Step className={classes.step}>
                                <StepLabel StepIconComponent={HiOutlineViewGridAdd}>
                                    {i18n.t('term.segments')}
                                </StepLabel>
                            </Step>
                            <Step className={classes.step}>
                                <StepLabel StepIconComponent={HiOutlinePaperClip}>
                                    {i18n.t('title.model-documents')}
                                </StepLabel>
                            </Step>
                        </Stepper>
                        {hasError ? (
                            <GenericError />
                        ) : (
                            <div className='content-steppers'>
                                {activeStep === steps.length ? (
                                    <Grid
                                        className='sign-up-sucess'
                                        container
                                        justifyContent='center'
                                    >
                                        {visibleModalSend && (
                                            <ModalSendRequest
                                                onSendRequest={() => {}}
                                                onClose={() => {
                                                    setVisibleModalSend(false);
                                                }}
                                                sendDocumentation={sendDocumentation}
                                            />
                                        )}
                                        <Typography variant='h4' color='secondary'>
                                            {t(
                                                'sign-up.pages.stepper-provider.steps.index.info-success-registration-1'
                                            )}
                                            <strong>
                                                {t(
                                                    'sign-up.pages.stepper-provider.steps.index.info-success-registration-2'
                                                )}
                                            </strong>
                                        </Typography>
                                        <Typography>
                                            {t(
                                                'sign-up.pages.stepper-provider.steps.index.info-success-registration-supplier'
                                            )}
                                        </Typography>
                                        <Grid item xs={12}>
                                            <img
                                                className='image-sucess'
                                                src='/assets/images/svgs/sign_up_person_success_img.png'
                                                alt='person'
                                            />
                                        </Grid>
                                        <Button
                                            disabled={loadingDocumentation}
                                            onClick={() => setVisibleModalSend(true)}
                                            size='small'
                                            color='primary'
                                            variant='contained'
                                        >
                                            {t('term.send-doc')}
                                        </Button>
                                    </Grid>
                                ) : (
                                    <div>
                                        <Typography variant='h5' color='primary'>
                                            {getStepContent(activeStep)}
                                        </Typography>

                                        {activeStep === 0 && (
                                            <Identification
                                                loading={loading}
                                                setLoading={setLoading}
                                            />
                                        )}
                                        {activeStep === 1 && (
                                            <Address loading={loading} setLoading={setLoading} />
                                        )}
                                        {activeStep === 2 && (
                                            <>
                                                <ProviderBankData />
                                                <AdvanceButton
                                                    disabled={false}
                                                    handleNext={() => handleNext(4)}
                                                />
                                            </>
                                        )}
                                        {activeStep === 4 && (
                                            <>
                                                <LegalRepresentative />
                                                <AdvanceButton
                                                    disabled={Boolean(
                                                        listLegalRepresentative.length === 0
                                                    )}
                                                    handleNext={handleNext}
                                                />
                                            </>
                                        )}
                                        {activeStep === 5 && <Segments />}
                                        {activeStep === 6 && <Documents />}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Content>
            </Container>
        </PageWrapper>
    );
};

export default SignUpStepperProvider;
