import { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import { Autocomplete } from '@material-ui/lab';
import { SupplyCategoryTypeEnum } from 'clients/manager/interfaces/supply-categories.interface';
import { ModalProviderAddSegmentWithFieldsViewProps } from './props';
import FormTechManagerSearh from '../form-tech-manager-search';
import FormTechManageAdd from '../form-tech-manager-add';

const ModalProviderAddSegmentWithFieldsView: FC<ModalProviderAddSegmentWithFieldsViewProps> = ({
    onClose,
    handleToggle,
    showModalAddTecnicalResponsible,
    checkedCategories,
    form,
    supplyCategories,
    providerNotFoundedError,
    newTecnicalResponsible,
    showCpfFields,
    setShowCpfFields,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {newTecnicalResponsible && (
                <FormTechManageAdd
                    showModalAddTecnicalResponsible={showModalAddTecnicalResponsible}
                    onClose={onClose}
                    form={form}
                    setShowCpfFields={setShowCpfFields}
                />
            )}
            <Modal
                position={ModalPosition.center}
                open
                onClose={(event: any) => {
                    event.stopPropagation();
                    onClose();
                }}
                header={<span>{t('info.add-segments')}</span>}
            >
                <Box width='800px'>
                    <Grid container>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={supplyCategories}
                                    clearOnEscape
                                    disableClearable
                                    getOptionLabel={(option) =>
                                        `${option.externalId ? `${option.externalId} - ` : ''}${
                                            option.categoryName
                                        }`
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('info.search-segments')}
                                            variant='outlined'
                                        />
                                    )}
                                    onChange={(_, category) => handleToggle(category)}
                                />
                            </Grid>
                            {checkedCategories.length > 0 &&
                                checkedCategories[0].supplyCategoryType ===
                                    SupplyCategoryTypeEnum.product && (
                                    <FormTechManagerSearh
                                        form={form}
                                        setShowCpfFields={setShowCpfFields}
                                        showCpfFields={showCpfFields}
                                        providerNotFounded={providerNotFoundedError}
                                        showModalAddTecnicalResponsible={
                                            showModalAddTecnicalResponsible
                                        }
                                    />
                                )}
                        </Grid>

                        <Grid container item xs={12}>
                            <Box mt={2} width={1} display='flex' justifyContent='flex-end'>
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={() => form.submitForm()}
                                    disabled={!checkedCategories.length}
                                >
                                    {t('term.save')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default ModalProviderAddSegmentWithFieldsView;
