import React, { ComponentProps } from 'react';

import {
    MarketplaceProviders,
    OrderItem,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { getAuthUserToken } from 'modules/auth/session.utils';
import OrderItemsTableView from './order-items-table-view';

interface OrderItemsTableProps {
    items: OrderItem[];
}

const OrderItemsTable: React.FC<OrderItemsTableProps> = ({ items }) => {
    const authUser = getAuthUserToken();

    function findAuthProvider(providers?: MarketplaceProviders[]) {
        if (!authUser?.providerId) {
            return undefined;
        }

        return providers?.find((provider) => provider.id === authUser?.providerId);
    }

    const tableItems: ComponentProps<typeof OrderItemsTableView>['items'] = items.map(
        (orderItem) => ({
            id: orderItem.id,
            itemAmount: orderItem.itemAmount,
            itemUnitMeasurement: orderItem.item.itemUnitMeasurement,
            itemName: orderItem.item.itemName,
            provider: findAuthProvider(orderItem?.item?.providers),
        })
    );

    return <OrderItemsTableView items={tableItems} />;
};

export default OrderItemsTable;
