import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
} from 'common/utils';
import DialogSyncPcaPncpView from './dialog-sync-pca-pncp-view';

interface DialogSyncPcaPncpProps {
    countTotalItemsUnit: number;
    totalItemsValue: number;
}

const DialogSyncPcaPncp: React.FC<DialogSyncPcaPncpProps> = ({
    countTotalItemsUnit,
    totalItemsValue,
}) => {
    const {
        pcaSyncId,
        setPcaSyncId,
        pcaStatusEnum,
        hiringPlanItens,
        hiringPlansList,
        setHiringPlansList,
        onCloseDialogSyncPcaPncp,
        setHiringPlanItens,
    } = useHiringPlansContext();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handlerSyncPcaWithPncp = useCallback(async () => {
        setIsLoading(true);
        try {
            if (pcaSyncId) {
                const verifyPcaSincronization = hiringPlanItens
                    .filter((pcaItem) => pcaItem.deletedAt === null)
                    .some((pcaItemFilter) => pcaItemFilter.createdOnPncp === 1);

                const organizationUnit = hiringPlanItens?.length
                    ? hiringPlanItens[0].organizationUnitId
                    : undefined;

                if (verifyPcaSincronization && organizationUnit) {
                    await hiringPlansRequests.synchronizeHiringPlanMissingItemsPncp(
                        pcaSyncId,
                        organizationUnit
                    );
                } else if (organizationUnit) {
                    await hiringPlansRequests.synchronizeHiringPlanWithPncp(
                        pcaSyncId.toString(),
                        organizationUnit
                    );
                }
            }
            setHiringPlansList((prevState) => [
                ...prevState.map((hiringPlan) => {
                    if (hiringPlan.id === pcaSyncId) {
                        return { ...hiringPlan, status: pcaStatusEnum.done };
                    }
                    return hiringPlan;
                }),
            ]);
            if (pcaSyncId) {
                const hiringPlanItensUpdate = await hiringPlansRequests.getListItensHiringPlan({
                    params: { pcaId: pcaSyncId },
                });
                setHiringPlanItens(hiringPlanItensUpdate.data);
            }
            onCloseDialogSyncPcaPncp();
            addNotificationSuccess({
                title: t('term.success'),
                message: t('hiring-plans.info.create-pca-success'),
            });
        } catch (err) {
            if (err.code === 'ECONNABORTED') {
                addNotificationError({
                    title: t('dispute-button.warning-title'),
                    message: t('error.timeout-message'),
                });
            } else {
                addNotificationApiError(err);
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const searchDetailsPcaUnit = (idPca: number) =>
        hiringPlansList.find((hiringPlan) => hiringPlan.id === idPca);

    const formatAmountTotalItemsUnit = Number(totalItemsValue.toFixed(2));

    useEffect(() => () => setPcaSyncId(undefined), []);

    return (
        <DialogSyncPcaPncpView
            isLoading={isLoading}
            searchDetailsPcaUnit={searchDetailsPcaUnit}
            countTotalItemsUnit={countTotalItemsUnit}
            totalItemsValue={formatAmountTotalItemsUnit}
            handlerSyncPcaWithPncp={handlerSyncPcaWithPncp}
        />
    );
};

export default DialogSyncPcaPncp;
