import { FC } from 'react';
import { styled } from 'common/theme/styled';

const Page = styled.div`
    background: ${({ theme }) => theme.colors.backgroundPageDefault};
    margin: 0;
    padding: 15px 20px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ChildContainer = styled.div`
    flex: 1;
    width: 100%;
    max-width: 90%;
`;

interface PageWrapperProps {}

const PageWrapper: FC<PageWrapperProps> = ({ children, ...rest }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Page id='page-child' className='childArea' {...rest}>
        <ChildContainer className='childContainer'>{children}</ChildContainer>
    </Page>
);

export default PageWrapper;
