import { Route } from 'react-router-dom';
import { Profile, MyAccount } from './pages/profile';
import { OAuth2Callback } from './pages/OAuth2/OAuth2-view';

const AuthRoutes: JSX.Element[] = [
    <Route path='/meu-perfil' key='/meu-perfil' exact render={() => <Profile />} />,
    <Route path='/minha-conta' key='/minha-conta' exact render={() => <MyAccount />} />,
    <Route
        path='/oauth2/callback'
        key='/oauth2/callback'
        exact
        render={() => <OAuth2Callback />}
    />,
];

export default AuthRoutes;
