import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse } from 'clients/interfaces/paginated.interface';

import {
    ListOrdersByAccreditationBody,
    ListOrdersByAccreditationResponse,
} from '../interfaces/orders.interfaces';

const BASE_URL = '/orders';

const ordersRequests = {
    listOrdersByAccreditation: async (
        body?: ListOrdersByAccreditationBody,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<ListOrdersByAccreditationResponse>>(
            managerApiInstance.get(
                `${BASE_URL}/orders/accreditation?accreditationId=${body?.params?.accreditationId}&offset=${body?.offset}&limit=${body?.limit}`,
                config
            )
        ),
};

export { ordersRequests };
