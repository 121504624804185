import { FC } from 'react';
import { useFormik } from 'formik';
import { addNotificationApiError } from 'common/utils';
import { verificationEmailRequests } from 'modules/company/edit-company/services/verification-email';
import { TypeAccess } from 'common/interfaces/user.interface';
import { ModalConfirmChangeEmailByAdminView } from './confirm-change-email-view';

export interface ConfirmChangeEmailByAdminProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (values?: any) => void;
    email?: string;
    oldEmail?: string;
    typeCompany: TypeAccess.provider | TypeAccess.organization;
    companyId: number;
}

const ModalConfirmChangeEmailByAdmin: FC<ConfirmChangeEmailByAdminProps> = ({
    onClose,
    open,
    onConfirm,
    email,
    oldEmail,
    companyId,
    typeCompany,
}) => {
    const form = useFormik({
        initialValues: {},
        validateOnBlur: false,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);

                if (!email) {
                    setSubmitting(false);
                    return;
                }

                await verificationEmailRequests.sendEmailToChangeProviderEmailByAdmin({
                    oldEmail,
                    email,
                    typeCompany,
                    companyId,
                });

                resetForm();
                onConfirm?.();
                return;
            } catch (err) {
                addNotificationApiError(err);
                onClose();
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <ModalConfirmChangeEmailByAdminView
            open={open}
            onClose={onClose}
            form={form}
            email={email}
        />
    );
};

export default ModalConfirmChangeEmailByAdmin;
