type OrderByType = 'ASC' | 'DESC';

type AudienceTypes = ('public' | 'provider' | 'citizen' | 'management' | 'organization')[];

type ShowWhere = ('page' | 'widget' | 'topbar' | 'tooltip' | 'both')[];

type PaginationArgs = {
    count: number;
    offset: number;
    limit: number;
};

type NoticeInformation = {
    id: number;
    title: string;
    resume: string;
    description: string;
    textLink?: string;
    urlLink?: string;
    file?: string | null;
    createdAt?: string;
    updateAt?: string;
    isPublic?: boolean;
    isFixedOnTop?: boolean;
    expirationAt?: string | null;
    audience?: AudienceTypes;
    coverImage?: string | null;
    informationFiles?: UploadFileNoticeInformationResponse[];
    showWhere?: ShowWhere;
    category?: InformationCategoryEnum;
};

interface NoticesInformationRequest {
    titleResume?: string;
    expirationAt?: string;
    isPublic?: boolean;
    orderBy?: OrderByType;
    limit?: number;
    offset?: number;
}

interface NoticesInformationResponse {
    data: Array<NoticeInformation>;
    meta: PaginationArgs;
    status: string;
}

interface UniqueNoticeInformationResponse {
    data: NoticeInformation;
    status: string;
}

interface UploadFileNoticeInformationResponse {
    url: string;
}

interface CreateNoticeInformationBody extends Omit<NoticeInformation, 'id'> {
    informationFiles?: UploadFileNoticeInformationResponse[];
}

interface UpdateNoticeInformationBody extends Partial<CreateNoticeInformationBody> {}

export enum InformationCategoryEnum {
    new = 'Novidade',
    improvment = 'Melhoria',
    information = 'Aviso',
}

interface LatestInformation {
    id: number;
    title: string;
    resume: string;
    description: string;
    textLink: string;
    urlLink: string;
    isPublic: boolean;
    isFixedOnTop: boolean;
    category: InformationCategoryEnum;
    showWhere: string;
    createdAt: string;
    updateAt: string;
    audience: string;
    coverImage: string;
    file: string;
}

interface UnreadedInformationCount {
    unreaded: number;
    filterApplied: boolean;
}

export type {
    OrderByType,
    AudienceTypes,
    ShowWhere,
    PaginationArgs,
    NoticeInformation,
    LatestInformation,
    UnreadedInformationCount,
    NoticesInformationRequest,
    NoticesInformationResponse,
    UniqueNoticeInformationResponse,
    UploadFileNoticeInformationResponse,
    CreateNoticeInformationBody,
    UpdateNoticeInformationBody,
};
