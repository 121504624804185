import React, { useRef } from 'react';
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { MaskedTextField, TextField } from 'common/components';
import Cards from 'react-credit-cards-2';
import { CheckoutFormValues } from 'modules/marketplace/pages/organizations/checkout-page';
import { CONSTANTS } from 'common/constants';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import useStyles from './credit-card-style';

interface CreditCardViewProps {
    form: FormikProps<CheckoutFormValues>;
}

const CreditCardView: React.FC<CreditCardViewProps> = ({ form }) => {
    const inFocusRef = useRef();

    const { t } = useTranslation();
    const classes = useStyles();

    // eslint-disable-next-line no-return-assign
    const handleOnFocus = (e: any) => (inFocusRef.current = e.target.name);

    const enableUserDataForm = form?.values.useUserData === 'false';
    const enableAddressForm = form?.values.useOrganizationAddress === 'false';

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('marketplace.checkout.holder-information')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            name='useUserData'
                            value={form?.values.useUserData}
                            onChange={(event) =>
                                form?.setFieldValue('useUserData', event.target.value)
                            }
                        >
                            <FormControlLabel
                                control={<Radio color='primary' value='true' />}
                                label={t('marketplace.checkout.use-user-information')}
                            />
                            <FormControlLabel
                                control={<Radio color='primary' value='false' />}
                                label={t('marketplace.checkout.fill-data')}
                            />
                        </RadioGroup>
                    </Grid>
                    {enableUserDataForm && (
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                label={t('term.full-name')}
                                name='holderName'
                                onChange={form.handleChange}
                                value={form.values.holderName}
                                error={form.touched.holderName && Boolean(form.errors.holderName)}
                                helperText={form.touched.holderName && form.errors.holderName}
                            />
                        </Grid>
                    )}
                    {enableUserDataForm && (
                        <Grid item xs={6}>
                            <IdentificationNumberTextField
                                required
                                identificationNumber={form.values.cpfCnpj}
                                country={CONSTANTS.ibgeCodes.BRAZIL}
                                fieldName='cpfCnpj'
                                type='cpf'
                                {...form}
                            />
                        </Grid>
                    )}
                    {enableUserDataForm && (
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                label={t('term.email')}
                                name='email'
                                onChange={form.handleChange}
                                value={form.values.email}
                                error={form.touched.email && Boolean(form.errors.email)}
                                helperText={form.touched.email && form.errors.email}
                            />
                        </Grid>
                    )}
                    {enableUserDataForm && (
                        <Grid item xs={6}>
                            <MaskedTextField
                                required
                                fullWidth
                                mask='(99) 99999-9999'
                                label={t('term.phone')}
                                name='phone'
                                onChange={form.handleChange}
                                value={form.values.phone}
                                error={form.touched.phone && Boolean(form.errors.phone)}
                                helperText={form.touched.phone && form.errors.phone}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('marketplace.checkout.address-to-billing')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label='position'
                            name='useOrganizationAddress'
                            value={form?.values.useOrganizationAddress}
                            onChange={(event) =>
                                form?.setFieldValue('useOrganizationAddress', event.target.value)
                            }
                        >
                            <FormControlLabel
                                control={<Radio color='primary' value='true' />}
                                label={t('marketplace.checkout.use-organization-address-data')}
                            />
                            <FormControlLabel
                                control={<Radio color='primary' value='false' />}
                                label={t('marketplace.checkout.fill-new-address-data')}
                            />
                        </RadioGroup>
                    </Grid>
                    {enableAddressForm && (
                        <Grid item xs={6}>
                            <MaskedTextField
                                required
                                mask='99999-999'
                                fullWidth
                                label='CEP'
                                name='postalCode'
                                value={form.values.postalCode}
                                onChange={form.handleChange}
                                error={form.touched.postalCode && Boolean(form.errors.postalCode)}
                                helperText={form.touched.postalCode && form.errors.postalCode}
                            />
                        </Grid>
                    )}
                    {enableAddressForm && (
                        <Grid item xs={6}>
                            <TextField
                                required
                                fullWidth
                                label={t('term.number')}
                                name='addressNumber'
                                value={form.values.addressNumber}
                                onChange={form.handleChange}
                                error={
                                    form.touched.addressNumber && Boolean(form.errors.addressNumber)
                                }
                                helperText={form.touched.addressNumber && form.errors.addressNumber}
                            />
                        </Grid>
                    )}
                    {enableAddressForm && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('term.complement')}
                                name='addressComplement'
                                value={form.values.addressComplement}
                                onChange={form.handleChange}
                                error={
                                    form.touched.addressComplement &&
                                    Boolean(form.errors.addressComplement)
                                }
                                helperText={
                                    form.touched.addressComplement && form.errors.addressComplement
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
                <Grid container direction='column' spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('marketplace.checkout.credit-card-information')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label={t('bidding.checkout.credit_card.name')}
                            name='name'
                            inputProps={{ maxLength: 20 }}
                            value={form.values.name}
                            onChange={form.handleChange}
                            onFocus={handleOnFocus}
                            error={form.touched.name && !!form.errors.name}
                            helperText={form.touched.name && form.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MaskedTextField
                            required
                            fullWidth
                            mask='9999 9999 9999 9999'
                            label={t('bidding.checkout.credit_card.number')}
                            maskChar=' '
                            inputProps={{ maxLength: 20 }}
                            name='number'
                            value={form.values.number}
                            onChange={(e) => {
                                const rawNumber = e.target.value.trimEnd();
                                return form.setFieldValue('number', rawNumber);
                            }}
                            onFocus={handleOnFocus}
                            error={form.touched.number && !!form.errors.number}
                            helperText={form.touched.number && form.errors.number}
                        />
                    </Grid>
                    <Grid container item spacing={2}>
                        <Grid item xs={6}>
                            <MaskedTextField
                                required
                                fullWidth
                                mask='99/99'
                                label={t('bidding.checkout.credit_card.due_date')}
                                maskChar=' '
                                name='expiry'
                                value={form.values.expiry}
                                onChange={(e) => {
                                    const rawNumber = e.target.value.trimEnd();
                                    return form.setFieldValue('expiry', rawNumber);
                                }}
                                onFocus={handleOnFocus}
                                error={form.touched.expiry && !!form.errors.expiry}
                                helperText={form.touched.expiry && form.errors.expiry}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MaskedTextField
                                required
                                fullWidth
                                mask='999'
                                label={t('bidding.checkout.credit_card.cvv')}
                                maskChar=' '
                                name='cvc'
                                value={form.values.cvc}
                                onChange={(e) => {
                                    const rawNumber = e.target.value.trimEnd();
                                    return form.setFieldValue('cvc', rawNumber);
                                }}
                                onFocus={handleOnFocus}
                                error={form.touched.cvc && !!form.errors.cvc}
                                helperText={form.touched.cvc && form.errors.cvc}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id='installments'
                            color='primary'
                            label={t('bidding.checkout.credit_card.installments')}
                            value={form.values.installments}
                            onChange={(event) => {
                                form.setFieldValue('installments', event.target.value);
                            }}
                            variant='outlined'
                            select
                            error={form.touched.installments && !!form.errors.installments}
                            helperText={form.touched.installments && form.errors.installments}
                        >
                            <MenuItem value={1}>Preço à vista</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={5} className={classes.creditCardImg}>
                <Cards
                    cvc={form.values.cvc}
                    expiry={form.values.expiry}
                    focused={inFocusRef.current}
                    name={form.values.name}
                    number={form.values.number}
                />
            </Grid>
        </Grid>
    );
};

export default CreditCardView;
