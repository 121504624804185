import { FormikProps, useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { usePlatformContext } from 'routes/platform.context';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { AuctionNoticeLotWithItems } from '../../../clients/manager/interfaces/auction-notice-lot.interface';
import {
    AuctionNotice,
    TypeValueBidTypes,
} from '../../../clients/manager/interfaces/auction-notice.interface';
import { AuctionTypeRule } from '../../../clients/manager/interfaces/auction-type-rules.interface';
import { getProcessValidationSchema } from './schema/process-schema';
import { processUtils } from '../process-utils';

export type ProcessForm = AuctionNotice & {
    processUnits?: Array<{
        unitId: number;
    }>;
};

export type ProcessFormContextType = {
    processForm: FormikProps<ProcessForm> | undefined;
    processLotsAndItems: AuctionNoticeLotWithItems[];
    setProcessLotsAndItems: React.Dispatch<React.SetStateAction<AuctionNoticeLotWithItems[]>>;
    loadingProcessData: boolean;
    setLoadingProcessData: React.Dispatch<React.SetStateAction<boolean>>;
    process: AuctionNotice | undefined;
    setProcess: React.Dispatch<React.SetStateAction<ProcessForm | undefined>>;
    processBkpBeforeSave: AuctionNotice | undefined;
    setProcessBkpBeforeSave: React.Dispatch<React.SetStateAction<AuctionNotice | undefined>>;
    auctionTypeRules: AuctionTypeRule | undefined;
    setAuctionTypeRules: React.Dispatch<React.SetStateAction<AuctionTypeRule | undefined>>;
    publishDateError: boolean;
    setPublishDateError: React.Dispatch<React.SetStateAction<boolean>>;
    accreditationDocumentsError: boolean;
    setAccreditationDocumentsError: React.Dispatch<React.SetStateAction<boolean>>;
    canSendInvites: boolean;
    setCanSendInvites: React.Dispatch<React.SetStateAction<boolean>>;
    requiredDocumentsTypesIds: number[];
    setRequiredDocumentsTypesIds: (selected: number[]) => void;
    isAgglutinatedItems: boolean | undefined;
    setCheckProcess: React.Dispatch<React.SetStateAction<string>>;
    warnings: { [x: string]: any };
    lotsError: { id: number; error: string }[];
};

export const ProcessFormContext = createContext<ProcessFormContextType>({
    processForm: undefined,
    loadingProcessData: false,
    setLoadingProcessData: () => {},
    setProcessLotsAndItems: () => {},
    processLotsAndItems: [],
    process: undefined,
    setProcess: () => {},
    processBkpBeforeSave: undefined,
    setProcessBkpBeforeSave: () => {},
    auctionTypeRules: undefined,
    setAuctionTypeRules: () => {},
    publishDateError: false,
    setPublishDateError: () => {},
    accreditationDocumentsError: false,
    setAccreditationDocumentsError: () => {},
    canSendInvites: false,
    setCanSendInvites: () => {},
    requiredDocumentsTypesIds: [],
    setRequiredDocumentsTypesIds: () => {},
    isAgglutinatedItems: false,
    setCheckProcess: () => {},
    warnings: {},
    lotsError: [],
});

export const useProcessFormContext = () => useContext(ProcessFormContext);

export const ProcessFormContextProvider = ({ children }: { children: ReactNode }) => {
    const [loadingProcessData, setLoadingProcessData] = useState(false);
    const [processLotsAndItems, setProcessLotsAndItems] = useState<AuctionNoticeLotWithItems[]>([]);
    const [process, setProcess] = useState<ProcessForm | undefined>();
    const [processBkpBeforeSave, setProcessBkpBeforeSave] = useState<AuctionNotice | undefined>();
    const [auctionTypeRules, setAuctionTypeRules] = useState<AuctionTypeRule>();
    const [publishDateError, setPublishDateError] = useState(false);
    const [accreditationDocumentsError, setAccreditationDocumentsError] = useState(false);
    const [canSendInvites, setCanSendInvites] = useState<boolean>(false);
    const [requiredDocumentsTypesIds, setRequiredDocumentsTypesIds] = useState<number[]>([]);
    const [isAgglutinatedItems, setIsAgglutinatedItems] = useState<boolean | undefined>();
    const [checkProcess, setCheckProcess] = useState('');
    const [warnings, setWarnings] = useState({});
    const [lotsError, setLotsError] = useState<{ id: number; error: string }[]>([]);
    const [awaitCheck, setAwaitCheck] = useState(false);

    const { platform } = usePlatformContext();

    const processRef = useRef<Pick<ProcessFormContextType, 'process' | 'setProcess'>>();
    processRef.current = { process, setProcess };

    const processBkpBeforeSaveRef =
        useRef<Pick<ProcessFormContextType, 'processBkpBeforeSave' | 'setProcessBkpBeforeSave'>>();
    processBkpBeforeSaveRef.current = { processBkpBeforeSave, setProcessBkpBeforeSave };

    const processLotsAndItemsRef =
        useRef<Pick<ProcessFormContextType, 'processLotsAndItems' | 'setProcessLotsAndItems'>>();
    processLotsAndItemsRef.current = { processLotsAndItems, setProcessLotsAndItems };

    const auctionTypeRulesRef =
        useRef<Pick<ProcessFormContextType, 'auctionTypeRules' | 'setAuctionTypeRules'>>();
    auctionTypeRulesRef.current = { auctionTypeRules, setAuctionTypeRules };

    const initialValues = process ? { ...process } : {};

    const form = useFormik<AuctionNotice>({
        enableReinitialize: true,
        validateOnMount: true,
        validate: (values) => {
            try {
                validateYupSchema(
                    values,
                    getProcessValidationSchema(values, auctionTypeRules, platform),
                    true
                );
            } catch (err) {
                return yupToFormErrors(err);
            }

            return {};
        },
        isInitialValid: false,
        initialValues: initialValues as AuctionNotice,
        onSubmit: () => {},
    });

    useEffect(() => {
        form.setFieldValue('auctionType', auctionTypeRules?.auctionType);
        const typeValueBid =
            processBkpBeforeSaveRef.current?.processBkpBeforeSave?.typeValueBid ||
            process?.typeValueBid;
        setIsAgglutinatedItems(
            auctionTypeRules?.generalSettings.cadastro.aglutinarItensNoGlobal &&
                typeValueBid === TypeValueBidTypes.kit
        );
        if (
            processUtils.isAccreditationProcess(form.values) ||
            processUtils.isUnenforceabilityProcess(form.values)
        ) {
            if (processUtils.isMarketplace(form.values)) {
                form.setFieldValue('typeValueBid', TypeValueBidTypes.unit);
            } else {
                form.setFieldValue('typeValueBid', TypeValueBidTypes.kit);
            }
        }
    }, [auctionTypeRules, processBkpBeforeSave?.typeValueBid, process]);

    useEffect(() => {
        const checkProcessToPublish = async () => {
            setAwaitCheck(true);
            setLoadingProcessData(true);
            if (process && process.id && !process.auctionPublishedDate && !awaitCheck) {
                setCheckProcess('');
                const participantDocument =
                    process?.participantDocument || form?.values?.participantDocument;

                const data = await auctionNoticeRequests
                    .checkProcessToPublish(process.id, participantDocument)
                    .then(() => null)
                    .catch((err) => {
                        if (err.response.data) {
                            const { data } = err.response;
                            return data;
                        }
                    });
                const dataWarning = { ...data?.warnings };
                const dataLotsError = data?.lotsError ? [...data.lotsError] : [];
                setLotsError(dataLotsError);
                setWarnings(dataWarning);
            }
            setLoadingProcessData(false);
            setAwaitCheck(false);
        };
        checkProcessToPublish();
    }, [checkProcess, process]);

    useEffect(() => {
        const dataWarning = { ...warnings } as { [x: string]: boolean | undefined };
        if (Object.keys(form.errors).length) {
            dataWarning.requiredFieldGeneral = true;
        } else {
            delete dataWarning.requiredFieldGeneral;
        }
        setWarnings(dataWarning);
    }, [form.errors]);

    return (
        <ProcessFormContext.Provider
            value={{
                processForm: form,
                processLotsAndItems,
                setProcessLotsAndItems,
                loadingProcessData,
                setLoadingProcessData,
                process: processRef.current.process,
                setProcess: processRef.current.setProcess,
                processBkpBeforeSave: processBkpBeforeSaveRef.current.processBkpBeforeSave,
                setProcessBkpBeforeSave: processBkpBeforeSaveRef.current.setProcessBkpBeforeSave,
                auctionTypeRules: auctionTypeRulesRef.current.auctionTypeRules,
                setAuctionTypeRules: auctionTypeRulesRef.current.setAuctionTypeRules,
                publishDateError,
                setPublishDateError,
                accreditationDocumentsError,
                setAccreditationDocumentsError,
                canSendInvites,
                setCanSendInvites,
                requiredDocumentsTypesIds,
                setRequiredDocumentsTypesIds,
                isAgglutinatedItems,
                setCheckProcess,
                warnings,
                lotsError,
            }}
        >
            {children}
        </ProcessFormContext.Provider>
    );
};
