import { getBackofficeRedirectUrl } from 'common/utils';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

export const RedirectOAuth2Login = (url: string) => {
    const styles = useStyles();
    const loginBaseUrl = getBackofficeRedirectUrl(true);

    window.location.replace(`${loginBaseUrl}/${url}`);
    return (
        <div className={styles.redirectMain}>
            <div className={styles.loading}>
                <CircularProgress className={clsx(styles.loading)} />
            </div>
        </div>
    );
};
