import moment from 'moment';
import { useEffect, useState } from 'react';
import { formatCountdown } from 'common/utils';
import OrderDeadlineCountdownView from './order-deadline-countdown-view';

interface OrderDeadlineCountdownProps {
    deadline: string;
    onlyCountDown?: boolean;
}

const OrderDeadlineCountdown: React.FC<OrderDeadlineCountdownProps> = ({
    deadline,
    onlyCountDown,
}) => {
    const dateDifference = moment(deadline).diff(moment(), 'seconds');
    const [secondsToDeadline, setSecondsToDeadline] = useState(
        dateDifference < 0 ? 0 : dateDifference
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (secondsToDeadline > 0) {
                setSecondsToDeadline((state) => state - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [secondsToDeadline]);

    const countdown = formatCountdown(secondsToDeadline);

    return (
        <OrderDeadlineCountdownView
            secondsToDeadline={dateDifference}
            countdown={countdown}
            onlyCountDown={onlyCountDown}
        />
    );
};

export default OrderDeadlineCountdown;
