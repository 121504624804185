import React from 'react';

import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useHistory } from 'react-router';
import ProviderOrderCardView from './provider-order-card-view';

interface ProviderOrderCardProps {
    order: OrderResponse;
}

const ProviderOrderCard: React.FC<ProviderOrderCardProps> = ({ order }) => {
    const history = useHistory();

    const handleRedirectToAction = () => {
        history.push(`/mercado/fornecedor/pedidos/julgamento/${order.id}`);
    };

    return <ProviderOrderCardView order={order} redirectToAction={handleRedirectToAction} />;
};

export default ProviderOrderCard;
