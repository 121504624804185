import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import { TextField } from 'common/components';
import { CurrencyInput } from 'common/components/currency';
import { FinancialProviderData } from 'clients/manager/interfaces/financial-provider-data.interface';
import { FinancialProviderDataRequests } from 'clients/manager/financial-provider-data.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

import { usePlatformContext } from 'routes/platform.context';
import { useProviderUpdateRequestWarningContext } from '../provider-update-request-warning/context';
import LoadingButton from '../loading-button';

export interface ProviderFinancialDataProps {
    financialData?: FinancialProviderData;
    setHasUpdate: (open: boolean) => void;
    providerId: number;
    disabled?: boolean;
}

const ProviderFinancialData: FC<ProviderFinancialDataProps> = ({
    financialData,
    providerId,
    setHasUpdate,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const [financialDataValue, setFinancialDataValue] = useState<FinancialProviderData | undefined>(
        financialData
    );
    const [updatedData, setUpdatedData] = useState<boolean>(false);
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const { platform } = usePlatformContext();
    const generalSettingsPlataform = platform?.generalSettings;
    const isEnableFinancialData = Boolean(
        generalSettingsPlataform?.general?.showFinancialDataSignup
    );
    const isAdmin = authUserTokenData?.typeAccess === TypeAccess.management;

    const handleValue = (key: string, value) => {
        setFinancialDataValue(() => ({ ...financialDataValue, [key]: value }));
    };

    const getFinancialData = async () => {
        try {
            const { data } = await FinancialProviderDataRequests.getFinancialProviderData({
                providerId,
            });
            setFinancialDataValue(data[0]);
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    useEffect(() => {
        getFinancialData();
    }, []);

    const updateFinancialData = async () => {
        setUpdatedData(true);

        try {
            if (isAdmin) {
                await FinancialProviderDataRequests.doUpdateFinancialProviderDataByAdmin(
                    financialDataValue || {}
                );

                ReactDOM.unstable_batchedUpdates(() => {
                    addNotificationSuccess({
                        title: 'Informações Atualizadas',
                        message: t('info.admin-provider-request-update'),
                        duration: 6000,
                    });
                    setUpdatedData(false);
                });
            } else {
                await FinancialProviderDataRequests.updateFinancialProviderData({
                    ...financialDataValue,
                    providerId,
                });

                ReactDOM.unstable_batchedUpdates(() => {
                    addNotificationSuccess({
                        title: 'Solicitação enviada',
                        message: t('info.provider-request-update'),
                        duration: 6000,
                    });
                    setForceHasChanges(true);
                    setUpdatedData(false);
                });

                setHasUpdate(true);
            }
        } catch (error) {
            setUpdatedData(false);
            addNotificationApiError(error);
        }
    };

    return (
        <Box p={2}>
            <Grid container>
                <Box mt={3} mb={3} width={1}>
                    <Typography variant='h6'> {t('info.financial-data')} </Typography>
                    <Divider component='h6' />
                </Box>
                <Grid container item spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label={t(
                                'sign-up.pages.stepper-provider.steps.financial.classification'
                            )}
                            name='classification'
                            value={financialDataValue?.classification}
                            onChange={(e) => {
                                handleValue('classification', e.target.value);
                            }}
                            disabled={disabled}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: 14 }}>
                                                {t(
                                                    'sign-up.pages.stepper-provider.steps.financial.classification-info'
                                                )}
                                            </span>
                                        }
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            allowNegative
                            decimalSeparator=','
                            precision={2}
                            thousandSeparator='.'
                            name='workingCapital'
                            label={t('sign-up.pages.stepper-provider.steps.workingCapital')}
                            variant='outlined'
                            value={financialDataValue?.workingCapital}
                            defaultValue={10000}
                            color='primary'
                            onChange={(_, value: string) => {
                                handleValue('workingCapital', value as String);
                            }}
                            disabled={disabled}
                            component={TextField}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: 14 }}>
                                                {t(
                                                    'sign-up.pages.stepper-provider.steps.workingCapital-info'
                                                )}
                                            </span>
                                        }
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CurrencyInput
                            fullWidth
                            allowNegative
                            decimalSeparator=','
                            precision={2}
                            name='netWorth'
                            thousandSeparator='.'
                            label={t('sign-up.pages.stepper-provider.steps.netWorth')}
                            variant='outlined'
                            value={financialDataValue?.netWorth}
                            defaultValue={10000}
                            color='primary'
                            onChange={(_, value: number) => {
                                handleValue('netWorth', value);
                            }}
                            disabled={disabled}
                            component={TextField}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: 14 }}>
                                                {t(
                                                    'sign-up.pages.stepper-provider.steps.netWorth-info'
                                                )}
                                            </span>
                                        }
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 4 }}
                            label={t('sign-up.pages.stepper-provider.steps.exerciseYear')}
                            name='exerciseYear'
                            value={financialDataValue?.exerciseYear}
                            onChange={(e) => {
                                handleValue('exerciseYear', e.target.value);
                            }}
                            disabled={disabled}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: 14 }}>
                                                {t(
                                                    'sign-up.pages.stepper-provider.steps.exerciseYear-info'
                                                )}
                                            </span>
                                        }
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label={t('sign-up.pages.stepper-provider.steps.operationalResult')}
                            name='operationalResult'
                            value={financialDataValue?.operationalResult}
                            onChange={(e) => {
                                handleValue('operationalResult', e.target.value);
                            }}
                            disabled={disabled}
                            InputProps={{
                                endAdornment: (
                                    <Tooltip
                                        title={
                                            <span style={{ fontSize: 14 }}>
                                                {t(
                                                    'sign-up.pages.stepper-provider.steps.operationalResult-info'
                                                )}
                                            </span>
                                        }
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignContent='center' justifyContent='flex-end'>
                <Box mt={2}>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        onClick={() => updateFinancialData()}
                        disabled={disabled}
                        size='small'
                        {...(updatedData
                            ? {
                                  loading: {
                                      text: `${t('term.updating')}..`,
                                  },
                              }
                            : {})}
                    >
                        {t('term.save-data')}
                    </LoadingButton>
                </Box>
            </Grid>
        </Box>
    );
};

export default ProviderFinancialData;
