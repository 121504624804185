import { memo, useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import { Skeleton } from '@material-ui/lab';
import { usePageContext } from 'common/components/base/pages/context';
import { addNotificationError } from 'common/utils';
import useHiringPlansContext from '../../hooks/useHiringPlans';
import BreadcrumbsComponent from '../../components/breadcrumbs';
import DialogNewPca from './components/dialog-new-pca';
import PcaItem from './components/pca-item';
import AdvancedSearchFiltersAside from './components/advanced-search-filters-aside';
import {
    HiringPlansPageWrapper,
    SearchHeader,
    SectionContent,
    SectionViewContent,
    AdvancedFiltersBackgroundBlock,
    HeaderPage,
    ActionButtonsSection,
    LoadingIndicatorContainer,
    BlankArea,
} from './style';

const HiringPlansSearch = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const { onOpenModalCrudPca, hiringPlansList, setHiringPlansList, isOpenDialogCrudPca } =
        useHiringPlansContext();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorOnGetData, setErrorOnGetData] = useState(false);

    const buttonNewPcaTitle = t('hiring-plans.title.new-pca');
    const skeletonPcaItensArray = Array(5).fill('');
    const BreadcrumbsLinks = [
        { url: '/planos-contratacao', title: t('term.hiring-plans'), active: false },
    ];

    const getListAllHiringPlans = async () => {
        setIsLoading(true);
        try {
            const response = await hiringPlansRequests.getAllHiringPlans();
            const responseFilter = response.filter((pca) => pca.status !== 'deleted');
            setHiringPlansList(responseFilter);
            setErrorOnGetData(false);
        } catch (err) {
            setErrorOnGetData(true);
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.get-pca-error'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setTitle(t('term.hiring-plans'));
        getListAllHiringPlans();
    }, []);

    return (
        <HiringPlansPageWrapper active={showFilters}>
            {isOpenDialogCrudPca && <DialogNewPca />}
            <SearchHeader>
                <HeaderPage>
                    <BreadcrumbsComponent links={BreadcrumbsLinks} />
                </HeaderPage>
                <ActionButtonsSection>
                    <IconButton onClick={() => setShowFilters((prevState) => !prevState)}>
                        <FiFilter size={28} color='var(--primary-color)' />
                    </IconButton>
                    <Button
                        color='primary'
                        variant='contained'
                        size='small'
                        style={{
                            height: 'fit-content',
                            padding: '11px 22px',
                            fontSize: '0.85rem',
                        }}
                        onClick={onOpenModalCrudPca}
                    >
                        {buttonNewPcaTitle}
                    </Button>
                </ActionButtonsSection>
                <AdvancedFiltersBackgroundBlock active={showFilters} />
            </SearchHeader>
            <SectionContent>
                <AdvancedSearchFiltersAside showAdvancedFilters={showFilters} />
                <SectionViewContent>
                    {!errorOnGetData &&
                        hiringPlansList.length > 0 &&
                        hiringPlansList.map((pcaItem) => <PcaItem key={pcaItem.id} {...pcaItem} />)}
                    {isLoading &&
                        hiringPlansList.length === 0 &&
                        skeletonPcaItensArray.map((skeletonItem, idx) => (
                            <Skeleton
                                key={`skeleton-${idx + 1}`}
                                variant='rect'
                                animation='wave'
                                style={{
                                    opacity: 0.6,
                                    minHeight: '98px',
                                    borderRadius: '4px',
                                }}
                            />
                        ))}
                    {isLoading && hiringPlansList.length > 0 && (
                        <LoadingIndicatorContainer>
                            <CircularProgress color='primary' size={24} />
                            <Typography color='primary' variant='subtitle2'>
                                {t('hiring-plans.info.inform-loading-pcas')}
                            </Typography>
                        </LoadingIndicatorContainer>
                    )}
                    {!isLoading && errorOnGetData && (
                        <BlankArea border>
                            <h1>{t('hiring-plans.info.get-pca-error')}</h1>
                            <h2>{t('hiring-plans.info.try-again-later')}</h2>
                        </BlankArea>
                    )}
                    {!isLoading && !errorOnGetData && hiringPlansList.length === 0 && (
                        <BlankArea border>
                            <h1>{t('hiring-plans.info.get-pca-empty-list')}</h1>
                            <h2>
                                {t('hiring-plans.info.sign-up-pca', { button: buttonNewPcaTitle })}
                            </h2>
                        </BlankArea>
                    )}
                </SectionViewContent>
            </SectionContent>
        </HiringPlansPageWrapper>
    );
};

export default memo(HiringPlansSearch);
