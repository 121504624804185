import { Button, Typography, ButtonProps } from '@material-ui/core';
import React from 'react';
import Add from '@material-ui/icons/Add';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useTranslation } from 'react-i18next';
import { UsePaginatedQueryResponse } from 'common/hooks/use-paginated-query.hook';
import { GenericResponse } from 'clients/interfaces/paginated.interface';
import { Pagination } from '@material-ui/lab';
import ProcessOrderCard from './components/process-order-card';
import { useStyles } from './process-orders-data-style';
import { ProcessOrderCardSkeleton } from './components/process-order-card/process-order-card';
import { ListOrdersByAccreditationResponse } from '../../interfaces/orders.interfaces';

export interface ProcessOrdersDataViewProps {
    orders: OrderResponse[];
    loading?: boolean;
    paginationParams: Omit<
        UsePaginatedQueryResponse<GenericResponse<ListOrdersByAccreditationResponse>>,
        'data' | 'loading'
    >;
    onClick?: ButtonProps['onClick'];
}

const ProcessOrdersDataView: React.FC<ProcessOrdersDataViewProps> = ({
    orders,
    paginationParams,
    loading = true,
    onClick,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    const hasOrder = !!orders.length;

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <Typography className={styles.title}>
                    {t('process.orders-data.process-purchasing')} (
                    {paginationParams?.totalCount ?? 0})
                </Typography>
                <Button color='primary' variant='contained' endIcon={<Add />} onClick={onClick}>
                    {t('process.orders-data.new-purchase')}
                </Button>
            </header>
            <section>
                {loading && (
                    <>
                        <ProcessOrderCardSkeleton />
                        <ProcessOrderCardSkeleton />
                    </>
                )}
                {hasOrder && (
                    <>
                        {orders.map((order) => (
                            <ProcessOrderCard key={order.id} order={order} />
                        ))}
                    </>
                )}
                {paginationParams?.totalCount > 0 && (
                    <Pagination
                        className={styles.notFoundLabel}
                        shape='rounded'
                        count={Math.floor(paginationParams.totalCount / paginationParams.limit)}
                        page={paginationParams.page + 1}
                        onChange={(_e, page) => paginationParams.setPage(page - 1)}
                    />
                )}

                {!hasOrder && !loading && (
                    <Typography className={styles.notFoundLabel}>
                        {t('term.items-not-found')}
                    </Typography>
                )}
            </section>
        </div>
    );
};

export default ProcessOrdersDataView;
