import React, { memo, useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RiFilter2Fill, RiFilter2Line } from 'react-icons/ri';
import { addNotificationError } from 'common/utils';
import { usePageContext } from 'common/components/base/pages/context';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { OrderByType } from 'clients/manager/interfaces/notice-information.interfaces';
import { noticesInformationRequests } from 'clients/manager/notice-information.requests';
import Breadcrumb from 'common/components/base/breadcrumb';
import useNoticesInformationContext from 'modules/notices-information/hooks/useNoticesInformation';
import CardNoticeInformationView from './components/card-notice-information';
import CardNoticeInformationSkeleton from './components/card-notice-information/card-notice-information-skeleton';
import DialogCrudNoticeInformation from './components/dialog-crud-notice-information';

const SearchNoticesInformationPage = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const {
        noticesInformation,
        setNoticesInformation,
        authenticatedUserIsAdmin,
        setNoticeInformationIdForEdit,
    } = useNoticesInformationContext();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
    const [showDialogCrudNoticeInformation, setShowDialogCrudNoticeInformation] = useState(false);

    const form = useFormik<{ titleResume: string; expirationAt: string; orderBy: OrderByType }>({
        initialValues: {
            titleResume: '',
            orderBy: 'DESC',
            expirationAt: new Date().toJSON(),
        },
        validationSchema: yup.object({
            titleResume: yup.string(),
            orderBy: yup.string(),
            expirationAt: yup.string(),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const response = await noticesInformationRequests.getAllNoticesInformation({
                    titleResume: values.titleResume,
                    orderBy: values.orderBy,
                    expirationAt: authenticatedUserIsAdmin ? undefined : new Date().toJSON(),
                });
                const { data, meta } = response;
                setError(false);
                setNoticesInformation(data);
                setHasNextPage([...noticesInformation, ...data].length < (meta?.count || 0));
            } catch (err) {
                setError(true);
                addNotificationError({
                    title: t('term.error'),
                    message: t(
                        'notices-information.info.error-get-list-all-notices-information-by-filter'
                    ),
                });
            } finally {
                setIsLoading(false);
            }
        },
    });

    const editNoticeInformation = useCallback(
        (noticeInformationId: number) => {
            setNoticeInformationIdForEdit(noticeInformationId);
            setShowDialogCrudNoticeInformation(true);
        },
        [noticesInformation]
    );

    const newNoticeInformation = useCallback(() => {
        setNoticeInformationIdForEdit(undefined);
        setShowDialogCrudNoticeInformation(true);
    }, [noticesInformation]);

    const closeDialogCrudNoticeInformation = useCallback(() => {
        setShowDialogCrudNoticeInformation(false);
    }, []);

    const getListNoticesInformation = async (nextPage?: boolean) => {
        if (isLoading || (nextPage && !hasNextPage)) return;
        if (nextPage) setIsLoadingNextPage(true);
        else setIsLoading(true);
        try {
            const response = await noticesInformationRequests.getAllNoticesInformation({
                ...form.values,
                offset: nextPage ? noticesInformation.length : 0,
                expirationAt: authenticatedUserIsAdmin ? undefined : new Date().toJSON(),
            });
            const { data, meta } = response;
            setError(false);
            setNoticesInformation((prevState) => [...prevState, ...data]);
            setHasNextPage([...noticesInformation, ...data].length < (meta?.count || 0));
        } catch (err) {
            setError(true);
            addNotificationError({
                title: t('term.error'),
                message: t('notices-information.info.error-get-list-all-notices-information-title'),
            });
        } finally {
            setIsLoading(false);
            setIsLoadingNextPage(false);
        }
    };

    useEffect(() => {
        const pageEl = document.querySelector('#page-child') as HTMLElement;
        let localLoading = false;

        const onScroll = ({ target }: { target: any }) => {
            if (isLoading || isLoadingNextPage || localLoading) return;

            const element = target as HTMLDivElement;
            const triggerScrollCoor = element.scrollHeight - element.clientHeight - 128;

            if (element.scrollTop >= triggerScrollCoor) {
                localLoading = true;
                getListNoticesInformation(true).finally(() => {
                    localLoading = false;
                });
            }
        };

        pageEl.addEventListener('scroll', onScroll);
        return () => pageEl.removeEventListener('scroll', onScroll);
    }, [isLoading, isLoadingNextPage, getListNoticesInformation]);

    useEffect(() => {
        setTitle(t('term.notices-information'));
        if (!noticesInformation.length) getListNoticesInformation();
    }, []);

    return (
        <PageWrapper>
            {showDialogCrudNoticeInformation && (
                <DialogCrudNoticeInformation
                    isOpenDialogNewNoticeInformation={showDialogCrudNoticeInformation}
                    onCloseModalDialogNewNoticeInformation={closeDialogCrudNoticeInformation}
                />
            )}
            <Container fixed maxWidth='xl' style={{ padding: 0 }}>
                <Box marginBottom={3}>
                    <Breadcrumb
                        customPath='/avisos-informacoes'
                        paths={{
                            'avisos-informacoes': {
                                value: t('term.notices-information'),
                                disabled: true,
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    {authenticatedUserIsAdmin ? (
                        <Button
                            size='medium'
                            disabled={isLoading || !authenticatedUserIsAdmin}
                            style={{
                                height: '100%',
                            }}
                            variant='contained'
                            color='primary'
                            onClick={newNoticeInformation}
                        >
                            {t('notices-information.title.register-notices-information')}
                        </Button>
                    ) : (
                        <span />
                    )}
                    <IconButton onClick={() => setShowFilterMenu((prevState) => !prevState)}>
                        {!showFilterMenu ? (
                            <RiFilter2Fill size={28} color='var(--primary-color)' />
                        ) : (
                            <RiFilter2Line size={28} color='var(--primary-color)' />
                        )}
                    </IconButton>
                </Box>
                <Box sx={{ marginY: '16px' }}>
                    {showFilterMenu && (
                        <Paper variant='outlined' elevation={0} style={{ padding: '14px' }}>
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={8}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            disabled={isLoading || isLoadingNextPage}
                                            size='small'
                                            type='text'
                                            variant='outlined'
                                            name='titleResume'
                                            label={t('notices-information.labels.title-summary')}
                                            placeholder={t(
                                                'notices-information.info.placeholder-enter-content-search-title-summary'
                                            )}
                                            value={form.values.titleResume}
                                            onChange={form.handleChange}
                                            error={Boolean(form.errors.titleResume)}
                                            helperText={form.errors.titleResume}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <TextField
                                            select
                                            fullWidth
                                            disabled={isLoading || isLoadingNextPage}
                                            size='small'
                                            key='order-by-notices-information'
                                            variant='outlined'
                                            name='orderBy'
                                            label={t('notices-information.labels.order-by')}
                                            value={form.values.orderBy}
                                            onChange={form.handleChange}
                                            error={Boolean(form.errors.orderBy)}
                                            helperText={form.errors.orderBy}
                                        >
                                            <MenuItem key='nenhum' value='' disabled>
                                                {t('notices-information.labels.none')}
                                            </MenuItem>
                                            <MenuItem key='ASC' value='ASC'>
                                                {t('notices-information.labels.older')}
                                            </MenuItem>
                                            <MenuItem key='DESC' value='DESC'>
                                                {t('notices-information.labels.last')}
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <Button
                                            fullWidth
                                            disabled={isLoading || isLoadingNextPage}
                                            style={{
                                                height: '100%',
                                            }}
                                            variant='contained'
                                            color='primary'
                                            onClick={() => {
                                                setNoticesInformation([]);
                                                form.handleSubmit();
                                            }}
                                        >
                                            {t(
                                                'notices-information.button.search-notices-information'
                                            ).toUpperCase()}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                    )}
                </Box>
                <Box height='100%' marginY={3}>
                    <Grid container spacing={2}>
                        {!error &&
                            isLoading &&
                            noticesInformation.length >= 0 &&
                            [...Array(9)].map((_, index) => (
                                <Grid
                                    key={`skeleton-card-${index + 1}`}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                >
                                    <CardNoticeInformationSkeleton isLoading />
                                </Grid>
                            ))}
                        {!error &&
                            !isLoading &&
                            noticesInformation.length > 0 &&
                            noticesInformation.map((noticeInformation) => (
                                <Grid
                                    key={`grid-item-card-${noticeInformation.id}`}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={4}
                                >
                                    <CardNoticeInformationView
                                        {...noticeInformation}
                                        key={noticeInformation.id}
                                        handleEditNoticeInformation={(id) => {
                                            editNoticeInformation(id);
                                        }}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    {hasNextPage && isLoadingNextPage && noticesInformation.length > 0 && (
                        <Box
                            sx={{
                                margin: '5px',
                                paddingBottom: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress
                                color='primary'
                                size={28}
                                style={{ marginRight: 5 }}
                            />
                            <Typography color='primary' variant='subtitle2'>
                                {t('notices-information.labels.inform-loading-notices-information')}
                            </Typography>
                        </Box>
                    )}
                    {!isLoading && error && (
                        <Box
                            sx={{
                                height: '320px',
                                border: '1px dashed var(--border-color)',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '1rem',
                            }}
                        >
                            <Typography variant='h5' color='primary'>
                                {t(
                                    'notices-information.info.error-get-list-all-notices-information-title'
                                )}
                            </Typography>
                            <Typography variant='subtitle1' color='textPrimary'>
                                {t(
                                    'notices-information.info.error-get-list-all-notices-information-description'
                                )}
                            </Typography>
                        </Box>
                    )}
                    {!isLoading && !error && noticesInformation.length === 0 && (
                        <Box
                            sx={{
                                height: '320px',
                                border: '1px dashed var(--border-color)',
                                borderRadius: '4px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '1rem',
                            }}
                        >
                            <Typography variant='h5' color='primary'>
                                {t(
                                    'notices-information.info.empty-get-list-all-notices-information-title'
                                )}
                            </Typography>
                            <Typography variant='subtitle1' color='textPrimary'>
                                {t(
                                    'notices-information.info.empty-get-list-all-notices-information-description'
                                )}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Container>
        </PageWrapper>
    );
};

export default memo(SearchNoticesInformationPage);
