import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Typography,
    Chip,
    Box,
    Tooltip,
} from '@material-ui/core';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineDownload, AiOutlineCheck } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
    HiringPlanResponse,
    PcaDashBoardData,
} from 'clients/manager/interfaces/hiring-plans.interfaces';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import { Alert, Pagination, Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { usePageContext } from 'common/components/base/pages/context';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    formatCurrency,
    formatDate,
    timeout,
} from 'common/utils';
import { Modal, ModalPosition } from 'common/components';
import ModalImportPcaItems from 'modules/process/components/process-data/components/modal-import-pca-items';
import { integrationsRequests } from 'clients/manager/integrations.requests';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { CONSTANTS } from 'common/constants';
import ReactDOM from 'react-dom';
import { CheckCircleIcon } from 'common/icons';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import BreadcrumbsComponent from '../../components/breadcrumbs';
import { ChartItemCountAndTotalAmountByPCA } from '../../components/charts';
import {
    Th,
    StyledCard,
    Stack,
    PcaCard,
    BlankArea,
    HeaderPage,
    FooterCard,
    HeaderCard,
    SkeletonBox,
    PcaCardContainer,
    BoxQuantityItens,
    BoxValueEstimated,
    PcaPlanPageWrapper,
    LoadingIndicatorContainer,
    useStyles,
} from './style';
import useHiringPlansContext from '../../hooks/useHiringPlans';
import DialogSyncPcaPncp from './components/dialog-sync-pca-pncp';
import DialogDeleteItemPca from './components/dialog-delete-item-pca';
import DialogNewEditItemPca from './components/dialog-new-edit-item-pca';

type ConsolidateItemsByCategoryType = {
    categoriaItemId?: number;
    quantidadeItens?: number;
    valorTotal?: number;
    categoriaItemNome?: string;
};

const PcaPlanPage = () => {
    const {
        hiringPlansList,
        setHiringPlansList,
        isOpenDialogCrudItemPca,
        isOpenDialogDeleteItemPca,
        onOpenDialogSyncPcaPncp,
        onOpenDialogCrudItemPca,
        onOpenDialogDeleteItemPca,
        hiringPlanItens,
        setHiringPlanItens,
        setItemEditId,
        setItemRemoveId,
        pcaStatusEnum,
        setPcaSyncId,
        listItemCategories,
        isOpenDialogSyncPcaPncp,
    } = useHiringPlansContext();
    const { setTitle } = usePageContext();
    const [isLoading, setIsLoading] = useState(false);
    const [hiringPlan, setHiringPlan] = useState<HiringPlanResponse>();
    const [errorOnGetData, setErrorOnGetData] = useState(false);
    const { t } = useTranslation();
    const { pcaId, organizationUnitId } = useParams<{
        pcaId: string;
        organizationUnitId: string;
    }>();
    const organizationUnitName = hiringPlan
        ? hiringPlan.organizationUnits.organizationUnitName.toUpperCase()
        : '-';
    const inDraft = hiringPlan?.status === pcaStatusEnum.draft;
    const pcaIdNumber = Number.parseInt(pcaId, 10);
    const organizationUnitIdNumber = Number.parseInt(organizationUnitId, 10);
    const isItemInPncp = (itemId: number) =>
        hiringPlanItens
            .filter((item) => item.id === itemId)
            .some((itemFilter) => itemFilter.createdOnPncp === 1);
    const skeletonTableItensArray = Array(5).fill('');
    const [isVisibleModalImport, setIsVisibleModalImport] = useState(false);
    const [isVisibleModalErrors, setIsVisibleModalErrors] = useState(false);
    const BreadcrumbsLinks = [
        { url: '/planos-contratacao', title: t('term.hiring-plans'), active: true },
        {
            url: '/planos-contratacao/:pcaAno',
            title: `PCA ${hiringPlan ? hiringPlan.year : '-'} - ${organizationUnitName}`,
            active: false,
        },
    ];

    const [pcaDashBoardData, setPcaDashBoardData] = useState<PcaDashBoardData | undefined>();

    const getPcaDashboardAmountData = async () => {
        try {
            const result = await hiringPlansRequests.getPcaDashboardAmountData(pcaIdNumber);
            setPcaDashBoardData(result.data[0]);
        } catch (err) {
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.get-dashboard-amount-data-error'),
            });
        }
    };

    const getListItensHiringPlanParams = useMemo(() => ({ pcaId: pcaIdNumber }), [pcaIdNumber]);

    const {
        data: listItensHiringPlanResponse,
        loading: loadingItensHiringPlan,
        refetch,
        ...paginationParams
    } = usePaginatedQuery(
        hiringPlansRequests.getListItensHiringPlan,
        getListItensHiringPlanParams,
        { initialLimit: 20 }
    );

    useEffect(() => {
        setHiringPlanItens(listItensHiringPlanResponse?.data ?? []);
    }, [listItensHiringPlanResponse?.data]);

    const getListAllHiringPlans = async () => {
        setIsLoading(true);
        try {
            const response = await hiringPlansRequests.getAllHiringPlans();
            setHiringPlansList(response);
            setErrorOnGetData(false);
        } catch (err) {
            setErrorOnGetData(true);
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.get-itens-unit-pca-error'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const editHiringPlanUnitItem = () => {
        onOpenDialogCrudItemPca();
    };

    const removeHiringPlanUnitItem = () => {
        onOpenDialogDeleteItemPca();
    };

    const synchronizeHiringPlanWithPncp = () => {
        onOpenDialogSyncPcaPncp();
    };

    const isEnablePcaSyncronization =
        !!pcaDashBoardData?.quantidadeDeItensParaEnvioAoPncp &&
        pcaDashBoardData?.quantidadeDeItensParaEnvioAoPncp > 0;

    useEffect(() => {
        setHiringPlan(hiringPlansList.find((hiringPlan) => hiringPlan.id === pcaIdNumber));
    }, [hiringPlansList]);

    useEffect(() => {
        if (hiringPlanItens.length) getPcaDashboardAmountData();
    }, [hiringPlanItens]);

    const pcaChartBody = useMemo(() => ({ pcaId: pcaIdNumber }), [pcaIdNumber, hiringPlanItens]);
    const { data: pcaChartResponse } = useQueryWithBody(
        hiringPlansRequests.getPcaDataChartCategory,
        pcaChartBody
    );

    const consolidateItemsByCategory: ConsolidateItemsByCategoryType[] =
        pcaChartResponse?.data.map((item) => ({
            categoriaItemId: Number(item.categoria),
            quantidadeItens: item.quantidadeItens,
            valorTotal: item.valorTotal,
            categoriaItemNome: listItemCategories.find(
                (category) => category.id === Number(item.categoria)
            )?.name,
        })) ?? [];

    useEffect(() => {
        setTitle(t('term.hiring-plans'));
        if (hiringPlansList.length === 0) {
            getListAllHiringPlans();
        }

        return () => {
            setPcaSyncId(undefined);
            setItemEditId(undefined);
            setItemRemoveId(undefined);
            setHiringPlanItens([]);
        };
    }, []);

    const getDropZoneSettings = useCallback(
        () =>
            ({
                multiple: false,
                maxSize: CONSTANTS.defaultFileMaxSize,
                accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                onDropRejected: (files) => {
                    addNotificationError({
                        title: t('info.invalid-files'),
                        message: t('info.files-were-not-accepted', {
                            value: String(files.length).padStart(2, '0'),
                        }),
                    });
                    setIsLoading(true);
                },
            } as DropzoneOptions),
        []
    );

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const dropzoneProps = useDropzone(getDropZoneSettings());
    const { acceptedFiles } = dropzoneProps;
    const [isImportingItems, setIsImportingItems] = useState(false);
    const [messageError, setMessageError] = useState('');

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setSelectedFile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    const handleCliclImportItemsPca = () => {
        setIsVisibleModalImport(true);
    };

    const errorMessagesOnImport = (message: string) => {
        setMessageError(message);
    };

    const handleClickImport = async () => {
        if (!selectedFile) {
            return;
        }
        try {
            setIsImportingItems(true);
            await integrationsRequests.importPcaItems({
                file: selectedFile,
                organizationUnitId: Number.parseInt(organizationUnitId, 10),
                pcaId: Number.parseInt(pcaId, 10),
            });

            ReactDOM.unstable_batchedUpdates(() => {
                setIsVisibleModalImport(false);
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('hiring-plans.labels.message-success-import-item-pca'),
                });
                timeout(() => refetch(), 300);
            });
        } catch (error) {
            if (error.response?.data.message) {
                setIsVisibleModalErrors(true);
                const errorMessages = error.response.data.message
                    .map((errorItem: any) => {
                        const line = `<span><strong>Linha: ${errorItem.errorLine}</strong></span> <br/>`;
                        const details = errorItem.errors
                            .map((error) => Object.values(error).join(' - '))
                            .join('; ');
                        return `${line} - Erros: ${details}`;
                    })
                    .join('<br/>');
                errorMessagesOnImport(errorMessages);
                addNotificationApiError(t('hiring-plans.labels.title-modal-import-error'));
            } else {
                addNotificationApiError(error);
            }
        } finally {
            setIsImportingItems(false);
            setSelectedFile(undefined);
        }
    };

    const handleClickCancel = () => {
        setSelectedFile(undefined);
        setIsVisibleModalImport(false);
    };

    const onCloseModalErros = () => {
        setIsVisibleModalErrors(false);
        setMessageError('');
    };

    const styleClass = useStyles();

    return (
        <PcaPlanPageWrapper>
            {isOpenDialogSyncPcaPncp && (
                <DialogSyncPcaPncp
                    countTotalItemsUnit={pcaDashBoardData?.quantitativoDosItens ?? 0}
                    totalItemsValue={pcaDashBoardData?.valorTotal ?? 0}
                />
            )}
            {isOpenDialogCrudItemPca && (
                <DialogNewEditItemPca
                    pcaId={pcaIdNumber}
                    organizationUnitId={organizationUnitIdNumber}
                />
            )}
            {isOpenDialogDeleteItemPca && <DialogDeleteItemPca />}
            <HeaderPage>
                <BreadcrumbsComponent links={BreadcrumbsLinks} />
            </HeaderPage>
            <PcaCard>
                <HeaderCard>
                    {isLoading && hiringPlanItens.length === 0 ? (
                        <Skeleton variant='text' animation='wave' width='60%' height='56px'>
                            <h1> - </h1>
                        </Skeleton>
                    ) : (
                        <h1>
                            PCA {hiringPlan?.year} - {organizationUnitName}
                        </h1>
                    )}
                    <div>
                        {isEnablePcaSyncronization && (
                            <Button
                                disabled={isLoading || loadingItensHiringPlan}
                                color='secondary'
                                size='small'
                                variant='contained'
                                className={styleClass.buttonUpdatePca}
                                onClick={() => {
                                    setPcaSyncId(hiringPlan?.id);
                                    synchronizeHiringPlanWithPncp();
                                }}
                            >
                                {hiringPlan?.pncpLink
                                    ? t('hiring-plans.labels.update-pca')
                                    : t('hiring-plans.labels.synchronize-pca')}
                            </Button>
                        )}
                        {hiringPlan?.pncpLink && (
                            <Button
                                disabled
                                color='secondary'
                                size='small'
                                variant='contained'
                                className={styleClass.buttonStandard}
                                startIcon={<AiOutlineCheck size={20} />}
                            >
                                {t('hiring-plans.labels.synchronized-pca')}
                            </Button>
                        )}
                    </div>
                </HeaderCard>
                <PcaCardContainer>
                    <StyledCard variant='outlined'>
                        <div className={styleClass.divStyleCard}>
                            <Typography color='textPrimary' variant='subtitle2'>
                                {isLoading && hiringPlanItens.length === 0 ? (
                                    <Skeleton />
                                ) : (
                                    <>
                                        {t('hiring-plans.labels.status')}:{' '}
                                        <Chip
                                            color={`${inDraft ? 'primary' : 'secondary'}`}
                                            size='small'
                                            label={inDraft ? t('term.draft') : t('term.concluded')}
                                        />
                                    </>
                                )}
                            </Typography>
                            {isLoading && hiringPlanItens.length === 0 ? (
                                <SkeletonBox variant='rect' animation='wave' />
                            ) : (
                                <BoxValueEstimated variant='outlined'>
                                    <p>{t('hiring-plans.title.value-estimated-title')}</p>
                                    <h2>{formatCurrency(pcaDashBoardData?.valorTotal ?? 0)}</h2>
                                </BoxValueEstimated>
                            )}
                            {isLoading && hiringPlanItens.length === 0 ? (
                                <SkeletonBox variant='rect' animation='wave' />
                            ) : (
                                <BoxQuantityItens variant='outlined'>
                                    <p>{t('hiring-plans.title.quantity-of-pca-items')}</p>
                                    <h2>
                                        {`${pcaDashBoardData?.quantidadeDeItens ?? 0} ${t(
                                            'hiring-plans.title.units'
                                        )}`}
                                    </h2>
                                </BoxQuantityItens>
                            )}
                            {isLoading && hiringPlanItens.length === 0 ? (
                                <SkeletonBox variant='rect' animation='wave' />
                            ) : (
                                <BoxQuantityItens variant='outlined'>
                                    <p>{t('hiring-plans.title.quantitative-of-items')}</p>
                                    <h2>
                                        {`${pcaDashBoardData?.quantitativoDosItens ?? 0} ${t(
                                            'hiring-plans.title.units'
                                        )}`}
                                    </h2>
                                </BoxQuantityItens>
                            )}
                        </div>
                    </StyledCard>
                    <StyledCard variant='outlined' className={styleClass.styleCardSize}>
                        {isLoading && hiringPlanItens.length === 0 ? (
                            <SkeletonBox variant='rect' animation='wave' height='400px' />
                        ) : (
                            <ChartItemCountAndTotalAmountByPCA
                                key='chart-item-count-and-total-amount'
                                consolidatedData={consolidateItemsByCategory}
                            />
                        )}
                    </StyledCard>
                </PcaCardContainer>
                <FooterCard>
                    {isLoading && !hiringPlan ? (
                        <Skeleton variant='text' animation='wave' width='25%' height='18px'>
                            <p> - </p>
                        </Skeleton>
                    ) : (
                        <p>{`${t('hiring-plans.labels.last-created-at')} ${
                            hiringPlan && formatDate(hiringPlan.createdAt, 'L LT')
                        }`}</p>
                    )}
                    <span>
                        <Button
                            style={{ visibility: 'hidden', display: 'none' }}
                            startIcon={<AiOutlineDownload />}
                            variant='outlined'
                            color='secondary'
                            title={t('hiring-plans.title.download-list-of-items')}
                        >
                            {t('hiring-plans.title.list-of-items')}&nbsp;
                            <span style={{ fontSize: '8px' }}>&nbsp;(.csv)</span>
                        </Button>
                    </span>
                </FooterCard>
            </PcaCard>
            <PcaCard style={{ marginTop: '16px' }}>
                <HeaderCard
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <h1>{t('hiring-plans.title.hiring-plan-itens-details')}</h1>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ModalImportPcaItems
                            dropzoneProps={dropzoneProps}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            open={isVisibleModalImport}
                            onClose={() => handleClickCancel()}
                            handleClickImport={handleClickImport}
                            loading={isImportingItems}
                        />
                        <Button
                            disabled={isLoading || loadingItensHiringPlan}
                            color='primary'
                            size='small'
                            variant='contained'
                            className={styleClass.buttonStandard}
                            onClick={handleCliclImportItemsPca}
                        >
                            {t('hiring-plans.labels.import-item-pca')}
                        </Button>
                        <Button
                            disabled={isLoading || loadingItensHiringPlan}
                            color='primary'
                            size='small'
                            variant='contained'
                            className={styleClass.buttonStandard}
                            onClick={onOpenDialogCrudItemPca}
                        >
                            {t('hiring-plans.labels.new-item-pca')}
                        </Button>
                    </div>
                </HeaderCard>
                <div id='modalError'>
                    <Modal
                        position={ModalPosition.center}
                        open={isVisibleModalErrors}
                        onClose={onCloseModalErros}
                        header={<span>{t('hiring-plans.labels.title-modal-import-error')}</span>}
                    >
                        <Box minWidth='400px' maxWidth='500px' minHeight='200px' maxHeight='300px'>
                            <Box width={1}>
                                <Alert severity='error'>
                                    {t('hiring-plans.labels.alert-modal-import-error')}
                                </Alert>
                            </Box>
                            <Box
                                width={1}
                                mt={1}
                                p={2}
                                className={styleClass.boxModalErrorImport}
                                dangerouslySetInnerHTML={{ __html: messageError }}
                            />
                        </Box>
                    </Modal>
                </div>
                <PcaCardContainer>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <Th>#</Th>
                                    <Th>{t('hiring-plans.title.description')}</Th>
                                    <Th style={{ width: '176px' }}>
                                        {t('hiring-plans.labels.last-created-at')}
                                    </Th>
                                    <Th style={{ width: '160px' }}>
                                        {t('hiring-plans.title.quantity-amount')}
                                    </Th>
                                    <Th style={{ width: '208px' }}>
                                        {t('hiring-plans.title.total-value')}
                                    </Th>
                                    <Th style={{ width: '128px' }} align='center' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(isLoading || loadingItensHiringPlan) &&
                                    !errorOnGetData &&
                                    hiringPlanItens.length === 0 &&
                                    skeletonTableItensArray.map((skeletonItem, idx) => (
                                        <TableRow key={`skeleton-row-${idx + 1}`}>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Stack direction='row'>
                                                    <Skeleton variant='circle'>
                                                        <IconButton
                                                            disabled
                                                            size='small'
                                                            color='primary'
                                                        >
                                                            <AiOutlineEdit />
                                                        </IconButton>
                                                    </Skeleton>
                                                    <Skeleton variant='circle'>
                                                        <IconButton
                                                            disabled
                                                            size='small'
                                                            color='primary'
                                                        >
                                                            <AiOutlineDelete />
                                                        </IconButton>
                                                    </Skeleton>
                                                    <Skeleton variant='circle'>
                                                        <IconButton
                                                            disabled
                                                            size='small'
                                                            color='primary'
                                                        >
                                                            <CheckCircleIcon />
                                                        </IconButton>
                                                    </Skeleton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {!errorOnGetData &&
                                    hiringPlanItens &&
                                    hiringPlanItens.length > 0 &&
                                    hiringPlanItens.map((item) => (
                                        <TableRow key={`item-${item.id}`}>
                                            <TableCell>{item.itemNumber}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>
                                                {formatDate(item.createdAt, 'L LT')}
                                            </TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>{formatCurrency(item.totalValue)}</TableCell>
                                            <TableCell
                                                align='center'
                                                className={styleClass.styleTableCellIconsButtons}
                                            >
                                                <Tooltip
                                                    title={t('info.edit-item-pca')}
                                                    aria-label='Info'
                                                >
                                                    <IconButton
                                                        disabled={loadingItensHiringPlan}
                                                        size='medium'
                                                        color='primary'
                                                        onClick={() => {
                                                            setItemEditId(item.id);
                                                            editHiringPlanUnitItem();
                                                        }}
                                                    >
                                                        <AiOutlineEdit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={t('info.delete-item-pca')}
                                                    aria-label='Info'
                                                >
                                                    <IconButton
                                                        disabled={loadingItensHiringPlan}
                                                        size='medium'
                                                        onClick={() => {
                                                            setItemRemoveId(item.id);
                                                            removeHiringPlanUnitItem();
                                                        }}
                                                    >
                                                        <AiOutlineDelete color='var(--danger-color)' />
                                                    </IconButton>
                                                </Tooltip>
                                                {isItemInPncp(item.id) && (
                                                    <Tooltip
                                                        title={t('info.sent-to-pncp')}
                                                        aria-label='Info'
                                                    >
                                                        <span>
                                                            <IconButton disabled size='small'>
                                                                <CheckCircleIcon
                                                                    className={styleClass.checkIcon}
                                                                />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {!isLoading &&
                                    !loadingItensHiringPlan &&
                                    !errorOnGetData &&
                                    hiringPlanItens &&
                                    hiringPlanItens.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={8}>
                                                <BlankArea
                                                    border
                                                    minHeight
                                                    style={{ width: '100%', height: '256px' }}
                                                >
                                                    <h1>
                                                        {t(
                                                            'hiring-plans.info.chart-empty-list-items-pca'
                                                        )}
                                                    </h1>
                                                    <h2>
                                                        {t(
                                                            'hiring-plans.info.table-empty-list-items-pca-instruction',
                                                            {
                                                                button: t(
                                                                    'hiring-plans.labels.new-item-pca'
                                                                ),
                                                            }
                                                        )}
                                                    </h2>
                                                </BlankArea>
                                            </TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                        {(isLoading || loadingItensHiringPlan) &&
                            !errorOnGetData &&
                            hiringPlanItens &&
                            hiringPlanItens.length > 0 && (
                                <LoadingIndicatorContainer style={{ marginTop: '24px' }}>
                                    <CircularProgress color='primary' size={24} />
                                    <Typography color='primary' variant='subtitle2'>
                                        {t('hiring-plans.info.inform-loading-pcas')}
                                    </Typography>
                                </LoadingIndicatorContainer>
                            )}
                        <Pagination
                            className={styleClass.paginationContainer}
                            shape='rounded'
                            count={Math.ceil(paginationParams.totalCount / paginationParams.limit)}
                            page={paginationParams.page + 1}
                            onChange={(_e, page) => paginationParams.setPage(page - 1)}
                        />
                    </TableContainer>
                </PcaCardContainer>
            </PcaCard>
        </PcaPlanPageWrapper>
    );
};

export default memo(PcaPlanPage);
