import { useState, useCallback, useEffect, useMemo } from 'react';
import { Theme } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import { AppBar, Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import { AuctionType } from 'clients/manager/interfaces/auction-notice.interface';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { processUtils } from 'modules/process/process-utils';
import { getAuthUserToken } from 'modules/auth/session.utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import ContentTab from '../content-tab';
import { ProcessTab } from '../../interfaces/process.tabs.interface';
import { useStyles } from './styles';
import { useProcessFormContext } from '../../context/process-form.context';
import { getTabs } from './tabs';

const CustomTab = withStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(14),
        minWidth: '105px',
        minHeight: '70px',

        '.MuiTab-wrapper': {
            flexDirection: 'row',
        },
        '&.MuiSvgIcon-root': {
            marginRight: theme.spacing(1),
        },
    },
}))(Tab);

const GeneralTabs = () => {
    const classes = useStyles();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const { processForm, process } = useProcessFormContext();
    const tabs = useMemo(() => getTabs(process?.id, process), [process?.id, process]);

    const getCurrentTab = (): ProcessTab | undefined => {
        const currentTab = query.get('aba');

        if (!currentTab) {
            return undefined;
        }

        const tab = Object.keys(tabs).find((key) => tabs[key].ref === currentTab);
        if (tab) {
            return tab as unknown as ProcessTab;
        }
    };

    const tabsByStage = useCallback(() => {
        const defaultTabsByStage = {
            [BiddingStageEnum.draft]: [ProcessTab.general, ProcessTab.documents],
            [BiddingStageEnum.published]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.proposals,
                ProcessTab.integrations,
            ],
            [BiddingStageEnum.dispute]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.proposals,
                ProcessTab.authorization,
                ProcessTab.lotsOverview,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
            [BiddingStageEnum.decision]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.proposals,
                ProcessTab.authorization,
                ProcessTab.lotsOverview,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
            [BiddingStageEnum.finishing]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.proposals,
                ProcessTab.authorization,
                ProcessTab.lotsOverview,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
        };

        const isMarketplace =
            processUtils.isMarketplace(processForm?.values) && processForm?.values?.isMarketplace;

        const accreditationTabsByStage = {
            ...defaultTabsByStage,
            [BiddingStageEnum.published]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.authorization,
                isMarketplace ? ProcessTab.orders : ProcessTab.quantitative,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
            [BiddingStageEnum.dispute]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.authorization,
                isMarketplace ? ProcessTab.orders : ProcessTab.quantitative,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
            [BiddingStageEnum.decision]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.authorization,
                isMarketplace ? ProcessTab.orders : ProcessTab.quantitative,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
            [BiddingStageEnum.finishing]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.messages,
                ProcessTab.solicitations,
                ProcessTab.authorization,
                isMarketplace ? ProcessTab.orders : ProcessTab.quantitative,
                ProcessTab.contracts,
                ProcessTab.integrations,
            ],
        };

        const unenforceabilityTabs = {
            ...defaultTabsByStage,
            [BiddingStageEnum.draft]: [
                ProcessTab.general,
                ProcessTab.documents,
                ProcessTab.proposals,
                ProcessTab.authorization,
            ],
        };

        const tabsMapper = [
            {
                type: AuctionType.accreditation,
                tabs: accreditationTabsByStage,
            },
            {
                type: AuctionType.unenforceability,
                tabs: unenforceabilityTabs,
            },
        ];

        const biddingStageId = processForm?.values.biddingStageId;
        const processType = processForm?.values.auctionType;

        if (!biddingStageId) {
            return [ProcessTab.general];
        }

        const foundMapper = tabsMapper.find((item) => item.type === processType);
        if (foundMapper) {
            return foundMapper.tabs[biddingStageId];
        }

        return defaultTabsByStage[biddingStageId];
    }, [
        processForm?.values.auctionType,
        processForm?.values.biddingStageId,
        processForm?.values?.legalSupportId,
        processForm?.values?.isMarketplace,
    ]);

    const [currentTab, setCurrentTab] = useState<ProcessTab>(getCurrentTab() ?? ProcessTab.general);
    const [tabsOrder, setTabsOrder] = useState<number[]>([...tabsByStage()]);

    const handleChange = (_: React.ChangeEvent<{}>, tab: number) => {
        setCurrentTab(tabsOrder[tab]);
    };

    useEffect(() => {
        setTabsOrder(tabsByStage());
    }, [
        processForm?.values.auctionType,
        processForm?.values.biddingStageId,
        processForm?.values?.legalSupportId,
        processForm?.values?.isMarketplace,
    ]);

    const sortedTabs = useCallback(
        () =>
            tabsOrder.reduce(
                (acc, tab) => ({
                    [tab]: tabs[tab],
                    ...acc,
                }),
                {}
            ),
        [tabsOrder]
    );

    const typeAccess = getAuthUserToken()?.typeAccess;

    return (
        <Paper className={classes.root}>
            <AppBar position='static' color='default' elevation={1}>
                <Tabs
                    classes={{ scroller: classes.scroller }}
                    // eslint-disable-next-line eqeqeq
                    value={tabsOrder.findIndex((index) => index == currentTab)}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    indicatorColor='secondary'
                    textColor='secondary'
                >
                    {Object.keys(sortedTabs()).map((key) => {
                        const tab = tabs[key];
                        return (
                            <CustomTab
                                key={key}
                                title={tab.title}
                                icon={tab.icon}
                                label={tab.label}
                                id={`scrollable-force-tab-${key}`}
                                aria-controls={`scrollable-force-tabpanel-${key}`}
                                onClick={() => {
                                    if (tab.onClick) {
                                        return tab.onClick();
                                    }

                                    // mantem dado na rota sem atualizar a página
                                    const url = new URL(window.location as any);
                                    url.searchParams.set('aba', tab.ref);
                                    window.history.pushState(null, '', url.toString());
                                }}
                            />
                        );
                    })}
                </Tabs>
            </AppBar>
            {(!tabs[currentTab]?.onClick || typeAccess === TypeAccess.management) && (
                <ContentTab tab={currentTab} />
            )}
        </Paper>
    );
};

export default GeneralTabs;
