import { RangeRegionParticipation } from 'common/enums/range-regional-participation.enum';

export const labelTypeRegionalBenefit = {
    [RangeRegionParticipation.without]: 'info.not-apply',
    [RangeRegionParticipation.regionalPreference]: 'term.regional-preference',
    [RangeRegionParticipation.localPreference]: 'term.local-preference',
    [RangeRegionParticipation.regionalExclusivity]: 'term.regional-exclusivity',
    [RangeRegionParticipation.localExclusivity]: 'term.local-exclusivity',
};

export const labelWarningRegionalBenefit = {
    [RangeRegionParticipation.without]: '',
    [RangeRegionParticipation.regionalPreference]: '',
    [RangeRegionParticipation.localPreference]: '',
    [RangeRegionParticipation.regionalExclusivity]: 'warning.process.regional-exclusivity',
    [RangeRegionParticipation.localExclusivity]: 'warning.process.regional-exclusivity',
};

export const rangeRegionParticipation = {
    without: RangeRegionParticipation.without,
    regionalPreference: RangeRegionParticipation.regionalPreference,
    localPreference: RangeRegionParticipation.localPreference,
    regionalExclusivity: RangeRegionParticipation.regionalExclusivity,
    localExclusivity: RangeRegionParticipation.localExclusivity,
};
