import {
    AppBar,
    Badge,
    Box,
    IconButton,
    Toolbar,
    Typography,
    Menu,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import { Notifications, NotificationsOutlined, Menu as MenuIcon } from '@material-ui/icons';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    featureFlagEnabled,
    getBackofficeRedirectUrl,
    getUrlOAuth2,
    timeout,
    apiRedirectTo,
} from 'common/utils';
import { CONSTANTS } from 'common/constants';
import { usePageContext } from 'common/components/base/pages/context';
import { notificationsRequests } from 'clients/manager/notifications.requests';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { AppState } from 'store';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { usePlatformContext } from 'routes/platform.context';
import { CgMenuGridO } from 'react-icons/cg';
import { TypeAccess } from 'common/interfaces/user.interface';
import { Platform, platformsRequests } from 'clients/manager/platforms.requests';
import { AuthenticatedHeaderProps } from './props';
import { Container, useStyles } from './styles';
import EnvironmentsModal from './components/environments-modal';
import { useMenuHeaderContext } from './menu-header.context';

const AuthenticatedHeader: FC<AuthenticatedHeaderProps> = ({ onExpandMenu }) => {
    const classes = useStyles();
    const { notificationsCountContext } = useMenuHeaderContext();
    const { title } = usePageContext();
    const { platform } = usePlatformContext();
    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const loginBaseUrl = getBackofficeRedirectUrl(true);

    const resize = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, [windowWidth]);

    UseWindowEvent('resize', () => resize(), [resize]);

    const [notificationsCount, setNotificationsCount] = useState(0);
    const [platformContext, setPlatformContext] = useState<Platform[]>([]);

    const getNotificationsCount = async () => {
        if (!authUser) {
            return;
        }

        const response = await notificationsRequests.getNotificationsCount();
        setNotificationsCount(response?.data?.count ?? 0);
    };

    const getPlatformContext = async () => {
        const response = await platformsRequests.getPlatformContext();
        setPlatformContext(response?.data ?? []);
    };

    const getPlatformDetails = (platforms: Platform[], platformName: string) => {
        const platform = platforms.find((p) => p.name === platformName);
        if (!platform) return null;
        return {
            icon: (
                <img
                    src={platform.urlOriginalIcon}
                    alt={`${platform.name} logo`}
                    style={{
                        marginRight: '10px',
                        width: '38px',
                        height: '38px',
                    }}
                />
            ),
            description: platform.description,
            redirectUrl: platform.apps?.php,
        };
    };

    const handleRedirectToPhpPainel = (backofficeRedirectUrl: string) => {
        //todo:
        // const typeAcess = (authUserTokenData?.typeAccess || 'citizen') as TypeAccess;
        // const companyId = authUserTokenData?.organizationId || authUserTokenData?.providerId || 0;
        // const url = getUrlOAuth2(typeAcess, `${companyId}`, '/painel', redirectToUrl);

        return apiRedirectTo({
            backofficeRedirectUrl,
            redirectUrl: '/painel',
            loginBaseUrl,
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const canSeeMenuPlatformContext =
        featureFlagEnabled('habilitarComprasCompartilhadas') &&
        authUserTokenData?.typeAccess === TypeAccess.organization;

    useEffect(() => {
        timeout(() => getNotificationsCount(), 5_000);
    }, [authUser]);

    useEffect(() => {
        setNotificationsCount(notificationsCountContext);
    }, [notificationsCountContext]);

    useEffect(() => {
        getPlatformContext();
    }, []);

    const mobile = windowWidth < CONSTANTS.mobileWidth;

    return (
        <Container>
            <div className={classes.root}>
                <AppBar position='static' className={classes.appBar}>
                    <Toolbar style={{ height: '100%' }}>
                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            alignItems='center'
                        >
                            {mobile && (
                                <IconButton
                                    aria-label='menu'
                                    color='primary'
                                    className='extra-icons-header'
                                    onClick={onExpandMenu}
                                    style={{ margin: '0 16px 0 0', padding: 0 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            )}

                            <Typography
                                id='titleApplication'
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                variant='h6'
                                className={classes.title}
                            >
                                {title}
                            </Typography>
                            <Box display='flex'>
                                <Box display='flex' alignItems='center'>
                                    {!mobile &&
                                        !!platform?.generalSettings?.general
                                            ?.showProductMarketing && (
                                            <IconButton id='beamerButton'>
                                                <HiOutlineSpeakerphone
                                                    color='var(--icon-color)'
                                                    size={26}
                                                />
                                            </IconButton>
                                        )}
                                    {!mobile && (
                                        <>
                                            <IconButton
                                                aria-label='notificações'
                                                onClick={() => history.push('/notificacoes')}
                                            >
                                                <Badge
                                                    badgeContent={notificationsCount}
                                                    color='error'
                                                >
                                                    {notificationsCount > 0 ? (
                                                        <Notifications />
                                                    ) : (
                                                        <NotificationsOutlined />
                                                    )}
                                                </Badge>
                                            </IconButton>
                                        </>
                                    )}
                                    {!mobile && canSeeMenuPlatformContext && (
                                        <>
                                            <IconButton aria-label='menu' onClick={handleClick}>
                                                <Badge badgeContent='Novo' color='primary'>
                                                    <CgMenuGridO />
                                                </Badge>
                                            </IconButton>

                                            <Menu
                                                id='expandable-menu'
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                {(() => {
                                                    const pipegovDetails = getPlatformDetails(
                                                        platformContext,
                                                        'pipegov'
                                                    );
                                                    const licitarDigitalDetails =
                                                        getPlatformDetails(
                                                            platformContext,
                                                            'licitardigital'
                                                        );

                                                    return (
                                                        <>
                                                            {pipegovDetails && (
                                                                <Tooltip
                                                                    title={
                                                                        pipegovDetails.description
                                                                    }
                                                                    key='pipegov'
                                                                >
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            if (
                                                                                pipegovDetails.redirectUrl
                                                                            ) {
                                                                                handleRedirectToPhpPainel(
                                                                                    pipegovDetails.redirectUrl
                                                                                );
                                                                            }
                                                                            handleClose();
                                                                        }}
                                                                    >
                                                                        {pipegovDetails.icon}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                maxWidth: '200px',
                                                                                display:
                                                                                    '-webkit-box',
                                                                                WebkitLineClamp: 2,
                                                                                WebkitBoxOrient:
                                                                                    'vertical',
                                                                                overflow: 'hidden',
                                                                                textOverflow:
                                                                                    'ellipsis',
                                                                                whiteSpace:
                                                                                    'normal',
                                                                            }}
                                                                        >
                                                                            {
                                                                                pipegovDetails.description
                                                                            }
                                                                        </span>
                                                                    </MenuItem>
                                                                </Tooltip>
                                                            )}

                                                            {licitarDigitalDetails && (
                                                                <Tooltip
                                                                    title={
                                                                        licitarDigitalDetails.description
                                                                    }
                                                                    key='licitardigital'
                                                                >
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            if (
                                                                                licitarDigitalDetails.redirectUrl
                                                                            ) {
                                                                                handleRedirectToPhpPainel(
                                                                                    licitarDigitalDetails.redirectUrl
                                                                                );
                                                                            }
                                                                            handleClose();
                                                                        }}
                                                                    >
                                                                        {licitarDigitalDetails.icon}
                                                                        <span
                                                                            style={{
                                                                                fontSize: 14,
                                                                                maxWidth: '200px',
                                                                                display:
                                                                                    '-webkit-box',
                                                                                WebkitLineClamp: 2,
                                                                                WebkitBoxOrient:
                                                                                    'vertical',
                                                                                overflow: 'hidden',
                                                                                textOverflow:
                                                                                    'ellipsis',
                                                                                whiteSpace:
                                                                                    'normal',
                                                                            }}
                                                                        >
                                                                            {
                                                                                licitarDigitalDetails.description
                                                                            }
                                                                        </span>
                                                                    </MenuItem>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    );
                                                })()}
                                            </Menu>
                                        </>
                                    )}
                                    <EnvironmentsModal />
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </div>
        </Container>
    );
};

export default AuthenticatedHeader;
