import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'common/components/base/breadcrumb';
import MarketplaceItemCard from 'modules/marketplace/components/marketplace-item-card';

interface JudgmentPageViewProps {
    order?: OrderResponse;
    loading?: boolean;
    isJudged?: boolean;
}

const JudgmentPageView: React.FC<JudgmentPageViewProps> = ({
    order,
    loading,
    isJudged = false,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='pedidos/julgamento'
                paths={{
                    pedidos: {
                        value: t('term.orders'),
                        onClick: () => history.replace('/mercado/fornecedor/pedidos'),
                    },
                    julgamento: { value: t('term.judgment'), disabled: true },
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {loading && <MarketplaceItemCard loading />}
                    {order?.items.map((orderItem) => (
                        <MarketplaceItemCard
                            key={orderItem.id}
                            orderId={order.id}
                            quantity={orderItem.itemAmount}
                            type='judgment'
                            accessType='provider'
                            item={orderItem.item as any}
                            orderItemStatus={orderItem.status}
                            isJudged={isJudged}
                        />
                    ))}
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default JudgmentPageView;
