import { doRequest, managerApiInstance } from 'clients/api';

import { PlainGenericResponse } from '../interfaces/paginated.interface';
import { ListEnvironmentsWithPermission } from './interfaces/environments-user-permission.interface';

const BASE_PATH = '/requestAccess';

const requestAccessRequests = {
    listEnvironmentsWithAuthorizedAccess: async () =>
        doRequest<PlainGenericResponse<ListEnvironmentsWithPermission>>(
            managerApiInstance.post(`${BASE_PATH}/listEnvironmentsWithAuthorizedAccess`)
        ),
};

export { requestAccessRequests };
