import React, { useEffect } from 'react';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { disableField } from 'modules/process/process-utils';
import { Autocomplete } from '@material-ui/lab';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { AuctionLegalSupport } from '../../../../clients/manager/interfaces/legall-support.interface';
import { auctionLegalSupportRequests } from '../../../../clients/manager/auction-legal-support.requests';
import { useProcessFormContext } from '../../context/process-form.context';

interface SelectLegalAssistanceProps {
    onChange: (value: number) => void;
}

const SelectLegalAssistance: React.FC<SelectLegalAssistanceProps> = ({ onChange }) => {
    const { t } = useTranslation();
    const { processForm, process } = useProcessFormContext();

    const { data: legalSupportListResponse, loading } = useQueryWithBody(
        auctionLegalSupportRequests.listLegalSupport,
        processForm?.values.auctionType,
        { skip: !processForm?.values.auctionType }
    );

    useEffect(() => {
        if (!legalSupportListResponse?.data || !processForm?.values?.legalSupportId) {
            return;
        }

        const currentLegalSupport = legalSupportListResponse?.data.find(
            (legalSupport) => legalSupport.id === processForm?.values.legalSupportId
        );

        if (!currentLegalSupport) {
            processForm?.setFieldValue('legalSupportId', undefined);
        }
    }, [
        legalSupportListResponse,
        processForm?.values?.legalSupportId,
        processForm?.values.auctionType,
        processForm?.values.ruleId,
    ]);

    const value = legalSupportListResponse?.data.find(
        (legalSupport) => legalSupport.id === processForm?.values.legalSupportId
    );

    return (
        <Autocomplete
            key='select-legal-assistance'
            options={legalSupportListResponse?.data ?? []}
            loading={loading}
            disabled={
                disableField('legalSupportId', process, !legalSupportListResponse?.data.length) ||
                loading
            }
            defaultValue={value || null}
            value={value || null}
            getOptionSelected={(option: AuctionLegalSupport, value: AuctionLegalSupport) => {
                return option.id === value.id;
            }}
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
                <TextField {...params} label={t('term.legal-support')} variant='outlined' />
            )}
            onChange={(_, legalSupportId) => {
                if (legalSupportId) {
                    onChange(legalSupportId.id);
                }
            }}
        />
    );
};

export default SelectLegalAssistance;
