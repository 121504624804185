import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { SendEmailToChangeProviderEmailBody } from '../interfaces/send-email-change-provider.interface';
import { PlainGenericResponse } from '../../../../clients/interfaces/paginated.interface';

const BASE_PATH = '/verification-email';

const verificationEmailRequests = {
    sendEmailToChangeProviderEmailByAdmin: async (body: SendEmailToChangeProviderEmailBody) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${BASE_PATH}/send-email-change-provider-email-by-admin`, body)
        ),
};

export { verificationEmailRequests };
