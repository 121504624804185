import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    CreateFinancialData,
    userFinancialDataRequests,
} from 'clients/manager/user-financial-data.requests';
import { addNotificationSuccess, addNotificationApiError } from 'common/utils';
import { AccountType, MethodType } from 'clients/manager/interfaces/user-financial-data.interface';
import banks from '../provider-bank-data/banks.json';
import ModalProviderBankDataView from './modal-provider-bank-data-view';

interface ModalProviderBankDataProps {
    onClose: () => void;
    idProvider?: number;
}

const ModalProviderBankData: FC<ModalProviderBankDataProps> = ({ onClose, idProvider }) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const createFinancialData = async (values: CreateFinancialData) => {
        try {
            await userFinancialDataRequests.createFinancialData(values);

            addNotificationSuccess();
            onClose();
        } catch (err) {
            addNotificationApiError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getValidationSchema = () =>
        Yup.object({
            methodType: Yup.string().required(t('required-field', { ns: 'validation' })),
            accountAgency: Yup.string().required(t('required-field', { ns: 'validation' })),
            accountNumber: Yup.string().required(t('required-field', { ns: 'validation' })),
            bankCode: Yup.string().required(t('required-field', { ns: 'validation' })),
            accountType: Yup.string().required(t('required-field', { ns: 'validation' })),
        });

    const form = useFormik<CreateFinancialData>({
        initialValues: {
            methodType: MethodType.BANK_ACCOUNT,
            accountAgency: '',
            accountNumber: '',
            accountType: AccountType.CC,
            bankCode: '',
            docNumberReceiver: '',
            pixValue: null,
            pixType: null,
            providerId: idProvider,
        },
        validationSchema: getValidationSchema(),
        onSubmit: async (values) => {
            setIsSubmitting(true);
            await createFinancialData(values);
        },
    });

    const getDefaultValue = () => {
        if (form?.values.bankCode) {
            return banks.find((bank) => bank.code === form?.values.bankCode);
        }

        return undefined;
    };

    return (
        <ModalProviderBankDataView
            t={t}
            onClose={onClose}
            form={form}
            banks={banks}
            getDefaultValue={getDefaultValue}
            isSubmitting={isSubmitting}
        />
    );
};

export default ModalProviderBankData;
