import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ProviderValueStatus } from 'common/enums/provider-values-status.enum';
import { formatCurrency } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useStyles } from './order-items-style';
import ProviderValueStatusChip from './components/provider-value-status-chip';

interface OrderItemTable {
    id: number;
    itemAmount: number;
    itemUnitMeasurement: string;
    itemName: string;
    provider?: {
        id: number;
        value?: number;
        status?: ProviderValueStatus;
    };
}

interface OrderItemsTableViewProps {
    items: OrderItemTable[];
}

const OrderItemsTableView: React.FC<OrderItemsTableViewProps> = ({ items }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <Table className={styles.table}>
            <TableHead>
                <TableRow>
                    <TableCell align='left' className={styles.headTableCell}>
                        {t('marketplace.provider-orders.item-name')}
                    </TableCell>
                    <TableCell align='right' className={styles.headTableCell}>
                        {t('term.amount')}
                    </TableCell>
                    <TableCell align='left' className={styles.headTableCell}>
                        {t('term.unit-measurement')}
                    </TableCell>
                    <TableCell align='left' className={styles.headTableCell}>
                        {t('marketplace.provider-orders.judgment-status')}
                    </TableCell>
                    <TableCell align='left' className={styles.headTableCell}>
                        {t('marketplace.provider-orders.my-proposal')}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((orderItem) => (
                    <TableRow hover>
                        <TableCell className={styles.largeBodyTableCell}>
                            {orderItem.itemName}
                        </TableCell>
                        <TableCell align='right'>{orderItem.itemAmount}</TableCell>
                        <TableCell className={styles.smallBodyTableCell}>
                            {orderItem.itemUnitMeasurement ?? '--'}
                        </TableCell>
                        <TableCell>
                            {orderItem?.provider?.status ? (
                                <ProviderValueStatusChip status={orderItem?.provider?.status} />
                            ) : (
                                '--'
                            )}
                        </TableCell>
                        <TableCell>
                            {orderItem?.provider?.value
                                ? formatCurrency(orderItem.provider.value)
                                : '--'}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default OrderItemsTableView;
