import { FC, useCallback, useState } from 'react';
import {
    Box,
    Chip,
    Grid,
    Tooltip,
    Card,
    Checkbox,
    FormControlLabel,
    IconButton,
    Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ReactDOM from 'react-dom';
import { HiOutlineUserAdd } from 'react-icons/hi';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { debounce } from 'lodash';
import { CONSTANTS } from 'common/constants';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import { usePlatformContext } from 'routes/platform.context';
import {
    addNotificationError,
    addNotificationApiError,
    addNotificationSuccess,
    formatDate,
} from 'common/utils';
import Markdown from 'common/components/markdown';
import { AttachmentOutlinedIcon } from 'common/icons';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { TypeAccess } from 'common/interfaces/user.interface';
import { HistoryOutlined, Settings } from '@material-ui/icons';
import { getChipSegmentLabel } from 'common/utils/getters/get-chip-segment-label.utils';
import { useParams } from 'react-router';
import mime from 'mime';
import {
    DocumentTypeDocument,
    DocumentTypesProvidersHistoric,
    EvaluatingStatusType,
    SegmentationTypeEnum,
} from '../../../../interfaces/document-type-provider.interface';
import UploadFile from '../../../upload-file';
import { ProviderDocumentItemProps } from './props';
import { useStyles } from './styles';
import ChipStatusView from './chip-status-view';
import { DocumentTypeProviderSegment } from '../../interfaces';
import { useProviderUpdateRequestWarningContext } from '../../../provider-update-request-warning/context';
import ModalEditDocuments from '../modal-edit-documents';
import ModalHistoricDocuments from '../modal-historic-documents';

const ProviderDocumentItem: FC<ProviderDocumentItemProps> = ({
    document,
    disabled = false,
    setDoNewRequest = undefined,
}) => {
    const [providerDocument, setProviderDocument] = useState<DocumentTypeProviderSegment>(document);
    const classes = useStyles();
    const { t } = useTranslation();
    const { platform } = usePlatformContext();
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const [selectedUpdateRequest, setSelectedUpdateRequest] = useState<boolean>();
    const [openHistoricModal, setOpenHistoricModal] = useState<boolean>(false);
    const [documentsHistoric, setDocumentsHistoric] = useState<DocumentTypesProvidersHistoric[]>(
        []
    );
    const maxUploadSize = Number(platform?.fileSettings?.maxUploadSizeProvider) * 1024 * 1024;
    const { idProvider } = useParams<{ idProvider: string }>();
    const hasTimeUpdate = document?.dateTimeUpdate;
    const [updatedDate, setUpdateDate] = useState<string | null>(
        hasTimeUpdate ? `${formatDate(hasTimeUpdate, 'DD/MM/YY HH:mm')}` : null
    );

    const handleChangeFile = async (file: File, providerId?: number) => {
        if (!file) {
            addNotificationError({
                title: t('error.to-update-files-title'),
                message: t('error.to-update-files-message'),
            });

            return;
        }

        try {
            const supplyCategoryIdSingle =
                document.segmentationType === SegmentationTypeEnum.single
                    ? document?.segmentsId?.[0]
                    : undefined;

            const isManagement = authUserTokenData?.typeAccess === TypeAccess.management;
            const dataUpload = await documentTypeProviderRequests.uploadDocumentTypeProvider(
                file,
                providerDocument.id,
                supplyCategoryIdSingle,
                isManagement ? providerId : undefined
            );

            const newFileUpload =
                dataUpload.data as DocumentTypeProviderSegment['documentTypesProviders'];
            const { fileName, dateTimeUpdate, url } = newFileUpload;

            setUpdateDate(`${formatDate(dateTimeUpdate, 'DD/MM/YY HH:mm')}`);
            ReactDOM.unstable_batchedUpdates(() => {
                setProviderDocument((prevDocument) => ({
                    ...prevDocument,
                    dateTimeUpdate,
                    documentTypesProviders: {
                        ...newFileUpload,
                        evaluatingStatus: isManagement
                            ? EvaluatingStatusType.APROVADO
                            : EvaluatingStatusType.AGUARDANDO_AVALIACAO,
                        fileName,
                        url,
                    },
                }));
                setForceHasChanges(true);
                addNotificationSuccess();
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const toogleDeclarationState = async (declarationAccepted: boolean) => {
        try {
            await documentTypeProviderRequests.createDocumentTypeProviderDeclaration(
                declarationAccepted,
                providerDocument.id as number
            );

            ReactDOM.unstable_batchedUpdates(() => {
                setProviderDocument((prevDocument) => ({
                    ...prevDocument,
                    documentTypesProviders: {
                        ...prevDocument?.documentTypesProviders,
                        declarationAccepted,
                        evaluatingStatus: EvaluatingStatusType.AGUARDANDO_AVALIACAO,
                    },
                }));
                setForceHasChanges(true);
                addNotificationSuccess();
            });

            if (setDoNewRequest !== undefined) setDoNewRequest(true);
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const listDocumentsHistoric = async () => {
        try {
            const listDocumentsHistoric =
                await documentTypeProviderRequests.listDocumentTypeProviderHistory(
                    providerDocument.documentTypesProviders.id as number
                );
            ReactDOM.unstable_batchedUpdates(() => {
                setDocumentsHistoric(listDocumentsHistoric);
                setOpenHistoricModal(true);
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const getDropZoneSettings = useCallback(
        () =>
            ({
                disabled: disabled,
                multiple: false,
                maxSize: platform?.fileSettings?.maxUploadSizeProvider
                    ? maxUploadSize ?? CONSTANTS.defaultFileMaxSize
                    : CONSTANTS.defaultFileMaxSize,
                accept:
                    platform?.fileSettings?.extensions.map((extension) =>
                        mime.getType(extension)
                    ) ?? '*',
                onDrop(acceptedFiles) {
                    if (authUserTokenData?.typeAccess === TypeAccess.management) {
                        handleChangeFile(acceptedFiles[0], Number(idProvider));
                    } else handleChangeFile(acceptedFiles[0]);
                },
            } as DropzoneOptions),
        [platform]
    );
    const dropzoneProps = useDropzone(getDropZoneSettings());

    const getIconButton = (evaluatingStatusType: EvaluatingStatusType) => {
        let iconType: string;

        if (evaluatingStatusType === EvaluatingStatusType.REPROVADO) {
            iconType = 'rejectedIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.AGUARDANDO_ATI_VCTE) {
            iconType = 'pendingIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.NAO_ENVIADO) {
            iconType = 'notSendIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.AGUARDANDO_AVALIACAO) {
            iconType = 'awaitAvaliationIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.EXPIRADO) {
            iconType = 'expiredIcon';
        } else {
            iconType = 'approvedIcon';
        }

        return (
            <IconButton classes={{ root: classes[iconType] }}>
                <Tooltip
                    title={
                        <span className={classes.justificationTooltip}>
                            {providerDocument.documentTypesProviders.justificationOfFailure}
                        </span>
                    }
                >
                    <InfoIcon />
                </Tooltip>
            </IconButton>
        );
    };

    const handleValuesUpdate = useCallback((updatedValues: any) => {
        setProviderDocument((prevDocument) => ({
            ...prevDocument,
            documentTypesProviders: {
                ...prevDocument.documentTypesProviders,
                ...updatedValues,
                fileName: prevDocument.documentTypesProviders.fileName,
                url: prevDocument.documentTypesProviders.url,
            },
        }));
    }, []);

    return (
        <Box mt={1}>
            {selectedUpdateRequest && (
                <ModalEditDocuments
                    documentName={providerDocument.name}
                    DocumentTypesProviders={providerDocument.documentTypesProviders}
                    onClose={() => setSelectedUpdateRequest(undefined)}
                    onUpdateValues={handleValuesUpdate}
                />
            )}
            {openHistoricModal && (
                <ModalHistoricDocuments
                    DocumentTypesProviders={documentsHistoric}
                    onClose={() => setOpenHistoricModal(false)}
                />
            )}
            <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={4} md={3} className={classes.gridItemColumn}>
                        <Box display='flex' alignItems='center'>
                            <Typography gutterBottom variant='subtitle2'>
                                {Boolean(providerDocument.canItContainPersonalData) && (
                                    <HiOutlineUserAdd
                                        title={t('common.provider-documents.private-document')}
                                        className={classes.hiOutlineUserAddIcon}
                                    />
                                )}
                                {providerDocument.name}
                            </Typography>
                            {providerDocument.documentRequired ? (
                                <Typography
                                    gutterBottom
                                    color='error'
                                    className={classes.typographyGutterBottom}
                                >
                                    {` (${t('term.required')})`}
                                </Typography>
                            ) : null}
                        </Box>
                        {providerDocument.typeDocument === DocumentTypeDocument.declaration ? (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={
                                                providerDocument?.documentTypesProviders
                                                    ?.declarationAccepted
                                            }
                                        />
                                    }
                                    label={<Markdown value={providerDocument?.description} />}
                                    onChange={debounce(
                                        (event: React.ChangeEvent<any>) =>
                                            toogleDeclarationState(event.target.checked),
                                        500
                                    )}
                                />
                                {providerDocument?.documentTypesProviders?.declarationAccepted ? (
                                    <a title={t('info.declaration-date')}>
                                        <Typography className={classes.updatedCheck}>
                                            {`${formatDate(
                                                document.dateTimeUpdate,
                                                'DD/MM/YY HH:mm'
                                            )}`}
                                        </Typography>
                                    </a>
                                ) : null}
                            </>
                        ) : (
                            <Markdown value={providerDocument?.description} />
                        )}
                    </Grid>
                    <>
                        <Grid item xs={2} md={1} className={classes.gridItem}>
                            {providerDocument.documentTypesProviders.url &&
                            providerDocument.typeDocument !== DocumentTypeDocument.declaration ? (
                                <Grid item xs={3} md={2} className={classes.gridItemColumn}>
                                    <a
                                        href={providerDocument.documentTypesProviders.url}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={classes.viewFileLinkSimple}
                                    >
                                        <Typography
                                            variant='body2'
                                            align='center'
                                            className={classes.typoAttachment}
                                        >
                                            <AttachmentOutlinedIcon
                                                className={classes.palettePrimaryDark}
                                                fontSize='small'
                                            />
                                            <Typography className={classes.viewFileText}>
                                                {t('info.view-file')}
                                            </Typography>
                                        </Typography>
                                    </a>
                                    <a title={t('info.uploaded-file')}>
                                        <Typography className={classes.updatedFile}>
                                            {updatedDate}
                                        </Typography>
                                    </a>
                                </Grid>
                            ) : (
                                <Typography variant='body2' />
                            )}
                        </Grid>
                    </>
                    {providerDocument.typeDocument === DocumentTypeDocument.upload ? (
                        <>
                            <Grid item xs={3} md={2} className={classes.gridItem}>
                                <Box mr={2} ml={2} width={1}>
                                    <UploadFile
                                        className={classes.uploadFile}
                                        disabled={disabled}
                                        {...dropzoneProps}
                                        label={
                                            <Box className={classes.gridItem}>
                                                <CloudUploadIcon
                                                    className={classes.cloudUploadIcon}
                                                />
                                                <Typography variant='body2'>
                                                    {t('term.upload')}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={3} md={1} className={classes.gridItem}>
                                {providerDocument.documentTypesProviders.id && (
                                    <Typography
                                        variant='body2'
                                        align='center'
                                        className={classes.historyText}
                                        onClick={listDocumentsHistoric}
                                    >
                                        <HistoryOutlined
                                            className={classes.palettePrimaryDark}
                                            fontSize='small'
                                        />
                                        {t('info.view-historic')}
                                    </Typography>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={3} md={1} />
                    )}
                    {providerDocument.typeDocument === DocumentTypeDocument.reference ? (
                        <>
                            <Grid item xs={5} md={3} className={classes.gridItem}>
                                <IconButton size='small' className={classes.marginRightIcon}>
                                    <InfoIcon className={classes.palettePrimaryLight} />
                                </IconButton>
                                <Typography
                                    variant='body2'
                                    className={classes.referenceDescription}
                                >
                                    {t('info.provider-document-reference-description')}
                                </Typography>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item md={2} xs={12} className={classes.flexEndAlignCenter}>
                        {providerDocument.typeDocument !== DocumentTypeDocument.reference ? (
                            <>
                                {providerDocument.documentTypesProviders.justificationOfFailure &&
                                    getIconButton(
                                        providerDocument.documentTypesProviders.evaluatingStatus
                                    )}
                                <ChipStatusView
                                    status={
                                        providerDocument.documentTypesProviders.evaluatingStatus
                                    }
                                    dueDate={providerDocument?.documentTypesProviders.dueDate}
                                />
                            </>
                        ) : null}
                        {providerDocument.typeDocument === DocumentTypeDocument.reference && (
                            <Chip
                                size='small'
                                className={classes.chipReference}
                                label={t('info.provider-document-reference')}
                                color='primary'
                            />
                        )}
                    </Grid>
                    <Grid item md={1} xs={12} className={classes.flexEndAlignCenter}>
                        {authUserTokenData?.typeAccess === TypeAccess.management &&
                            providerDocument.documentTypesProviders.id && (
                                <IconButton
                                    onClick={() => setSelectedUpdateRequest(true)}
                                    disabled={disabled}
                                >
                                    <Settings />
                                </IconButton>
                            )}
                    </Grid>
                </Grid>
                {providerDocument.segments?.length ? (
                    <Box mt={1}>
                        <Grid container className={classes.gridItem}>
                            {providerDocument.segments.map((segment) => (
                                <Chip
                                    size='small'
                                    className={classes.chipSegment}
                                    label={getChipSegmentLabel(segment)}
                                    color='primary'
                                />
                            ))}
                        </Grid>
                    </Box>
                ) : null}
            </Card>
        </Box>
    );
};

export default ProviderDocumentItem;
