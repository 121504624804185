import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Buffer } from 'buffer';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getEnviromentToAccessType, setDefaultFavicon, setPageTitle } from 'common/utils';
import { providerRequests } from 'clients/manager/provider.requests';
import { useMUIThemeContext } from 'common/theme';
import { setEnableHttpOnlyToken } from 'modules/auth/session.utils';
import { Platform, platformsRequests } from '../clients/manager/platforms.requests';
import { AppState } from '../store';
import { TypeAccess, TypeUser } from '../common/interfaces/user.interface';

export type PlatformContextType = {
    platform: Platform | undefined;
    openConfirmationTerms: [boolean, Dispatch<SetStateAction<boolean>>];
    isOauth2: boolean;
    setIsOauth2: Dispatch<SetStateAction<boolean>>;
};

export const PlatformContext = createContext<PlatformContextType>({} as PlatformContextType);

export const usePlatformContext = () => useContext(PlatformContext);

export const PlatformContextProvider = ({ children }: { children: ReactNode }) => {
    const history = useHistory();

    const { setPlatformColor } = useMUIThemeContext();

    const openConfirmationTerms = useState(false);
    const [isOauth2, setIsOauth2] = useState(false);
    const [platform, setPlatform] = useState<Platform | undefined>(undefined);
    const { authUser, currentAccessType, authUserTokenData } = useSelector(
        (state: AppState) => state.authUserState
    );

    useEffect(() => {
        const getProvider = async () => {
            const provider = await providerRequests.getProvider();
            return provider;
        };

        if (authUserTokenData?.typeAccess === TypeAccess.provider) {
            getProvider().then((provider) => {
                const checkTermsUnaccepted =
                    provider.versionOfAcceptedTerms !==
                    platform?.generalSettings.general.versionOfAcceptedTerms;

                if (checkTermsUnaccepted) openConfirmationTerms[1](true);
            });
        }
    }, [platform, authUserTokenData]);

    const getPlatform = async () => {
        try {
            const response = await platformsRequests.getPlatformByOrigin();
            const platform = response.data;
            setPlatform(platform);
            setEnableHttpOnlyToken(platform?.generalSettings?.general?.enableHttpOnlyToken);

            if (platform) {
                setPageTitle(platform.description, undefined, true);
                setDefaultFavicon(platform.urlOriginalIcon);

                if (platform.themeColors) setPlatformColor(platform.themeColors);
            }
        } catch (error) {
            console.error(error);
            history.push('/erro-sistema');
        }
    };

    useEffect(() => {
        getPlatform();
    }, []);

    if (!platform) {
        return null;
    }

    const analyticsId = platform.analyticsCode
        ? Buffer.from(platform.analyticsCode, 'base64').toString('ascii')
        : undefined;

    const isVisibleChatSupport = () => {
        switch (currentAccessType) {
            case TypeUser.citizen:
                return platform?.generalSettings?.general?.showChatSupportCitizen;

            case TypeUser.provider:
                return platform?.generalSettings?.general?.showChatSupportProvider;

            case TypeUser.organization:
                return platform?.generalSettings?.general?.showChatSupportOrganization;

            default:
                return platform?.generalSettings?.general?.showChatSuppertPublicArea;
        }
    };

    const isVisibleBeamer =
        !!platform?.generalSettings?.general?.showProductMarketing && !!authUser?.id;

    const filterTypeAccess = authUser?.typeUser
        ?.split(',')
        .map((item) => getEnviromentToAccessType(item as TypeUser))
        .join(';');

    return (
        <PlatformContext.Provider
            value={{
                platform,
                openConfirmationTerms,
                isOauth2,
                setIsOauth2,
            }}
        >
            <>
                <HelmetProvider>
                    {analyticsId && (
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
                        />
                    )}
                    {analyticsId && (
                        <script>
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', ${analyticsId});
                            `}
                        </script>
                    )}
                    {isVisibleBeamer && (
                        <script>
                            {`
                                var beamer_config = {
                                    product_id: 'EveHJyDq27667',
                                    selector: '#beamerButton',
                                    language: '${authUser?.language}', 
                                    user_id: '${authUser?.id}',
                                    user_firstname: '${authUser?.name}', 
                                    user_email: '${authUser?.email}',
                                    filter: '${filterTypeAccess}',
                                    user_created_at: '${authUser?.dateTimeInsert}'
                                };
                            `}
                        </script>
                    )}
                    {isVisibleBeamer && (
                        <script
                            type='text/javascript'
                            src='https://app.getbeamer.com/js/beamer-embed.js'
                            defer
                        />
                    )}
                    {isVisibleChatSupport() && (
                        <script>
                            {`(function (o, c, t, a, d, e, s, k) {
                                    o.octadesk = o.octadesk || {};
                                    o.octadesk.chatOptions = {
                                        subDomain: a,
                                        showButton: d,
                                        openOnMessage: e,
                                        hide: s
                                    };
                                    var bd = c.getElementsByTagName("body")[0];
                                    var sc = c.createElement("script");
                                    sc.async = 1;
                                    sc.src = t;
                                    bd.appendChild(sc);
                                    })(window, document, 'https://cdn.octadesk.com/embed.js', 'o151325-958',  'true', 'true', 'false');`}
                        </script>
                    )}
                    {isVisibleChatSupport() && !!authUser?.name && !!authUser?.email && (
                        <script>
                            {`window.addEventListener('onOctaChatReady', function () {
                                    octadesk.chat.login({
                                        name: '${authUser.name} - ${platform.description}', 
                                        email:'${authUser.email}' 
                                    });
                                });`}
                        </script>
                    )}
                </HelmetProvider>
                {children}
            </>
        </PlatformContext.Provider>
    );
};
