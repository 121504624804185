/* eslint-disable @typescript-eslint/naming-convention */
import {
    HiringPlanItensResponse,
    HiringPlanResponse,
} from 'clients/manager/interfaces/hiring-plans.interfaces';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import _ from 'lodash';
import { useState, memo, Dispatch, createContext, SetStateAction, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

type YearPcaItem = { title: string };
type OrderByType = string;
type InstitutionsPcaItem = { id: string; nome: string };
enum idItemCategoryEnum {
    'material' = 1,
    'servico' = 1,
    'obras' = 3,
    'servicosEngenharia' = 4,
    'solucoesTic' = 5,
    'locacaoImoveis' = 6,
    'alienacaoConcessaoPermissao' = 7,
    'obrasServicosEngenharia' = 8,
}
export enum pcaStatusEnum {
    'draft' = 'draft',
    'done' = 'done',
    'deleted' = 'deleted',
}

interface HiringPlansContextProps {
    idItemCategoryEnum: typeof idItemCategoryEnum;
    pcaStatusEnum: typeof pcaStatusEnum;
    yearPcaSelected: Array<YearPcaItem>;
    setYearPcaSelected: Dispatch<SetStateAction<YearPcaItem[]>>;
    orderByType: OrderByType;
    setOrderByType: Dispatch<SetStateAction<OrderByType>>;
    hiringPlansList: HiringPlanResponse[];
    setHiringPlansList: Dispatch<SetStateAction<HiringPlanResponse[]>>;
    organizationUnitsList: OrganizationUnit[];
    setOrganizationUnitsList: Dispatch<SetStateAction<OrganizationUnit[]>>;
    institutionsPcaSelected: Array<InstitutionsPcaItem>;
    setInstitutionsPcaSelected: Dispatch<SetStateAction<InstitutionsPcaItem[]>>;
    isOpenDialogCrudPca: boolean;
    onOpenModalCrudPca: () => void;
    onCloseModalCrudPca: () => void;
    isOpenDialogSyncPcaPncp: boolean;
    onOpenDialogSyncPcaPncp: () => void;
    onCloseDialogSyncPcaPncp: () => void;
    isOpenDialogCrudItemPca: boolean;
    onOpenDialogCrudItemPca: () => void;
    onCloseDialogCrudItemPca: () => void;
    isOpenDialogDeleteItemPca: boolean;
    onOpenDialogDeleteItemPca: () => void;
    onCloseDialogDeleteItemPca: () => void;
    listPcaStatus: Array<{ id: string; name: string }>;
    listItemCatalog: Array<{ id: number; name: string }>;
    listItemCategories: Array<{ id: number; name: string }>;
    listItemCatalogClassification: Array<{ id: number; name: string }>;
    hiringPlanItens: Array<HiringPlanItensResponse>;
    setHiringPlanItens: Dispatch<SetStateAction<HiringPlanItensResponse[]>>;
    itemEditId: number | undefined;
    setItemEditId: Dispatch<SetStateAction<number | undefined>>;
    itemRemoveId: number | undefined;
    setItemRemoveId: Dispatch<SetStateAction<number | undefined>>;
    pcaSyncId: number | undefined;
    setPcaSyncId: Dispatch<SetStateAction<number | undefined>>;
}

export const HiringPlansContext = createContext({} as HiringPlansContextProps);

const HiringPlansProvider = ({ children }: PropsWithChildren<any>) => {
    const { t } = useTranslation();
    const [isOpenDialogCrudPca, setIsOpenDialogCrudPca] = useState<boolean>(false);
    const [isOpenDialogCrudItemPca, setIsOpenDialogCrudItemPca] = useState<boolean>(false);
    const [isOpenDialogDeleteItemPca, setIsOpenDialogDeleteItemPca] = useState<boolean>(false);
    const [isOpenDialogSyncPcaPncp, setIsOpenDialogSyncPcaPncp] = useState<boolean>(false);
    const [orderByType, setOrderByType] = useState<OrderByType>('-data');
    const [yearPcaSelected, setYearPcaSelected] = useState<YearPcaItem[]>([]);
    const [hiringPlansList, setHiringPlansList] = useState<HiringPlanResponse[]>([]);
    const [hiringPlanItens, setHiringPlanItens] = useState<Array<HiringPlanItensResponse>>([]);
    const [pcaSyncId, setPcaSyncId] = useState<number>();
    const [itemEditId, setItemEditId] = useState<number>();
    const [itemRemoveId, setItemRemoveId] = useState<number>();
    const [organizationUnitsList, setOrganizationUnitsList] = useState<OrganizationUnit[]>([]);
    const [institutionsPcaSelected, setInstitutionsPcaSelected] = useState<InstitutionsPcaItem[]>(
        []
    );

    const listPcaStatus = [
        { id: 'draft', name: t('term.draft') },
        { id: 'done', name: t('hiring-plans.title.concluded-title') },
    ];

    const listItemCategories = [
        { id: 1, name: t('hiring-plans.title.material') },
        { id: 2, name: t('hiring-plans.title.service') },
        { id: 3, name: t('hiring-plans.title.construction') },
        { id: 4, name: t('hiring-plans.title.engineering-services') },
        { id: 5, name: t('hiring-plans.title.tic-solutions') },
        { id: 6, name: t('hiring-plans.title.lease-real-estate') },
        { id: 7, name: t('hiring-plans.title.alienation-concession-permission') },
        { id: 8, name: t('hiring-plans.title.works-services-engineering') },
    ];

    const listItemCatalog = [
        { id: 1, name: t('hiring-plans.title.national-catalog-of-goods-services') },
        { id: 2, name: t('hiring-plans.title.catalog-others') },
    ];

    const listItemCatalogClassification = [
        { id: 1, name: t('hiring-plans.title.catalog-classification-material') },
        { id: 2, name: t('hiring-plans.title.catalog-classification-services') },
    ];

    const onCloseModalCrudPca = () => {
        setIsOpenDialogCrudPca(false);
    };

    const onOpenModalCrudPca = () => {
        setIsOpenDialogCrudPca(true);
    };

    const onCloseDialogSyncPcaPncp = () => {
        setIsOpenDialogSyncPcaPncp(false);
    };

    const onOpenDialogSyncPcaPncp = () => {
        setIsOpenDialogSyncPcaPncp(true);
    };

    const onCloseDialogCrudItemPca = () => {
        setIsOpenDialogCrudItemPca(false);
    };

    const onOpenDialogCrudItemPca = () => {
        setIsOpenDialogCrudItemPca(true);
    };

    const onCloseDialogDeleteItemPca = () => {
        setIsOpenDialogDeleteItemPca(false);
    };

    const onOpenDialogDeleteItemPca = () => {
        setIsOpenDialogDeleteItemPca(true);
    };

    return (
        <HiringPlansContext.Provider
            value={{
                idItemCategoryEnum,
                pcaStatusEnum,
                orderByType,
                setOrderByType,
                yearPcaSelected,
                setYearPcaSelected,
                institutionsPcaSelected,
                setInstitutionsPcaSelected,
                isOpenDialogCrudPca,
                isOpenDialogSyncPcaPncp,
                onOpenModalCrudPca,
                onCloseModalCrudPca,
                onCloseDialogSyncPcaPncp,
                onOpenDialogSyncPcaPncp,
                hiringPlansList: _.orderBy(hiringPlansList, ['year'], ['desc']),
                setHiringPlansList,
                organizationUnitsList,
                setOrganizationUnitsList,
                isOpenDialogCrudItemPca,
                onOpenDialogCrudItemPca,
                onCloseDialogCrudItemPca,
                listPcaStatus,
                listItemCatalog,
                listItemCategories,
                listItemCatalogClassification,
                hiringPlanItens,
                setHiringPlanItens,
                itemEditId,
                setItemEditId,
                isOpenDialogDeleteItemPca,
                onOpenDialogDeleteItemPca,
                onCloseDialogDeleteItemPca,
                itemRemoveId,
                setItemRemoveId,
                pcaSyncId,
                setPcaSyncId,
            }}
        >
            {children}
        </HiringPlansContext.Provider>
    );
};

export default memo(HiringPlansProvider);
