import { Auth } from 'aws-amplify';
import { removeAuthToken } from 'modules/auth/session.utils';

export enum CognitoHostedUIIdentityProvider {
    Cognito = 'COGNITO',
    Google = 'Google',
    Facebook = 'Facebook',
    Amazon = 'LoginWithAmazon',
    Apple = 'SignInWithApple',
    Microsoft = 'Microsoft',
    Corporativo = 'Corporativo',
}

export const amplifyConfig = {
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
};

export const logout = async () => {
    await Auth.signOut();
    await removeAuthToken();
    window.location.href = '/login';
};

export const providerSignIn = (provider: CognitoHostedUIIdentityProvider) => {
    Auth.federatedSignIn({
        provider: provider as any,
    });
};
