import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    MenuItem,
    FormControlLabel,
    Radio as RadioButton,
    Typography,
    Button,
} from '@material-ui/core';
import { TextField, Modal, ModalPosition } from 'common/components';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import UploadFileIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { orderBy } from 'lodash';
import { Alert } from '@material-ui/lab';
import DocumentIcon from '@material-ui/icons/PostAdd';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import {
    ProcessDocumentsSteps,
    ProcessDocumentsStepsStatus,
    ProcessDocumentsStepsType,
    ProcessDocumentsStepsVisibility,
} from 'clients/manager/interfaces/process-documents-steps.interface';
import { processDocumentStepsRequests } from 'clients/manager/documents/process-documents-steps.requests';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    addNotificationWarning,
} from 'common/utils';
import { pncpRequests } from 'clients/manager/pncp.requests';
import LoadingButton from 'common/components/loading-button';
import { documentActions } from 'modules/process/document-utils';
import { UploadDocument, documentRequests } from 'clients/manager/documents/document.requests';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { useProcessDocumentsStepsContext } from '../../../../context/process-document-steps';
import { useStyles, ContentScroll } from './styles';
import { ModalCreateDocumentProps } from './props';
import FormDefaultSigners from './form-default-signers';
import UploadTypeView from './upload-type-view';

type ItemOptions = 0 | 1;

const iconStyles = {
    largeIcon: {
        width: 40,
        height: 40,
    },
};

const ModalCreateDocument: FC<ModalCreateDocumentProps> = ({
    onClose,
    process,
    processDocumentsStep,
    processDocumentsSteps,
    onUpdateProcessDocumentStep,
}) => {
    const [itemSelected, setItemSelected] = useState<ItemOptions>(0);
    const { setCheckProcess } = useProcessFormContext();
    const { setProcessDocumentsSteps } = useProcessDocumentsStepsContext();
    const [creatingDocumentStep, setCreatingDocumentStep] = useState<boolean>(false);
    const [sendingToPncp, setSendingToPncp] = useState(false);
    const generatedDocuments = processDocumentsStep?.generatedDocuments;
    const { processForm } = useProcessFormContext();

    const classes = useStyles();
    const { t } = useTranslation();

    const documentTypes: { [key in DocumentTemplateType]: string } = {
        [DocumentTemplateType.contract]: `${t('term.contract')} / ${t(
            'term.ata-price-registration'
        )}`,
        [DocumentTemplateType.receipt]: t('term.receipt'),
        [DocumentTemplateType.auction]: `${t('term.public-notices')} / ${t('term.basic-project')}`,
        [DocumentTemplateType.protocol]: 'Ata',
        [DocumentTemplateType.referenceTerms]: t('term.terms-of-reference'),
        [DocumentTemplateType.hiringNotice]: t('term.direct-hiring-notice'),
        [DocumentTemplateType.contractDraft]: t('term.contract-draft'),
        [DocumentTemplateType.preliminaryDesign]: t('term.preliminary-design'),
        [DocumentTemplateType.preliminaryTechnicalStudy]: t('term.preliminary-technical-study'),
        [DocumentTemplateType.executiveProject]: t('term.executive-project'),
        [DocumentTemplateType.riskMap]: t('term.risk-map'),
        [DocumentTemplateType.dod]: t('term.dod'),
        [DocumentTemplateType.terminationNotice]: t('term.termination-notice'),
        [DocumentTemplateType.addendum]: t('term.addendum'),
        [DocumentTemplateType.apostilmentTerm]: t('term.apostilment-term'),
        // [DocumentTemplateType.commitmentNote]: t('term.commitment-note'),
        [DocumentTemplateType.minutesOfPriceRegistration]: t('term.minutes-of-price-registration'),
        [DocumentTemplateType.actAuthorizingDirectContracting]: t(
            'term.act-authorizing-direct-contracting'
        ),
        [DocumentTemplateType.others]: t('term.others'),
    };

    const onCreatedDocumentProcessStep = (
        processDocumentStep: ProcessDocumentsSteps,
        createVirtualGenerated: boolean
    ) => {
        setProcessDocumentsSteps((prevState) =>
            orderBy(
                [
                    {
                        ...processDocumentStep,
                        totalGenerated: 0,
                        totalGeneratedFromUpload:
                            createVirtualGenerated &&
                            processDocumentStep.type === ProcessDocumentsStepsType.upload
                                ? 1
                                : 0,
                    },
                    ...prevState,
                ],
                ['order', 'createdAt'],
                ['desc', 'desc']
            )
        );
        onClose();
    };

    const onUpdatedDocumentProcessStep = (newProcessDocumentStep: ProcessDocumentsSteps) => {
        setProcessDocumentsSteps((prevState) => [
            ...prevState.map((processDocumentStep) => {
                if (newProcessDocumentStep._id === processDocumentStep._id) {
                    return {
                        ...processDocumentStep,
                        ...newProcessDocumentStep,
                    };
                }
                return processDocumentStep;
            }),
        ]);
        onClose();
    };

    const getInitialValues = () => {
        if (processDocumentsStep) {
            return {
                auctionId: process.id,
                name: processDocumentsStep.name,
                order: processDocumentsStep.order,
                signers: processDocumentsStep.signers,
                status: processDocumentsStep.status,
                visibility: processDocumentsStep.visibility,
                type: processDocumentsStep.type,
                description: processDocumentsStep.description,
                documentType: processDocumentsStep.documentType,
            };
        }
        return {
            auctionId: process.id,
            name: '',
            order: 1,
            signers: [],
            status: ProcessDocumentsStepsStatus.draft,
            visibility: ProcessDocumentsStepsVisibility.public,
            type: ProcessDocumentsStepsType.document,
            description: '',
            documentType: undefined,
        };
    };

    const getValidationSchema = () => {
        const objValidation: { [key: string]: any } = {
            name: Yup.string().required(t('required-field', { ns: 'validation' })),
            signers: Yup.array().of(
                Yup.object().shape({
                    userId: Yup.number(),
                    name: Yup.string().required(t('required-field', { ns: 'validation' })),
                    email: Yup.string().required(t('required-field', { ns: 'validation' })),
                })
            ),
        };

        if (processDocumentsStep) {
            return Yup.object(objValidation);
        }

        objValidation.documentType = Yup.string().required(
            t('required-field', { ns: 'validation' })
        );
        objValidation.type = Yup.string().required(t('required-field', { ns: 'validation' }));

        if (itemSelected === 0) {
            objValidation.documentTemplateId = Yup.string().required(
                t('required-field', { ns: 'validation' })
            );
        }

        return Yup.object(objValidation);
    };

    const form = useFormik<Omit<ProcessDocumentsSteps, '_id'>>({
        initialValues: getInitialValues(),
        validationSchema: getValidationSchema(),
        enableReinitialize: true,
        onSubmit: (values) => {
            if (values.type === ProcessDocumentsStepsType.document && !processDocumentsStep) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return createDocumentProcessStep(values);
            }

            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return updateDocumentProcessStep(values);
        },
    });

    useEffect(() => {
        if (processDocumentsStep) return;
        if (itemSelected === 0) {
            form.setValues({
                ...form.values,
                type: ProcessDocumentsStepsType.document,
            });
        } else if (itemSelected === 1) {
            form.setValues({
                ...form.values,
                documentTemplateId: undefined,
                type: ProcessDocumentsStepsType.upload,
            });
        }
    }, [itemSelected]);

    const handleListItemClick = (item: ItemOptions) => setItemSelected(item);

    // O campo de upload é desabilitado para o tipo contrato, pois o arquivo deve ser
    // enviado na aba de contratos do processo
    const documentsTypeContract = [
        DocumentTemplateType.contract,
        DocumentTemplateType.terminationNotice,
        DocumentTemplateType.addendum,
        DocumentTemplateType.apostilmentTerm,
    ];
    const disabledUploadDocument = !!(
        form.values.documentType &&
        documentsTypeContract.includes(form.values.documentType) &&
        form.values.type === ProcessDocumentsStepsType.upload &&
        !processDocumentsStep
    );

    // Só pode ter uma etapa do tipo contrato no processo
    const disableCreateContractStep =
        !!processDocumentsSteps?.find(
            (step) => step.documentType === DocumentTemplateType.contract
        ) &&
        form.values.documentType === DocumentTemplateType.contract &&
        !processDocumentsStep;

    const createDocumentProcessStep = async (formValues: Omit<ProcessDocumentsSteps, '_id'>) => {
        if (disableCreateContractStep) {
            return;
        }
        try {
            setCreatingDocumentStep(true);
            const response = await processDocumentStepsRequests.createProcessStep(formValues);
            ReactDOM.unstable_batchedUpdates(() => {
                setCreatingDocumentStep(false);
                onCreatedDocumentProcessStep(response.data, !disabledUploadDocument);
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('info.action-success'),
                });
            });
        } catch (error) {
            setCreatingDocumentStep(false);
            addNotificationApiError(error);
        }
    };

    const onlyCreateStep = async () => {
        const response = await processDocumentStepsRequests.createProcessStep(form.values);
        const stepCreate = {
            ...response.data,
            status:
                form.values.type === ProcessDocumentsStepsType.upload
                    ? ProcessDocumentsStepsStatus.generated
                    : response.data.status,
        };
        if (!stepCreate) {
            return;
        }
        setCreatingDocumentStep(false);
        onCreatedDocumentProcessStep(stepCreate, !disabledUploadDocument);
    };

    const createDocumentAndUpload = async (file: File) => {
        const formValues = form.values;
        const body: UploadDocument = {
            processId: process.id,
            name: formValues.name,
            signers: formValues.signers,
            step: {
                order: 1,
                visibility: formValues.visibility,
                description: formValues.description,
                documentType: formValues.documentType ?? DocumentTemplateType.others,
            },
        };
        const response = await documentRequests.uploadDocument(body, file);
        const stepCreate = response.data.step;
        setCheckProcess('upload-document');
        if (
            formValues.type === ProcessDocumentsStepsType.upload &&
            processDocumentsStep &&
            onUpdateProcessDocumentStep
        ) {
            onUpdateProcessDocumentStep({
                ...processDocumentsStep,
                status: stepCreate.status,
            });
        }
        setCreatingDocumentStep(false);
        onCreatedDocumentProcessStep(stepCreate, !disabledUploadDocument);
    };

    const createDocumentProcessStepFromFile = async (file?: File) => {
        if (disableCreateContractStep) {
            return;
        }

        try {
            setCreatingDocumentStep(true);
            if (disabledUploadDocument) {
                return await onlyCreateStep();
            }
            if (file) {
                return await createDocumentAndUpload(file);
            }
        } catch (error) {
            setCreatingDocumentStep(false);
            addNotificationApiError(error);
        }
    };

    const isEditingStep = !!processDocumentsStep;

    const updateDocumentProcessStep = async (formValues: Omit<ProcessDocumentsSteps, '_id'>) => {
        if (!processDocumentsStep) {
            return;
        }
        const newProcessDocumentsStep = {
            ...processDocumentsStep,
            ...formValues,
        };

        try {
            setCreatingDocumentStep(true);
            await processDocumentStepsRequests.updateProcessStep(
                processDocumentsStep._id,
                newProcessDocumentsStep
            );
            if (newProcessDocumentsStep?.generatedDocuments?.length) {
                newProcessDocumentsStep.generatedDocuments =
                    newProcessDocumentsStep.generatedDocuments.map((generated) => ({
                        ...generated,
                        signers: newProcessDocumentsStep.signers,
                    }));
            }
            ReactDOM.unstable_batchedUpdates(() => {
                setCreatingDocumentStep(false);
                onUpdatedDocumentProcessStep(newProcessDocumentsStep);
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('info.action-success'),
                });
            });
        } catch (error) {
            setCreatingDocumentStep(false);
            addNotificationWarning({
                title: t('term.error'),
                message: t('info.error-create-process-step'),
            });
        }
    };
    const canSendPncp = documentActions.canSendPncp(process, generatedDocuments?.[0]);
    const showPncpLink = documentActions.showPncpLink(process, generatedDocuments?.[0]);

    const pncpLink =
        !!generatedDocuments && !!generatedDocuments[0] && !!generatedDocuments[0].pncpLink
            ? generatedDocuments[0].pncpLink
            : '';

    const handleSendToPncp = async () => {
        if (
            !canSendPncp ||
            !generatedDocuments ||
            !generatedDocuments[0] ||
            !generatedDocuments[0]._id
        ) {
            return addNotificationError({
                title: t('term.err'),
                message: 'Não foi possível enviar para o PNCP, documento gerado não encontrado',
            });
        }

        try {
            setSendingToPncp(true);
            const { data } = await pncpRequests.doSyncFile({
                params: {
                    processId: process.id,
                    generatedDocumentId: generatedDocuments[0]._id,
                },
            });

            if (onUpdateProcessDocumentStep) {
                onUpdateProcessDocumentStep({
                    ...processDocumentsStep,
                    status: data.stepStatus,
                    generatedDocuments: [{ ...generatedDocuments?.[0], pncpLink: data.pncpLink }],
                });
            }
            addNotificationSuccess({
                message: 'Sincronizado ao PNCP com sucesso',
                title: t('term.success'),
            });
            window.open(data.pncpLink, '_blank');
        } catch (error) {
            addNotificationError({
                message: error?.response?.data?.message || 'Erro ao enviar para o PNCP',
                title: t('term.error'),
            });
        }
        setSendingToPncp(false);
    };

    const handleDocTypeWhenItsPriceRegistration = (documentType: string): boolean => {
        const cantShowDocumentType =
            documentType === DocumentTemplateType.addendum.toString() ||
            documentType === DocumentTemplateType.apostilmentTerm.toString() ||
            documentType === DocumentTemplateType.terminationNotice.toString();
        return !(cantShowDocumentType && Boolean(processForm?.values.itsPriceRegistration));
    };

    const documentTypesFiltered = Object.keys(documentTypes).filter((key) =>
        handleDocTypeWhenItsPriceRegistration(key)
    );

    const buttonsActions = () => (
        <Grid>
            {showPncpLink && (
                <Button
                    size='small'
                    onClick={() => {
                        window.open(pncpLink, '_blank');
                    }}
                    variant='text'
                    style={{ marginLeft: 16 }}
                >
                    {t('info.pncp-link')}
                </Button>
            )}

            {canSendPncp && (
                <LoadingButton
                    size='small'
                    variant='contained'
                    style={{ marginLeft: 16 }}
                    onClick={handleSendToPncp}
                    {...(sendingToPncp
                        ? {
                              loading: {
                                  text: `${t('term.sending')}..`,
                              },
                          }
                        : {})}
                >
                    {generatedDocuments?.[0]?.pncpLink
                        ? t('info.resend-to-pncp')
                        : t('info.send-to-pncp')}
                </LoadingButton>
            )}
        </Grid>
    );

    return (
        <Modal
            position={ModalPosition.right}
            open
            onClose={onClose}
            header={
                isEditingStep ? (
                    <span>{processDocumentsStep.name}</span>
                ) : (
                    <span>{t('term.new-document')}</span>
                )
            }
        >
            <ContentScroll>
                <Box p={1} className={classes.root}>
                    {processDocumentsStep && (
                        <Box mb={2}>
                            <Alert severity='warning'>
                                <Typography variant='body2'>
                                    {t('info.cant-change-uploaded-step')}
                                </Typography>
                            </Alert>
                        </Box>
                    )}
                    {processDocumentsStep?.documentType === DocumentTemplateType.contract && (
                        <Box mb={2}>
                            <Alert severity='error'>
                                <Typography variant='body2'>
                                    {t('info.cant-change-uploaded-step-contract')}
                                </Typography>
                            </Alert>
                        </Box>
                    )}
                    <Grid container>
                        {!isEditingStep && (
                            <Box width={1} mb={2} display='flex' justifyContent='center'>
                                <List
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: 0,
                                    }}
                                    component='nav'
                                    aria-label='main'
                                >
                                    <ListItem
                                        disabled={isEditingStep}
                                        className={classes.listItem}
                                        style={{ marginRight: 16 }}
                                        button
                                        selected={itemSelected === 0}
                                        onClick={() => handleListItemClick(0)}
                                    >
                                        <ListItemIcon className='leftIcon'>
                                            <SvgIcon
                                                style={iconStyles.largeIcon}
                                                component={DocumentIcon}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t('term.create-document')}
                                            secondary={t(
                                                'process.components.use-generater-documents'
                                            )}
                                        />
                                        <ListItemIcon className='checkIcon'>
                                            {itemSelected === 0 && (
                                                <CheckCircleRoundedIcon color='secondary' />
                                            )}
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        disabled={isEditingStep}
                                        className={classes.listItem}
                                        button
                                        selected={itemSelected === 1}
                                        onClick={() => handleListItemClick(1)}
                                    >
                                        <ListItemIcon className='leftIcon'>
                                            <SvgIcon
                                                style={iconStyles.largeIcon}
                                                component={UploadFileIcon}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t('term.do-upload')}
                                            secondary={t('process.components.upload-document-pdf')}
                                        />
                                        <ListItemIcon className='checkIcon'>
                                            {itemSelected === 1 && (
                                                <CheckCircleRoundedIcon color='secondary' />
                                            )}
                                        </ListItemIcon>
                                    </ListItem>
                                </List>
                            </Box>
                        )}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <TextField
                                label={t('term.document-name')}
                                name='name'
                                variant='outlined'
                                autoFocus
                                fullWidth
                                onChange={form.handleChange}
                                value={form.values.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                label={t('term.document-type')}
                                name='documentType'
                                variant='outlined'
                                disabled={isEditingStep}
                                fullWidth
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    form.setFieldValue('documentType', Number(event.target.value));
                                }}
                                defaultValue={form.values.documentType}
                                value={form.values.documentType}
                                select
                            >
                                {documentTypesFiltered.map((key) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {documentTypes[key]}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                checked={
                                    form.values.visibility ===
                                    ProcessDocumentsStepsVisibility.private
                                }
                                control={<RadioButton />}
                                label={t('term.private')}
                                onChange={() =>
                                    form.setFieldValue(
                                        'visibility',
                                        ProcessDocumentsStepsVisibility.private
                                    )
                                }
                            />
                            <FormControlLabel
                                checked={
                                    form.values.visibility ===
                                    ProcessDocumentsStepsVisibility.public
                                }
                                control={<RadioButton />}
                                label={t('term.public')}
                                onChange={() =>
                                    form.setFieldValue(
                                        'visibility',
                                        ProcessDocumentsStepsVisibility.public
                                    )
                                }
                            />
                            <Box mt={1}>
                                <Alert severity='info'>
                                    <Typography variant='body2'>{t('info.public-step')}</Typography>
                                </Alert>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FormDefaultSigners
                                signers={form.values.signers}
                                processDocumentsStep={processDocumentsStep}
                                onChange={(signers) => form.setFieldValue('signers', signers)}
                            />
                        </Grid>
                        {!disableCreateContractStep && disabledUploadDocument && (
                            <Grid item xs={12}>
                                <Box>
                                    <Alert severity='warning'>
                                        <Typography variant='body2'>
                                            {t('info.contract-step-generate-document')}
                                        </Typography>
                                    </Alert>
                                </Box>
                            </Grid>
                        )}
                        {disableCreateContractStep && (
                            <Grid item xs={12}>
                                <Box>
                                    <Alert severity='error'>
                                        <Typography variant='body2'>
                                            {t('info.already-exists-contract-step')}
                                        </Typography>
                                    </Alert>
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <UploadTypeView
                                disabledContinue={
                                    !form.dirty ||
                                    !!Object.keys(form.errors).length ||
                                    !!disableCreateContractStep
                                }
                                viewSelected={itemSelected}
                                creatingDocumentStep={creatingDocumentStep}
                                isEditingStep={!!processDocumentsStep}
                                disabledUploadDocument={disabledUploadDocument}
                                createDocumentProcessStepFromFile={
                                    createDocumentProcessStepFromFile
                                }
                                {...form}
                            />
                        </Grid>
                        {buttonsActions()}
                    </Grid>
                </Box>
            </ContentScroll>
        </Modal>
    );
};

export default ModalCreateDocument;
