import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles<Theme>(() => ({
    container: {
        display: 'flex',
        width: '100%',
        borderTop: '1px solid #C2C2CC',
    },
    divider: {
        height: 'auto',
        width: '1px',
        backgroundColor: '#C2C2CC',
    },
    buttonText: {
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(16),
        fontWeight: 500,
        lineHeight: formatPxToRem(24),
        color: 'inherit',
    },
    cancelButton: {
        flex: 1,
        textTransform: 'none',
        color: '#CC2A18',
        padding: formatPxToRem(24),
    },
    confirmButton: {
        flex: 1,
        textTransform: 'none',
        padding: formatPxToRem(24),
    },
}));

export default useStyles;
