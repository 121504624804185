import BiddingRoutes from 'modules/bidding/routes';
import NoticesInformationRoutes from 'modules/notices-information/routes';
import HiringPlansRoutes from 'modules/hiring-plans/routes';
import CitizenDashboardRoutes from 'modules/citizen-dashboard/router';
import ContractsRoutes from 'modules/contracts/routes';
import DocumentTypesRoutes from 'modules/document-types/routes';
import EditorRoutes from 'modules/editor/routes';
import HolidaysRoutes from 'modules/holidays/routes';
import NotificationRoutes from 'modules/notification/routes';
import OrganizationRoutes from 'modules/organization/routes';
import ProviderRoutes from 'modules/providers/routes';
import SignUpRoutes from 'modules/sign-up/routes';
import { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MenuHeaderContextProvider } from 'common/components/authenticated-header/menu-header.context';
import { PageContextProvider } from 'common/components/base/pages/context';
import { SignUpProviderContextProvider } from 'common/components/base/pages/context/SignUpProviderContext';
import PageMenuHeader from 'common/components/base/pages/page-menu-header';
import InvoicesRoutes from 'modules/invoices/routes';
import PublicRoutes from 'modules/public/routes';
import PriceBankRoutes from 'modules/priceBank/routes';
import MarketplaceRoutes from 'modules/marketplace/routes';
import { RedirectOAuth2Login } from 'modules/auth/pages/OAuth2/Redirect-OAuth2-view';
import TopbarContextProvider from 'common/components/base/pages/context/TopbarContext';
import MaterialRoutes from '../modules/material/routes';
import AuditRoutes from '../modules/audit/routes';
import AuthRoutes from '../modules/auth/routes';
import CompanyRoutes from '../modules/company/routes';
import GroupAuthorizationRoutes from '../modules/group-authorization/routes';
import ManagementRoutes from '../modules/management/routes';
import OrganizationUsersRoutes from '../modules/organization-users/routes';
import ProcessRoutes from '../modules/process/routes';
import ProviderDashboardRoutes from '../modules/provider-dashboard/routes';
import ProviderUsersRoutes from '../modules/provider-users/routes';
import ApiOffline from './api-offline';
import PrivateRoute from './components/private-route';
import NotFound from './not-found';
import { PlatformContextProvider } from './platform.context';
import WithoutPermission from './without-permission';
import PageConfirmationTerms from './components/page-confimation-terms';
import NotificationTopbar from './components/notification-topbar';

const Routes: FC = () => {
    const routeList: JSX.Element[] = [
        ...PublicRoutes,
        ...AuthRoutes,
        ...MarketplaceRoutes,
        ...PriceBankRoutes,
        ...EditorRoutes,
        ...OrganizationRoutes,
        ...ProviderRoutes,
        ...OrganizationUsersRoutes,
        ...ProviderUsersRoutes,
        ...SignUpRoutes,
        ...GroupAuthorizationRoutes,
        ...MaterialRoutes,
        ...ProcessRoutes,
        ...BiddingRoutes,
        ...AuditRoutes,
        ...CompanyRoutes,
        ...HolidaysRoutes,
        ...DocumentTypesRoutes,
        ...ProviderDashboardRoutes,
        ...CitizenDashboardRoutes,
        ...NotificationRoutes,
        ...ManagementRoutes,
        ...ContractsRoutes,
        ...InvoicesRoutes,
        ...HiringPlansRoutes,
        ...NoticesInformationRoutes,
    ];

    return (
        <BrowserRouter>
            <Route path='/erro-sistema' key='/erro-sistema' component={() => <ApiOffline />} />
            <PlatformContextProvider>
                <PageContextProvider>
                    <SignUpProviderContextProvider>
                        <MenuHeaderContextProvider>
                            <TopbarContextProvider>
                                <NotificationTopbar />
                                <Route
                                    path='/login'
                                    key='/login'
                                    exact
                                    component={() => RedirectOAuth2Login('oauth2/in/')}
                                />
                                <Route
                                    path='/recuperar-senha'
                                    key='/recuperar-senha'
                                    exact
                                    component={() => RedirectOAuth2Login('oauth2/retrieve/')}
                                />

                                <Route
                                    path='/alterar-senha'
                                    key='/alterar-senha'
                                    exact
                                    component={() => RedirectOAuth2Login('oauth2/login-methods/')}
                                />
                                <PageMenuHeader>
                                    <Switch>
                                        {routeList}
                                        <PrivateRoute
                                            path='/sem-permissao'
                                            key='/sem-permissao'
                                            component={() => <WithoutPermission />}
                                        />
                                        <PrivateRoute
                                            path='/nao-encontrado'
                                            key='/nao-encontrado'
                                            component={() => <NotFound />}
                                        />
                                        <Route path='*' key='*' component={() => <NotFound />} />
                                    </Switch>
                                </PageMenuHeader>
                            </TopbarContextProvider>
                        </MenuHeaderContextProvider>
                    </SignUpProviderContextProvider>
                </PageContextProvider>
                <PageConfirmationTerms />
            </PlatformContextProvider>
        </BrowserRouter>
    );
};

export default Routes;
