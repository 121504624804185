import { Typography } from '@material-ui/core';
import { t } from 'i18next';
import useStyles from './styles';

export const BoxConfirmChangeEmailView = ({ error, isConfirm, url }) => {
    const styles = useStyles();
    return (
        <div className={styles.loading}>
            <div className={styles[error ? 'containerError' : 'containerSucess']}>
                <Typography className={styles[error ? 'titleError' : 'titleSucess']}>
                    <img
                        src={`/assets/icons/${error ? 'error_filled' : 'checked'}.svg`}
                        alt='Title icon'
                        className={styles.icon}
                    />
                    {error ? t('term.error') : t('term.success')}
                </Typography>
                <Typography className={styles.description}>
                    {error
                        ? t('change_email.error')
                        : t(`change_email.${isConfirm ? 'confirmed' : 'refused'}`)}
                </Typography>
                <a href={url} className='back-link'>
                    {t('password-reset.back-to-dashboard')}
                </a>
            </div>
        </div>
    );
};
