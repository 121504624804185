import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles(() => ({
    skeleton: {
        height: formatPxToRem(420),
        marginBottom: formatPxToRem(10),
        borderRadius: formatPxToRem(8),
        transform: 'scale(1)',
    },
    container: {
        paddingRight: formatPxToRem(10),
        height: '80vh',
        flex: 1,
        overflowY: 'auto',
    },
}));

export { useStyles };
