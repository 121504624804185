import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { MarketplaceCartItem } from 'modules/marketplace/interfaces/marketplace.interface';
import moment from 'moment';
import MarketplaceSummaryCardView from './marketplace-summary-card-view';

interface MarketplaceSummaryCardProps {
    title: string;
    items?: MarketplaceCartItem[];
    loading?: boolean;
    initialDeadlineToSendProposal?: string;
    hideBackToCartLink?: boolean;
    hideDeadline?: boolean;
    confirmLabel?: string;
    isConfirming?: boolean;
    isJudged?: boolean;
    disabled?: boolean;
    onConfirm?: (deadlineToSendProposal: string) => any;
}

const MarketplaceSummaryCard: React.FC<MarketplaceSummaryCardProps> = ({
    title,
    items,
    loading = false,
    confirmLabel,
    isConfirming = false,
    isJudged = false,
    onConfirm,
    initialDeadlineToSendProposal,
    hideBackToCartLink = false,
    hideDeadline = false,
    disabled = false,
}) => {
    const [deadlineToSendProposal, setDeadlineToSendProposal] = useState('');

    const disableConfirmButton =
        moment(initialDeadlineToSendProposal).diff(moment(), 'seconds') > 0 || disabled;

    const handleOnDeadlineChange = (value: string) => {
        setDeadlineToSendProposal(value);
    };

    const handleCreateOrder = () => {
        onConfirm?.(deadlineToSendProposal);
    };

    const calculateAveragePrice = (cartItem: MarketplaceCartItem) => {
        return cartItem.item.prices.mid * (cartItem.itemAmount ?? 1);
    };
    const calculateEstimatedValue = (cartItem: MarketplaceCartItem) => {
        return cartItem.item.prices.estimated * (cartItem.itemAmount ?? 1);
    };
    const calculateDiscount = (cartItem: MarketplaceCartItem) => {
        return calculateAveragePrice(cartItem) - calculateEstimatedValue(cartItem);
    };

    const totalAverangeValue =
        items?.reduce((acc, cartItem) => acc + calculateAveragePrice(cartItem), 0) ?? 0;
    const totalEstimatedValue =
        items?.reduce((acc, cartItem) => acc + calculateEstimatedValue(cartItem), 0) ?? 0;
    const totalDiscount =
        items?.reduce((acc, cartItem) => acc + calculateDiscount(cartItem), 0) ?? 0;
    const totalValue =
        items?.reduce((acc, cartItem) => acc + calculateAveragePrice(cartItem), 0) ?? 0;

    return (
        <div>
            {loading ? (
                <>
                    <Skeleton height={300} width={300} style={{ marginTop: '-70px' }} />
                </>
            ) : (
                <MarketplaceSummaryCardView
                    title={title}
                    initialDeadlineToSendProposal={initialDeadlineToSendProposal}
                    deadlineToSendProposal={deadlineToSendProposal}
                    onDeadlineChange={handleOnDeadlineChange}
                    handleCreateOrder={handleCreateOrder}
                    totalAverangeValue={totalAverangeValue}
                    totalEstimatedValue={totalEstimatedValue}
                    totalDiscount={totalDiscount}
                    totalValue={totalValue}
                    confirmLabel={confirmLabel}
                    isConfirming={isConfirming}
                    isJudged={isJudged}
                    hideBackToCartLink={hideBackToCartLink}
                    hideDeadline={hideDeadline}
                    disableConfirmButton={disableConfirmButton}
                />
            )}
        </div>
    );
};

export default MarketplaceSummaryCard;
