import { Modal, ModalPosition } from 'common/components';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { FormikProps } from 'formik';
import { FooterActions } from './footer-actions/footer-confirm-change-email';
import useStyles from './style';

interface ModalConfirmChangeEmailByAdminViewProps {
    open: boolean;
    onClose: () => void;
    email?: string;
    form: FormikProps<{}>;
}

export const ModalConfirmChangeEmailByAdminView: FC<ModalConfirmChangeEmailByAdminViewProps> = ({
    open,
    onClose,
    email,
    form,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Modal
            position={ModalPosition.center}
            open={open}
            onClose={() => onClose()}
            footer={
                <FooterActions
                    onClose={onClose}
                    onConfirm={() => {
                        return form.handleSubmit();
                    }}
                />
            }
        >
            <div className={styles.container}>
                <img src='/assets/icons/lock.svg' alt='Title icon' className={styles.icon} />
                <Typography className={styles.title}>
                    {t('change_email.validator_modal_title')}
                </Typography>
                <Typography className={styles.subTitle}>
                    {t('change_email.validator_modal_subtitle', { email })}
                </Typography>
            </div>
        </Modal>
    );
};
