import jsPDF from 'jspdf';
import moment from 'moment';

interface UserHeader {
    name: string;
}

const defaultHeader = (doc: jsPDF | any, user: UserHeader, priceListId: string, page: number) => {
    const defaulImageLocation = `/assets/images/default_licitardigital.png`;

    doc.setPage(page);
    doc.setTextColor('#222');

    doc.setFontSize(9);
    doc.text('Cotação:', 15, 10);
    doc.text('Responsável:', 15, 15);
    doc.text('Emissão:', 15, 20);

    doc.setTextColor('#777');
    doc.text(priceListId, 38, 10);
    doc.text(user.name, 38, 15);
    doc.text(moment().locale('pt-br').format('LLL'), 38, 20);

    const img = new Image();
    img.src = defaulImageLocation;
    doc.addImage(img, 'png', 155, 9, 38, 12);

    doc.line(14, 23, 196, 23);
};

const addHeaders = (
    doc: jsPDF | any,
    user: UserHeader,
    priceListId: string,
    eachPage?: boolean
) => {
    if (eachPage) {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            defaultHeader(doc, user, priceListId, i);
        }
        return;
    }

    return defaultHeader(doc, user, priceListId, 1);
};

export { addHeaders };
