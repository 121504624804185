import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { OrderStatusEnum } from 'common/enums';
import { OrderStatusChip, OrderDeadlineCountdown } from 'modules/marketplace/components';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useStyles } from './provider-order-card-style';
import OrderItemsTable from '../order-items-table';

interface ProviderOrderCardViewProps {
    order: OrderResponse;
    redirectToAction?: () => void;
}

const ProviderOrderCardView: React.FC<ProviderOrderCardViewProps> = ({
    order,
    redirectToAction,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Paper variant='outlined' key={order.id} className={styles.container}>
            <header>
                <section className={styles.titleSection}>
                    <div>
                        <Typography variant='h6'>
                            {t('order.term.order-id', { value: order.id })}
                        </Typography>
                        <Typography variant='subtitle1' className={styles.marginTopSm}>
                            {order.purchaseJustification}
                        </Typography>
                    </div>
                    <div>
                        <Button variant='contained' color='primary' onClick={redirectToAction}>
                            <Typography variant='button' color='textSecondary'>
                                {order.status === OrderStatusEnum.OPENED
                                    ? t('marketplace.provider-orders.update-proposal')
                                    : t('marketplace.provider-orders.go-to-judgment')}
                            </Typography>
                        </Button>
                    </div>
                </section>
                <section className={styles.infoSection}>
                    <div className={styles.infoContainer}>
                        <Typography variant='body2' className={styles.infoLabel}>
                            {t('marketplace.provider-orders.order-status')}
                        </Typography>
                        <OrderStatusChip status={order.status} />
                    </div>
                    <div className={styles.infoContainerBordeless}>
                        <Typography variant='body2' className={styles.infoLabel}>
                            {t('marketplace.provider-orders.proposal-deadline-label')}
                        </Typography>
                        <OrderDeadlineCountdown
                            onlyCountDown
                            deadline={order.deadlineToSendProposal}
                        />
                    </div>
                </section>
            </header>
            <div>
                <Typography variant='subtitle1' className={styles.strong}>
                    {t('term.proposals')}:
                </Typography>
                <OrderItemsTable items={order.items} />
            </div>
        </Paper>
    );
};

export default ProviderOrderCardView;
