/* eslint-disable no-useless-computed-key */
import { Grid, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Button, TextField } from 'common/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { SignUpContext } from '../context/SignUpContext';

const CreatePassword = () => {
    const { setNewUser, setCurrentStep, token, errorMessage, handleClickPrev } =
        useContext(SignUpContext);

    const { t } = useTranslation();

    const [consentIsChecked, setConsentIsChecked] = useState(false);
    const [lgpdLegalBasis, setLgpdLegalBasis] = useState({});
    const [validatePassword, setValidadePassword] = useState({
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
        special: false,
    });

    const secureText = (password: string) => {
        const regexUppercase = RegExp(/^(?=.*[A-Z]).+$/);
        const regexLowercase = RegExp(/^(?=.*[a-z]).+$/);
        const regexNumber = RegExp(/^(?=.*[0-9]).+$/);
        const regexSpecial = RegExp(/[!@#$%^&*(),.?":{}|<>]/g);
        const length = password.length >= 8;

        ReactDOM.unstable_batchedUpdates(() => {
            setValidadePassword({
                uppercase: regexUppercase.test(password),
                lowercase: regexLowercase.test(password),
                number: regexNumber.test(password),
                special: regexSpecial.test(password),
                length,
            });
        });
    };

    const regexUppercase = /^(?=.*[A-Z]).+$/;
    const regexLowercase = /^(?=.*[a-z]).+$/;
    const regexNumber = /^(?=.*[0-9]).+$/;
    const regexSpecial = /[!@#$%^&*(),.?":{}|<>]/;

    const validationSchema = yup.object({
        password: yup
            .string()
            .matches(regexLowercase, t('password-lowercase', { ns: 'validation' }))
            .matches(regexUppercase, t('password-upercase', { ns: 'validation' }))
            .matches(regexNumber, t('password-number', { ns: 'validation' }))
            .matches(regexSpecial, t('password-special-character', { ns: 'validation' }))
            .min(8, t('password-min-number-character', { ns: 'validation' }))
            .required(t('password', { ns: 'validation' })),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('password'), null], t('password-equals', { ns: 'validation' }))
            .required(t('password-confirm', { ns: 'validation' })),
    });

    const initialValues = {
        password: '',
        confirmPassword: '',
    };

    const form3 = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            ReactDOM.unstable_batchedUpdates(() => {
                setNewUser((prevNewUser: any) => ({
                    ...prevNewUser,
                    password: values.password,
                    lgpdLegalBasis: JSON.stringify(lgpdLegalBasis),
                }));
            });
        },
    });

    // useEffect(() => {
    //     if (token) {
    //         setCurrentStep(3);
    //     }
    // }, [token]);

    return (
        <Grid className='step-3'>
            <Typography variant='body2'>{t('sign-up.pages.stepps.info-account-play')}</Typography>

            <form onSubmit={form3.handleSubmit}>
                <div className='formContent'>
                    <Grid container spacing={2}>
                        <Grid item md={7}>
                            <div className='first-item'>
                                <TextField
                                    label={t('term.password')}
                                    name='password'
                                    type='password'
                                    value={form3.values.password}
                                    onChange={(e) => {
                                        form3.handleChange(e);
                                        secureText(e.target.value);
                                    }}
                                    error={form3.touched.password && Boolean(form3.errors.password)}
                                />
                                <TextField
                                    label={t('term.confirm-password')}
                                    name='confirmPassword'
                                    type='password'
                                    value={form3.values.confirmPassword}
                                    onChange={form3.handleChange}
                                    error={
                                        form3.touched.confirmPassword &&
                                        Boolean(form3.errors.confirmPassword)
                                    }
                                    helperText={
                                        form3.touched.confirmPassword &&
                                        form3.errors.confirmPassword
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item md={5}>
                            <div className='passwordValidation'>
                                <div className='validation'>
                                    {validatePassword.length ? (
                                        <CheckCircleIcon className='validation-true' />
                                    ) : (
                                        <CancelIcon className='validation-false' />
                                    )}

                                    <Typography variant='body2'>
                                        {t('sign-up.pages.stepps.info-password-min-character')}
                                    </Typography>
                                </div>
                                <div className='validation'>
                                    {validatePassword.uppercase ? (
                                        <CheckCircleIcon className='validation-true' />
                                    ) : (
                                        <CancelIcon className='validation-false' />
                                    )}
                                    <Typography variant='body2'>
                                        {t('sign-up.pages.stepps.info-password-uppercase')}
                                    </Typography>
                                </div>
                                <div className='validation'>
                                    {validatePassword.lowercase ? (
                                        <CheckCircleIcon className='validation-true' />
                                    ) : (
                                        <CancelIcon className='validation-false' />
                                    )}
                                    <Typography variant='body2'>
                                        {t('sign-up.pages.stepps.info-password-lowercase')}
                                    </Typography>
                                </div>
                                <div className='validation'>
                                    {validatePassword.special ? (
                                        <CheckCircleIcon className='validation-true' />
                                    ) : (
                                        <CancelIcon className='validation-false' />
                                    )}
                                    <Typography variant='body2'>
                                        {t('sign-up.pages.stepps.info-password-special-char')}
                                    </Typography>
                                </div>
                                <div className='validation'>
                                    {validatePassword.number ? (
                                        <CheckCircleIcon className='validation-true' />
                                    ) : (
                                        <CancelIcon className='validation-false' />
                                    )}
                                    <Typography variant='body2'>
                                        {t('sign-up.pages.stepps.info-password-number-char')}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <div className='consent'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name='consent'
                                            checked={consentIsChecked}
                                            onClick={() => setConsentIsChecked(!consentIsChecked)}
                                            onChange={(e) =>
                                                setLgpdLegalBasis({
                                                    ...lgpdLegalBasis,
                                                    optional: {
                                                        allowSendProcessOpportunitiesByEmail:
                                                            e.currentTarget.checked,
                                                    },
                                                })
                                            }
                                        />
                                    }
                                    label={t('sign-up.pages.stepps.info-consent-privacy-policy')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {errorMessage && <Typography color='error'>{errorMessage}</Typography>}

                <Grid item>
                    <Button
                        size='small'
                        variant='text'
                        color='primary'
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={handleClickPrev}
                    >
                        {t('term.back')}
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={!consentIsChecked}
                    >
                        {t('term.create-account')}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
};

export default CreatePassword;
