import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    chipStatusWinner: {
        backgroundColor: theme.palette.success.main,
        fontWeight: 600,
    },
    chipStatusUnclassified: {
        backgroundColor: theme.palette.error.main,
        fontWeight: 600,
    },
    chipStatusNotCalled: {
        backgroundColor: theme.palette.grey[600],
        fontWeight: 600,
    },
}));
