import { apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from '../OAuth2/styles';

export const Profile = () => {
    const styles = useStyles();
    const backofficeRedirectUrl = getBackofficeRedirectUrl(true);
    apiRedirectTo({ backofficeRedirectUrl, redirectUrl: 'oauth2/profile' });
    return (
        <div className={styles.loading}>
            <CircularProgress className={clsx(styles.loading)} />
        </div>
    );
};
