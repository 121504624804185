import {
    Box,
    Grid,
    Paper,
    RadioGroup,
    Radio,
    FormControlLabel,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AuctionType } from 'clients/manager/interfaces/auction-notice.interface';
import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { AuctionTypeEnum } from 'common/enums';
import { getDefaultFormValuesByAuctionType } from 'modules/process/context/process-form-default-values';
import { ProcessModalityProps } from './props';
import ExpandedContentCard from '../../../expand-content-card';
import SelectLegalAssistance from '../../../select-legal-assistance';
import { useProcessFormContext } from '../../../../context/process-form.context';
import SelectAuctionTypeRule from '../../../select-auction-type-rule';
import { processUtils } from '../../../../process-utils';
import ProcessContentHeader from '../process-content-header';
import { getModalityFormLabels } from '../../../../context/schema/modality-form-schema';

const ProcessModality: FC<ProcessModalityProps> = () => {
    const { processForm, setAuctionTypeRules } = useProcessFormContext();
    const { t } = useTranslation();
    const processHasLegalSupport = processForm?.values.legalSupportId || processForm?.values.id;
    const visibleAccreditationType = processHasLegalSupport
        ? processUtils.isMarketplace(processForm.values)
        : false;

    const onChangeAuctionType = (auctionTypeRule: AuctionTypeRule) => {
        ReactDOM.unstable_batchedUpdates(() => {
            processForm?.setValues((oldValues) => {
                const defaultValues = getDefaultFormValuesByAuctionType(
                    auctionTypeRule.auctionType as AuctionTypeEnum
                );

                return {
                    ...oldValues,
                    auctionType: auctionTypeRule.auctionType as AuctionType,
                    ruleId: auctionTypeRule.id,
                    ...defaultValues,
                };
            });
            setAuctionTypeRules(auctionTypeRule);
        });
    };

    const onChangeLegalAssistance = (newLegalSupportId: number) => {
        processForm?.setValues((oldValues) => ({
            ...oldValues,
            legalSupportId: newLegalSupportId,
            isMarketplace: processUtils.isMarketplace({
                ...oldValues,
                legalSupportId: newLegalSupportId,
            })
                ? oldValues.isMarketplace
                : 0,
        }));
    };

    const handleSelectAccreditationType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = event.target.value;
        processForm?.setFieldValue('isMarketplace', Number(selectedType));
    };

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    header={
                        <ProcessContentHeader
                            title={t('term.modality')}
                            partialProcessFormLabels={getModalityFormLabels()}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <SelectAuctionTypeRule onChange={onChangeAuctionType} />
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <SelectLegalAssistance onChange={onChangeLegalAssistance} />
                        </Grid>
                        {visibleAccreditationType && (
                            <Grid item xs={12}>
                                <Typography variant='body2' paragraph>
                                    {t('term.accreditations-types')}
                                </Typography>
                                <RadioGroup
                                    aria-label='tipo de credenciamento'
                                    name='isMarketplace'
                                    onChange={handleSelectAccreditationType}
                                    row
                                    defaultValue={processForm?.values.isMarketplace ?? 0}
                                    value={processForm?.values.isMarketplace ?? 0}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Card variant='outlined'>
                                                <FormControlLabel
                                                    value={0}
                                                    control={<Radio />}
                                                    label={t(
                                                        'term.accreditations-quantitative-division'
                                                    )}
                                                    labelPlacement='end'
                                                />
                                                <CardContent>
                                                    <Typography variant='body2'>
                                                        {t('term.accreditation-modality-text-info')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Card variant='outlined'>
                                                <FormControlLabel
                                                    value={1}
                                                    control={<Radio />}
                                                    label={t('term.marketplace-licitar-digital')}
                                                    labelPlacement='end'
                                                />
                                                <CardContent>
                                                    <Typography variant='body2'>
                                                        {t('term.marketplace-modality-text-info')}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </Grid>
                        )}
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};
export default ProcessModality;
