import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    cardContainer: {
        minHeight: '212px',
        borderRadius: '8px',
        marginBottom: '0.6rem',
        overflow: 'hidden',
    },
    cardContentContainer: {
        padding: '1.6rem',
        marginBottom: '0.6rem',
    },
    providerActionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 10,
    },
    skeleton: {
        height: '212px',
        borderRadius: '8px',
        marginBottom: '0.6rem',
        transform: 'scale(1)',
    },
    processInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textProcessInfo: {
        color: '#999',
        fontSize: '11px',
        fontWeight: 600,
    },
    categoriesChip: {
        marginRight: '5px',
    },
    title: {
        color: '#444',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: 1,
        marginBottom: '5px',
    },
    titlePrice: {
        color: 'black',
        fontSize: '23px',
        textAlign: 'end',
        fontWeight: 800,
    },
    titlePriceLabel: {
        textAlign: 'end',
        color: '#999',
        fontSize: '12px',
        fontWeight: 600,
    },
    listInfoContainer: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listInfoItem: {
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 10,
            right: 0,
            bottom: 0,
            borderRight: 'solid 1px #999',
            height: '60%',
        },
    },
    infoItemLabel: {
        color: '#999',
        fontSize: '12px',
        fontWeight: 600,
    },
    infoItemValue: {
        color: '#838080',
        fontSize: '16px',
        fontWeight: 800,
    },
    addToCartButton: {
        width: '180px',
        height: '32px',
    },
}));

export { useStyles };
