import React, { useMemo } from 'react';

import { useHistory } from 'react-router';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import ProcessOrdersDataView from './process-orders-data-view';
import { ordersRequests } from '../../services/orders.requests';

interface ProcessOrdersDataProps {}

const ProcessOrdersData: React.FC<ProcessOrdersDataProps> = () => {
    const history = useHistory();

    const { processForm } = useProcessFormContext();

    const accreditationId = processForm?.values?.id;

    const handleRedirectToMarketplace = () => {
        history.push(`/mercado`);
    };

    const params = useMemo(() => ({ accreditationId }), [accreditationId]);

    const {
        data: ordersResponse,
        loading,
        ...paginationParams
    } = usePaginatedQuery(ordersRequests.listOrdersByAccreditation, params, {
        skip: !accreditationId,
    });

    const orders = ordersResponse?.data ?? [];

    return (
        <ProcessOrdersDataView
            orders={orders}
            loading={loading}
            paginationParams={paginationParams}
            onClick={handleRedirectToMarketplace}
        />
    );
};

export default ProcessOrdersData;
