import { FC, useCallback } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from 'routes/components/private-route';
import NotFound from 'routes/not-found';
import { featureFlagEnabled, hasPermissionToExecute } from 'common/utils';
import { MarketplaceProvider } from './context/marketplace.context';
import {
    CartPage,
    CheckoutPage,
    ContractPage,
    HomePage,
    JudgmentPage,
    OrderPage,
} from './pages/organizations';
import { ProviderJudgmentPage, ProviderOrderPage } from './pages/providers';

const ChildRoutes: FC = () => {
    const match = useRouteMatch();

    const handleCanAccess = useCallback(() => {
        const hasPermission = hasPermissionToExecute('listarMarketplace');
        const featureIsEnabled = featureFlagEnabled('habilitarMarketplace');

        return hasPermission && featureIsEnabled;
    }, []);

    return (
        <MarketplaceProvider>
            <Switch>
                <PrivateRoute
                    canAccess={handleCanAccess}
                    path={`${match.path}/`}
                    exact
                    component={() => <HomePage />}
                />
                <PrivateRoute
                    canAccess={handleCanAccess}
                    path={`${match.path}/carrinho`}
                    key='/carrinho'
                    exact
                    component={() => <CartPage />}
                />
                <PrivateRoute
                    canAccess={handleCanAccess}
                    path={`${match.path}/pedidos`}
                    key='/pedidos'
                    exact
                    component={() => <OrderPage />}
                />
                <PrivateRoute
                    canAccess={handleCanAccess}
                    path={`${match.path}/pedidos/checkout/:orderId`}
                    key='/pedidos/checkout/:orderId'
                    exact
                    component={() => <CheckoutPage />}
                />
                <PrivateRoute
                    canAccess={handleCanAccess}
                    path={`${match.path}/pedidos/julgamento/:orderId`}
                    key='/pedidos/julgamento/:orderId'
                    component={() => <JudgmentPage />}
                />
                <PrivateRoute
                    canAccess={handleCanAccess}
                    path={`${match.path}/pedidos/empenho/:orderId`}
                    key='/pedidos/empenho/:orderId'
                    component={() => <ContractPage />}
                />
                <PrivateRoute
                    // canAccess={handleCanAccess}
                    path={`${match.path}/fornecedor/pedidos`}
                    key='/fornecedor/pedidos'
                    exact
                    component={() => <ProviderOrderPage />}
                />
                <PrivateRoute
                    // canAccess={handleCanAccess}
                    path={`${match.path}/fornecedor/pedidos/julgamento/:orderId`}
                    key='/fornecedor/pedidos/julgamento/:orderId'
                    exact
                    component={() => <ProviderJudgmentPage />}
                />
                <PrivateRoute path='*' key='*' component={() => <NotFound />} />
            </Switch>
        </MarketplaceProvider>
    );
};

const MarketplaceRoutes: JSX.Element[] = [
    <Route path='/mercado' key='/mercado' render={() => <ChildRoutes />} />,
];

export default MarketplaceRoutes;
