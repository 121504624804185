import { useHistory } from 'react-router-dom';
import { setAuthToken, setAuthUserToken } from 'modules/auth/session.utils';
import { apiRedirectTo, getBackofficeRedirectUrl } from 'common/utils';
import { TypeAccess } from 'common/interfaces/user.interface';
import jwtDecode from 'jwt-decode';
import { JwtContent } from 'store/user/user.reducer';

export const useValidadeToken = () => {
    const history = useHistory();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const redirectOrganization = (token: string) => {
        if (process.env.REACT_APP_NODE_ENV === 'local') {
            history.push(`/auth/?token=${token}&redirectUrl=/processos`);
            return;
        }
        const loginBaseUrl = getBackofficeRedirectUrl(true);
        apiRedirectTo({ backofficeRedirectUrl, redirectUrl: 'painel', loginBaseUrl });
    };

    const authenticate = async (token: string) => {
        setAuthUserToken(token);
        setAuthToken(token);
        const tokenData = jwtDecode(token) as JwtContent;
        switch (tokenData.typeAccess) {
            case TypeAccess.provider: {
                history.push(`/auth/?token=${token}&redirectUrl=/painel-fornecedor`);
                break;
            }
            case TypeAccess.citizen: {
                history.push(`/auth/?token=${token}&redirectUrl=/painel-cidadao`);
                break;
            }
            default: {
                redirectOrganization(token);
            }
        }
    };

    return {
        authenticate,
    };
};
