import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import _, { create } from 'lodash';
import { HiringPlanItemUnitRequest } from 'clients/manager/interfaces/hiring-plans.interfaces';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import useHiringPlansContext from 'modules/hiring-plans/hooks/useHiringPlans';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import moment from 'moment';
import DialogNewEditItemPcaView from './dialog-new-edit-item-pca-view';

interface DialogNewEditItemPcaProps {
    pcaId: number;
    organizationUnitId: number;
}

const DialogNewEditItemPca: React.FC<DialogNewEditItemPcaProps> = (props) => {
    const { pcaId, organizationUnitId } = props;
    const { t } = useTranslation();
    const {
        itemEditId,
        setItemEditId,
        pcaStatusEnum,
        hiringPlansList,
        hiringPlanItens,
        setHiringPlanItens,
        onCloseDialogCrudItemPca,
    } = useHiringPlansContext();
    const [fecharFormSalvar, setFecharFormSalvar] = useState(false);
    const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false);
    const itemDataToEdit = itemEditId && hiringPlanItens.find((item) => item.id === itemEditId);
    const pcaIsSynchronized =
        hiringPlansList.find((hiringPlan) => hiringPlan.id === pcaId)?.status ===
        pcaStatusEnum.done;

    const defaultSchema = {
        description: yup.string().min(6).max(255).required(t('term.required-field')),
        pcaItemCategory: yup.string().required(t('term.required-field')),
        unitValue: yup.number().min(0).required(t('term.required-field')),
        quantity: yup.number().min(1).required(t('term.required-field')),
        totalValue: yup.number().required(t('term.required-field')),
        providerUnit: yup.string().max(255).required(t('term.required-field')),
        budgetValueAssignment: yup.number().required(t('term.required-field')),
        requestUnit: yup.string().max(255).required(t('term.required-field')),
        expectedDate: yup
            .date()
            .min(
                new Date(new Date().setDate(new Date().getDate() - 1)),
                t('hiring-plans.info.field-cannot-less-than-current-date')
            )
            .required(t('term.required-field')),
        catalog: yup.string().max(2),
        catalogClassification: yup.string().max(2).required(t('term.required-field')),
        superiorClassificationCode: yup.string().max(100).required(t('term.required-field')),
        superiorClassificationName: yup.string().max(255).required(t('term.required-field')),
    };

    const newItemSchema = yup.object({ ...defaultSchema });

    const editItemSchema = yup.object({
        ...defaultSchema,
        expectedDate: yup.date().required(t('term.required-field')),
    });

    const editItemSynchronizedSchema = yup.object({
        ...defaultSchema,
        expectedDate: yup.date().required(t('term.required-field')),
        justification: yup.string().min(12).max(255).required(t('term.required-field')),
    });
    const defaultValues = {
        pcaId: pcaId,
        organizationUnitId: organizationUnitId,
        description: '',
        pcaItemCategory: '',
        unitValue: 0,
        quantity: 1,
        totalValue: 0,
        providerUnit: '',
        budgetValueAssignment: 0,
        requestUnit: '',
        expectedDate: moment().format('yyyy-MM-DD'),
        catalog: '',
        catalogClassification: '',
        superiorClassificationCode: '',
        superiorClassificationName: '',
        createdOnPncp: undefined,
    };

    const getDefaultInitialFormData = () => {
        if (!itemEditId) {
            return defaultValues;
        }
        if (itemDataToEdit && !pcaIsSynchronized) {
            return _.pick(
                itemDataToEdit,
                Object.keys({ ...defaultValues })
            ) as HiringPlanItemUnitRequest;
        }
        if (itemDataToEdit && pcaIsSynchronized) {
            return _.pick(
                itemDataToEdit,
                Object.keys({ ...defaultValues, justification: '' })
            ) as HiringPlanItemUnitRequest;
        }
        return defaultValues;
    };

    const getFormDataSchema = () => {
        if (!itemEditId) {
            return newItemSchema;
        }
        if (itemDataToEdit && !itemDataToEdit.createdOnPncp) {
            return editItemSchema;
        }
        if (itemDataToEdit && pcaIsSynchronized && itemDataToEdit.createdOnPncp) {
            return editItemSynchronizedSchema;
        }
        return newItemSchema;
    };

    const form = useFormik<HiringPlanItemUnitRequest>({
        validateOnBlur: true,
        validateOnMount: false,
        validateOnChange: true,
        initialValues: getDefaultInitialFormData(),
        validationSchema: getFormDataSchema(),
        onSubmit: async (values, { resetForm }) => {
            setIsLoadingSubmitForm(true);
            try {
                if (!itemEditId) {
                    const response = await hiringPlansRequests.createHiringPlanUnitItem({
                        ...values,
                        catalog: String(values.catalog),
                        pcaItemCategory: String(values.pcaItemCategory),
                        catalogClassification: String(values.catalogClassification),
                    });
                    setHiringPlanItens((prevState) => [...prevState, response]);
                } else {
                    await hiringPlansRequests.editHiringPlanUnitItem({
                        ...values,
                        id: itemEditId,
                        catalog: String(values.catalog),
                        pcaItemCategory: String(values.pcaItemCategory),
                        catalogClassification: String(values.catalogClassification),
                    });
                }
                const itemsUpdate = await hiringPlansRequests.getListItensHiringPlan({
                    params: { pcaId: pcaId },
                });
                setHiringPlanItens(itemsUpdate.data);
                if (fecharFormSalvar) onCloseDialogCrudItemPca();
                resetForm();
                addNotificationSuccess({
                    title: t('term.success'),
                    message: !itemEditId
                        ? t('hiring-plans.info.create-pca-unit-item-success')
                        : t('hiring-plans.info.edit-pca-unit-item-success'),
                });
            } catch (err) {
                addNotificationError({
                    title: t('term.error'),
                    message: !itemEditId
                        ? t('hiring-plans.info.create-pca-unit-item-error')
                        : t('hiring-plans.info.edit-pca-unit-item-error'),
                });
            } finally {
                setIsLoadingSubmitForm(false);
            }
        },
    });

    const submitForm = () => {
        form.handleSubmit();
    };

    const searchDetailsPcaUnit = (idPca: number) =>
        hiringPlansList.find((hiringPlan) => hiringPlan.id === idPca);

    useEffect(() => {
        const { quantity, unitValue } = form.values;
        form.setFieldValue('totalValue', Number((quantity * unitValue).toFixed(2)));
    }, [form.values.quantity, form.values.unitValue]);

    useEffect(() => () => setItemEditId(undefined), []);

    return (
        <DialogNewEditItemPcaView
            {...props}
            form={form}
            submitForm={submitForm}
            pcaIsSynchronized={pcaIsSynchronized}
            setFecharFormSalvar={setFecharFormSalvar}
            isLoadingSubmitForm={isLoadingSubmitForm}
            searchDetailsPcaUnit={searchDetailsPcaUnit}
        />
    );
};

export default DialogNewEditItemPca;
