import { Box, Checkbox, CircularProgress, Grid, Paper } from '@material-ui/core';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'common/components';
import { organizationUnitsRequests } from 'clients/manager/organization.requests';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import { processActions } from 'modules/process/process-actions';
import ExpandedContentCard from '../../../expand-content-card';
import { useProcessFormContext } from '../../../../context/process-form.context';
import ProcessContentHeader from '../process-content-header';
import { ProcessUnitDataProps } from './props';
import SelectOrganizationUnit from '../../../select-organization-unit';
import { getUnitDataFormLabels } from '../../../../context/schema/unit-data-form-schema';

const ProcessUnitData: FC<ProcessUnitDataProps> = ({ disabledExpand }) => {
    const { processForm, process } = useProcessFormContext();

    const { t } = useTranslation();

    const { data: organizationUnits, loading } = useQueryWithBody(
        organizationUnitsRequests.listOrganizationUnit
    );

    const optionalOrganizationUnits = organizationUnits?.filter(
        (unit) => unit.id !== processForm?.values.organizationUnitId
    );

    const onChangeUnit = (organizationUnitId: number) => {
        ReactDOM.unstable_batchedUpdates(() => {
            processForm?.setValues({
                ...processForm?.values,
                organizationUnitId,
            });
        });
    };

    const onChangeParticipantsUnits = (values: OrganizationUnit[]) => {
        ReactDOM.unstable_batchedUpdates(() => {
            processForm?.setValues({
                ...processForm?.values,
                processUnits: values?.map((value) => ({ unitId: value.id })) ?? [],
            });
        });
    };

    const participantsUnitsValue =
        optionalOrganizationUnits?.filter(
            (organizationUnit) =>
                processForm?.values?.processUnits &&
                processForm.values.processUnits.find(
                    (processUnit) => processUnit.unitId === organizationUnit.id
                )
        ) ?? [];

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <ProcessContentHeader
                            title={t(
                                'process.components.process-data.process-unit-data.units-data'
                            )}
                            partialProcessFormLabels={getUnitDataFormLabels()}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <SelectOrganizationUnit
                                label={t('term.organization-unit')}
                                value={processForm?.values.organizationUnitId}
                                organizationUnitOptions={organizationUnits ?? []}
                                loading={loading}
                                onChange={onChangeUnit}
                            />
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Autocomplete
                                fullWidth
                                multiple
                                disabled={
                                    loading ||
                                    !processForm?.values.organizationUnitId ||
                                    processActions.cannotEditUntilDispute(process)
                                }
                                value={participantsUnitsValue}
                                onChange={(_, values) => {
                                    onChangeParticipantsUnits(values);
                                }}
                                options={optionalOrganizationUnits ?? []}
                                disableCloseOnSelect
                                getOptionLabel={(option: any) => option?.organizationUnitName}
                                getOptionSelected={(option, value) => option?.id === value?.id}
                                renderOption={(option, state) => (
                                    <>
                                        <Checkbox checked={state?.selected} color='primary' />
                                        {option?.organizationUnitName}
                                    </>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t(
                                            'process.components.process-data.process-unit-data.participants-units'
                                        )}
                                        placeholder={t(
                                            'process.components.process-data.process-unit-data.select-participants-units'
                                        )}
                                        InputProps={{
                                            ...params?.InputProps,
                                            endAdornment: loading && (
                                                <CircularProgress color='secondary' size={20} />
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessUnitData;
