import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { doRequest, managerApiInstance } from '../api';
import { OkResponse } from '../interfaces/ok.interface';
import { PlainGenericResponse } from '../interfaces/paginated.interface';

interface CreateLotsAndItensData {
    auctionNoticeId: number;
    file: string;
    enterprise: string;
    async: boolean;
}
interface ExportLotsAndItensData {
    processId: number;
    enterprise: string;
    async: boolean;
    onlyWinners?: boolean;
}
interface ImportPcaItems {
    file: File;
    organizationUnitId: number;
    pcaId: number;
}

const integrationsRequests = {
    createLotsAndItens: async (data: CreateLotsAndItensData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/integrations/imports/createLotsAndItens`, data)
        ),
    exportLotsAndItens: async (data: ExportLotsAndItensData) =>
        doRequest<any>(
            managerApiInstance.post(
                `/integrations/exports/auction-notice-lots`,
                {
                    params: data,
                },
                data.async
                    ? undefined
                    : {
                          responseType: 'arraybuffer',
                      }
            )
        ),
    importPcaItems: async (data: ImportPcaItems) => {
        const formData = new FormData();
        const newFileName = getNewFileName(data.file);
        formData.append('file', data.file, newFileName);
        formData.append('organizationUnitId', data.organizationUnitId.toString());
        return doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`pca/${data.pcaId}/pcaItemBatch`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                // @TODO: Tornar essa requisição assíncrona e retirar o timeout
                timeout: 30000,
            })
        );
    },
};

export { integrationsRequests };
