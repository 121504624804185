/**
 * Censor email address by replacing the most of the characters with a specified character.
 * @param email The email address to censor.
 * @param options The options to customize the censoring:
 * - `visibility`: The number of characters to keep visible at the beginning and end of the email address. Default is `1`.
 * - `censor`: The character to replace the rest of the characters with. Default is `•`.
 * @author &commat;nascjoao &lt;oi&commat;nasc.dev&gt;
 */
export default function censorEmail(
    email: string,
    options?: { visibility?: number; censor?: string }
) {
    const visibility = options?.visibility ?? 1;
    const censor = options?.censor ?? '•';
    const pattern = new RegExp(
        `^(.{${visibility}})(.*)(.{${visibility}})@(.{${visibility}})(.*)(\\..+)$`
    );
    return email.replace(pattern, (_, first, middle, last, atFirst, provider, tld) => {
        return `${first}${middle.replace(/./g, censor)}${last}@${atFirst}${provider.replace(
            /./g,
            censor
        )}${tld}`;
    });
}
