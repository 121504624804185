import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    table: {
        backgroundColor: theme.palette.grey[100],
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    headTableCell: {
        fontWeight: 600,
    },
    largeBodyTableCell: {
        width: '40%',
    },
    smallBodyTableCell: {
        width: '10%',
    },
}));

export { useStyles };
