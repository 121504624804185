import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { disableField } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';
import HelpIconLabel from 'modules/process/components/help-icon-label';

const BidGuaranteeField = () => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const handleChangeBidGurantee = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        return processForm?.setValues({
            ...processForm?.values,
            [event.target.name]: value,
        });
    }, 30);

    return (
        <HelpIconLabel
            title={t('process.components.bid-guarantee-warning')}
        >
            <TextField
                fullWidth
                color='primary'
                label={t('process.components.bid-guarantee')}
                name='bidGuarantee'
                onChange={handleChangeBidGurantee}
                variant='outlined'
                select
                defaultValue={processForm?.values?.bidGuarantee ?? 0}
                disabled={disableField('bidGuarantee', process)}
            >
                <MenuItem value={1}>{t('term.yes')}</MenuItem>
                <MenuItem value={0}>{t('term.no')}</MenuItem>
            </TextField>
        </HelpIconLabel>
    );
};

export default BidGuaranteeField;
