import React from 'react';
import { Button, Typography, ButtonProps } from '@material-ui/core';
import { OrderResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate } from 'common/utils';
import { useStyles } from './process-order-card-style';
import OrderStatusChip from './components/order-status-chip';

export interface ProcessOrderCardViewProps {
    order: OrderResponse;
    totalValue?: number;
    totalEstimatedValue?: number;
    onClick?: ButtonProps['onClick'];
}

const ProcessOrderCardView: React.FC<ProcessOrderCardViewProps> = ({
    order,
    totalValue = 0,
    totalEstimatedValue = 0,
    onClick,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <section className={styles.container}>
            <header className={styles.header}>
                <div className={styles.flex}>
                    <Typography className={styles.title}>
                        {t('order.term.order-id', { value: order.id })}
                    </Typography>
                    <Typography className={styles.itemsCount}>
                        ({order.items.length} itens)
                    </Typography>
                </div>
                <div className={styles.flex}>
                    <Typography className={styles.contentTitle}>
                        {formatDate(order.createdAt, 'L LT')}
                    </Typography>
                    <OrderStatusChip status={order.status} />
                </div>
            </header>
            <div className={styles.gridContent}>
                <div>
                    <Typography className={styles.contentTitle}>
                        {t('term.marketplace-estimated-price-total')}
                    </Typography>
                    <Typography className={styles.contentValue}>
                        {totalEstimatedValue ? formatCurrency(totalEstimatedValue) : '--'}
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.contentTitle}>
                        {t('term.marketplace-effective-price')}
                    </Typography>
                    <Typography className={styles.contentValue}>
                        {totalValue ? formatCurrency(totalValue) : '--'}
                    </Typography>
                </div>
                <div className={styles.gridSelfEnd}>
                    <Button color='primary' variant='outlined' onClick={onClick}>
                        {t('term.open-request')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default ProcessOrderCardView;
