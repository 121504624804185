export enum ProcessTab {
    general = 1,
    documents = 2,
    messages = 3,
    solicitations = 4,
    proposals = 5,
    authorization = 6,
    quantitative = 7,
    orders = 8,
    lotsOverview = 9,
    contracts = 10,
    integrations = 11,
}
