import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from 'common/theme';
import { formatPxToRem } from 'common/utils';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: theme.palette.neutral['50'],
        border: `1px solid ${theme.palette.neutral['200']}`,
        borderRadius: formatPxToRem(8),
        overflow: 'hidden',
        marginBottom: formatPxToRem(16),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: formatPxToRem(16),
        borderBottom: `1px solid ${theme.palette.neutral['200']}`,
    },
    flex: {
        display: 'flex',
        gap: formatPxToRem(5),
        alignItems: 'center',
    },
    gridContent: {
        display: 'grid',
        gridTemplateColumns: '25% 25% auto',
        gridTemplateRows: 'auto',
        padding: formatPxToRem(16),
        paddingBottom: formatPxToRem(48),
    },
    gridSelfEnd: {
        justifySelf: 'end',
    },
    title: {
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(14),
        fontWeight: 600,
        color: theme.palette.neutral['800'],
    },
    itemsCount: {
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(14),
        color: theme.palette.neutral['600'],
    },
    contentTitle: {
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(12),
        color: theme.palette.neutral['600'],
    },
    contentValue: {
        fontFamily: 'Public Sans Variable',
        fontSize: formatPxToRem(14),
        color: theme.palette.neutral['800'],
    },
    skeleton: {
        borderRadius: formatPxToRem(8),
        marginBottom: formatPxToRem(16),
    },
}));
